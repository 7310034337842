var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.series
    ? _c("div", [
        _c("div", { staticClass: "preview-banner" }, [
          _c("div", { staticClass: "columns is-mobile" }, [
            _c("div", { staticClass: "column is-one-fifth is-hidden-mobile" }),
            _c("div", { staticClass: "column" }, [
              _c("h3", [_vm._v("Event Series Preview")]),
              _c(
                "button",
                {
                  staticClass: "button is-pulled-right",
                  on: {
                    click: function($event) {
                      return _vm.edit()
                    }
                  }
                },
                [_vm._m(0), _c("span", [_vm._v("Edit Series")])]
              )
            ]),
            _c("div", { staticClass: "column is-one-fifth is-hidden-mobile" })
          ])
        ]),
        _c("div", { staticClass: "body-wrapper" }, [
          _c(
            "div",
            { staticClass: "columns" },
            [
              _c("div", {
                staticClass: "column is-one-fifth is-hidden-mobile"
              }),
              _c("div", { staticClass: "column maincont" }, [
                _vm.series.image
                  ? _c("div", { staticClass: "imagewrap" }, [
                      _c("img", {
                        staticClass: "event-image",
                        attrs: { src: _vm.series.image }
                      })
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "calendar" },
                  [
                    _c("div", { staticClass: "cal-stripe" }),
                    _c("EventDateBadge", {
                      attrs: {
                        date: _vm.series.start
                          ? _vm.series.start.toDate()
                          : null,
                        end: _vm.series.end ? _vm.series.end.toDate() : null
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "description indent" }, [
                  _c(
                    "div",
                    [_c("EventDateline", { attrs: { event: _vm.series } })],
                    1
                  ),
                  _c("h1", [_vm._v(_vm._s(_vm.series.title))]),
                  _vm.venue
                    ? _c(
                        "div",
                        {
                          staticClass: "venue-map",
                          class: {
                            hasMap:
                              !_vm.series.mapDisplay ||
                              _vm.series.mapDisplay != "hidden"
                          }
                        },
                        [
                          !_vm.series.mapDisplay ||
                          _vm.series.mapDisplay != "hidden"
                            ? _c(
                                "gmap-map",
                                {
                                  staticStyle: {
                                    width: "300px",
                                    height: "200px"
                                  },
                                  attrs: {
                                    center: _vm.location,
                                    zoom: _vm.series.venueIsAlternative
                                      ? 10
                                      : 17,
                                    mapTypeControl: "false",
                                    options: _vm.options,
                                    zoomControl: "false"
                                  }
                                },
                                [
                                  _c("gmap-marker", {
                                    attrs: {
                                      position: _vm.location,
                                      clickable: true,
                                      draggable: true
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.center = _vm.location
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "map-legend" }, [
                            _c("h3", [_vm._v(_vm._s(_vm.venue.name))]),
                            !_vm.series.venueIsAlternative
                              ? _c("p", [_vm._v(_vm._s(_vm.venue.fullAddress))])
                              : _vm._e(),
                            _vm.series.venueIsAlternative
                              ? _c("p", [_vm._v(_vm._s(_vm.venue.city))])
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.series.description) }
                  })
                ]),
                _c(
                  "div",
                  {
                    staticClass: "indent",
                    staticStyle: { "margin-bottom": "20px" }
                  },
                  [
                    _c(
                      "h3",
                      { staticStyle: { clear: "both", "margin-top": "20px" } },
                      [_vm._v(_vm._s(_vm.series.title) + " Passes")]
                    ),
                    _vm._l(_vm.series.passes, function(pass) {
                      return _c(
                        "div",
                        { key: pass.id, staticClass: "passclass" },
                        [
                          _c("h4", [_vm._v(_vm._s(pass.name))]),
                          _c("div", { staticClass: "columns" }, [
                            _c("div", { staticClass: "column" }, [
                              _vm._v(_vm._s(pass.description))
                            ]),
                            !pass.status || pass.status != "soldout"
                              ? _c(
                                  "div",
                                  { staticClass: "column is-one-quarter" },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        "$" + _vm._s(pass.price) + " per pass"
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            pass.status && pass.status == "soldout"
                              ? _c(
                                  "div",
                                  { staticClass: "column is-one-quarter" },
                                  [
                                    _c("strong", { staticClass: "soldout" }, [
                                      _vm._v(
                                        "$" + _vm._s(pass.price) + " per pass"
                                      )
                                    ]),
                                    _vm._v(" SOLD OUT ")
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    }),
                    _c("div", { staticClass: "buttons is-right" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-primary is-pulled-right",
                          on: {
                            click: function($event) {
                              return _vm.passes()
                            }
                          }
                        },
                        [_vm._v(" purchase passes ")]
                      )
                    ])
                  ],
                  2
                )
              ]),
              !_vm.series.eventType || _vm.series.eventType != "videoOnDemand"
                ? _c(
                    "div",
                    { staticClass: "performances indent" },
                    [
                      false
                        ? _c("FollowArtist", { attrs: { artist: _vm.series } })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.events && _vm.events.length < 0
                ? _c("div", { staticClass: "indent" }, [
                    _c("h3", [_vm._v("Shows")])
                  ])
                : _vm._e(),
              _vm._l(_vm.events, function(event) {
                return _c(
                  "div",
                  { key: event.id, staticClass: "event indent" },
                  [
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column" }, [
                        _c("div", { staticClass: "eventimagerapper" }, [
                          _c("div", { staticClass: "imageholder" }, [
                            _c("img", {
                              staticClass: "eventimage",
                              attrs: { src: event.image, alt: "" }
                            })
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "eventTitle" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "event",
                                    params: { id: event.id }
                                  }
                                }
                              },
                              [_vm._v(_vm._s(event.title))]
                            )
                          ],
                          1
                        ),
                        _c("div", [_vm._v(_vm._s(event.act))]),
                        _c("div", {
                          staticClass: "showblurb",
                          domProps: { innerHTML: _vm._s(event.description) }
                        })
                      ])
                    ])
                  ]
                )
              }),
              _c("div", { staticClass: "column is-one-fifth is-hidden-mobile" })
            ],
            2
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-pen" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }