var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "field" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Event Date")]),
          _c(
            "div",
            { staticClass: "control", class: { disabled: _vm.disable } },
            [
              _c("duet-date-picker", {
                attrs: {
                  identifier: _vm.datepickerId,
                  value: _vm.date.startDate,
                  disabled: _vm.disable,
                  "first-day-of-week": "0"
                },
                domProps: { localization: _vm.localisation_us },
                on: { duetChange: _vm.updateStartDate }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "column is-narrow" }, [
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("label", { staticClass: "label" }, [_vm._v("Start Time")]),
            _c("TimeField", {
              attrs: { time: _vm.date.startTime, valid: _vm.startTimeValid },
              on: {
                "update:time": function($event) {
                  return _vm.$set(_vm.date, "startTime", $event)
                },
                "update:valid": function($event) {
                  _vm.startTimeValid = $event
                },
                change: _vm.startChanged
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "column is-narrow" }, [
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("label", { staticClass: "label" }, [_vm._v("End Time")]),
            _c("TimeField", {
              attrs: { time: _vm.date.endTime, valid: _vm.endTimeValid },
              on: {
                "update:time": function($event) {
                  return _vm.$set(_vm.date, "endTime", $event)
                },
                "update:valid": function($event) {
                  _vm.endTimeValid = $event
                },
                change: _vm.changed
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "field" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Tags (optional)")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newTag,
                expression: "newTag"
              }
            ],
            staticClass: "input",
            attrs: { type: "text", placeholder: "Add tag" },
            domProps: { value: _vm.newTag },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.addTag.apply(null, arguments)
              },
              input: function($event) {
                if ($event.target.composing) return
                _vm.newTag = $event.target.value
              }
            }
          })
        ])
      ]),
      _c("div", { staticClass: "column is-narrow" }, [
        !_vm.disable
          ? _c("span", {
              staticClass: "icon is-medium delete",
              on: { click: _vm.deleted }
            })
          : _vm._e(),
        false
          ? _c(
              "span",
              {
                staticClass: "icon is-medium info",
                on: { click: _vm.deleted }
              },
              [_c("i", { staticClass: "fas fa-lg fa-info-circle" })]
            )
          : _vm._e()
      ])
    ]),
    _c("div", { staticClass: "columns" }, [
      _vm.date.tags
        ? _c("div", { staticClass: "column" }, [
            _c(
              "div",
              { staticClass: "tags" },
              _vm._l(_vm.date.tags, function(tag) {
                return _c("span", { key: tag, staticClass: "tag is-success" }, [
                  _vm._v(" " + _vm._s(tag) + " "),
                  _c("button", {
                    staticClass: "delete is-small",
                    on: {
                      click: function($event) {
                        return _vm.removeTag(tag)
                      }
                    }
                  })
                ])
              }),
              0
            )
          ])
        : _vm._e()
    ]),
    !_vm.startTimeValid
      ? _c("p", { staticClass: "help is-danger" }, [
          _vm._v("The start time is invalid. Use 12 hour format (eg 12:30 PM).")
        ])
      : _vm._e(),
    !_vm.endTimeValid
      ? _c("p", { staticClass: "help is-danger" }, [
          _vm._v("The end time is invalid. Use 12 hour format (eg 12:30 PM).")
        ])
      : _vm._e(),
    _c("hr")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }