<template>
  <div class="body-wrapper">
    <div class="body-content" v-if="event && dateSummary">
      <h1>
        <span
          class="icon backbutton"
          @click="
            $router.push({
              name: 'eventSummary',
              params: { id: event.id }
            })
          "
        >
          <i class="fas fa-chevron-left"></i>
        </span>
        {{ event.title }}
      </h1>
      <h3 v-if="dateSummary">{{ moment(dateSummary.start.toDate()).format("l h:mm a") }}</h3>
      <div class="level">
        <p class="level-item">
          <span class="total">Willcall: {{ totalQty }}</span>
        </p>
        <p class="level-item">
          <span class="total">Checkedin: {{ totalCheckedin }}</span>
        </p>
        <p>
          <span class="total">Total Sold: {{ dateSummary.totalSold }} / {{ dateSummary.capacity }}</span>
        </p>
        <p class="level-item has-text-centered">
          <button class="button" @click="addcomp">Add Comp</button>
        </p>
        <p class="level-item has-text-centered">
          <button class="button" @click="walkup">Walk Up</button>
        </p>
        <p class="level-item has-text-centered" v-if="event.festivalId">
          <button class="button" @click="pass">Use Pass</button>
        </p>
        <p class="level-item has-text-centered" v-if="event.festivalId">
          <button class="button" @click="newpass">Add Pass</button>
        </p>
      </div>
      <h4>Willcall</h4>
      <table id="eventTable">
        <tr>
          <th class="is-hidden-mobile"></th>
          <th>First Name</th>
          <th>Last Name</th>
          <th class="is-hidden-mobile">email</th>
          <th><span>Qty</span></th>
          <th class="is-hidden-mobile">Price Tier</th>
          <th></th>
        </tr>
        <WillcallLine
          v-for="audience in willcall"
          :key="audience.id"
          :audience="audience"
          :eventId="event.id"
          :dateId="dateSummary.dateId"
          @move="move(audience)"
        />
      </table>

      <div class="spacer"></div>
      <h4>Checked In</h4>
      <table id="eventTable">
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>email</th>
          <th>Quantity</th>
          <th>Price Tier</th>
          <th></th>
        </tr>
        <tr v-for="audience in checkedin" :key="audience.orderId">
          <td>{{ audience.firstName }}</td>
          <td>{{ audience.lastName }}</td>
          <td>{{ audience.email }}</td>
          <td>{{ audience.checkedin }}</td>
          <td>{{ audience.tierName }}</td>
          <td><button class="button" @click="undoCheckin(audience)">Undo</button></td>
        </tr>
      </table>

      <div class="spacer"></div>

      <div v-if="cancelled && cancelled.length > 0">
        <h4>Cancelled</h4>
        <table id="eventTable">
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>email</th>
            <th>Quantity</th>
            <th>Price Tier</th>
            <th></th>
          </tr>
          <tr v-for="audience in cancelled" :key="audience.orderId">
            <td>{{ audience.firstName }}</td>
            <td>{{ audience.lastName }}</td>
            <td>{{ audience.email }}</td>
            <td>{{ audience.cancelled }}</td>
            <td>{{ audience.tierName }}</td>
            <td><button class="button" @click="undoCheckin(audience)">Undo</button></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import WillcallLine from "@/components/willcall-line";
import moment from "moment";

export default {
  props: ["eventId", "id"],
  data: function() {
    return {
      moment: moment,
    }
  },
  computed: {
    ...mapGetters({
      dateSummary: "dateSummary",
      willcall: "willcall",
      checkedin: "checkedin",
      event: "event",
      cancelled: "cancelled"
    }),
    totalQty() {
      if (this.willcall.length > 0)
        return this.willcall
          .map(patron => patron.quantity)
          .reduce((prev, next) => prev + next);
      else return 0;
    },
    totalCheckedin() {
      if (this.checkedin.length > 0)
        return this.checkedin
          .map(patron => patron.checkedin)
          .reduce((prev, next) => prev + next);
      else return 0;
    }
  },
  components: { WillcallLine },
  methods: {
    async undoCheckin(audience) {
      //checkin
      console.log("uci", audience);
      const isPass = (audience.passId) ? true : false;
      await store.dispatch("undoCheckin", {
        eventId: this.eventId,
        dateId: this.id,
        audienceId: audience.id,
        isPass: isPass
      });
    },
    walkup() {
      console.log("walking up");
      this.$emit("walkup");
    },
    addcomp() {
      this.$emit("addcomp");
    },
    pass() {
      this.$emit("pass");
    },
    newpass() {
      this.$emit("addpass");
    },
    move(audience) {
      console.log("moving", audience);
      store.dispatch("setOrder", audience)
      this.$emit("move");
    }
  },
  async created() {
    console.log("Get event summarys.", {
      eventId: this.eventId,
      dateId: this.id
    });
    await store.dispatch("fetchEvent", this.eventId);
    await store.dispatch("fetchDateSummary", {
      eventId: this.eventId,
      dateId: this.id
    });
    await store.dispatch("fetchWillcall", {
      eventId: this.eventId,
      dateId: this.id
    });
    await store.dispatch("fetchCheckedIn", {
      eventId: this.eventId,
      dateId: this.id
    });
    await store.dispatch("fetchCancelled", {
      eventId: this.eventId,
      dateId: this.id
    });
    await store.dispatch("fetchEventDates");
    if (this.event && this.event.seasonId && (!this.passes || this.passes.length < 1))
      await store.dispatch("getFestivalPasses", {
        seasonId: this.event.seasonId,
        festivalId: this.event.festivalId
      });
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";

.spacer {
  height: 50px;
}
.backbutton {
  margin-right: 20px;
}
.backbutton:hover {
  color: $active-green;
}
.span.total {
  margin: 0 20px;
}
</style>
