<template>
  <div class="profile">
    <div class="columns" v-if="!editing">
      <div class="column is-narrow">
        <div class="avatar-generic"><img :src="profile.image" alt="" /></div>
      </div>
      <div class="column">
        <p class="artistname">
          <strong>{{ profile.name }}</strong
          >&nbsp;&nbsp;|&nbsp;&nbsp;{{ profile.type }}
        </p>
      </div>
      <div class="column is-narrow">
        <p class="managedby">Managed by {{ owners }}</p>
      </div>
      <div class="column is-narrow">
        <div
          class="dropdown"
          :class="{ 'is-active': showMenu }"
          @click="showMenu = true"
        >
          <div class="dropdown-trigger">
            <button
              class="button dot-menu"
              aria-haspopup="true"
              aria-controls="dropdown-menu3"
            >
              <span class="icon is-small">
                <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu3" role="menu">
            <div class="dropdown-content" @blur="showMenu = false">
              <a class="dropdown-item" @click="edit()">Edit Artist</a>
              <a v-if="false" class="dropdown-item" @click="deleteDate()"
                >Delete Artist</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- EDIT MODE -->
    <div v-if="editing">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Artist Name</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Artist name"
                v-model.lazy="profile.name"
                @change="save"
                :readonly="profile.useMyName"
              />
            </div>
          </div>
        </div>
        <div class="column is-one-third">
          <div class="field">
            <label class="label">&nbsp;</label>
            <div class="select is-full-width">
              <select v-model="profile.type" @change="save">
                <option value="" disabled selected>Select category</option>
                <option value="music">Music</option>
                <option value="theater">Theater</option>
                <option value="dance">Dance</option>
                <option value="improv">Improv</option>
                <option value="storytelling">Storytelling</option>
                <option value="standup">Standup Comedy</option>
                <option value="conferences">Conferences/Exhibitions</option>
                <option value="opera">Opera</option>
                <option value="music theater">Music Theater</option>
                <option value="art">Visual Art</option>
                <option value="gatherings">Social Gatherings</option>
                <option value="sports">Sports</option>
                <option value="children">Children's Programs</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Artist Image</label>
            <div v-if="false">
              <Avatar />
            </div>
            <div class="imagefield" v-if="profile.image">
              <img :src="profile.image" v-if="profile.image" alt="User Image" />
              <button class="button" @click="profile.image = null">
                Change Image
              </button>
            </div>
            <UploadImage
              v-if="!profile.image"
              outputWidth="400"
              outputHeight="400"
              v-on:imageCropped="saveImage"
            />
            <div class="buttons"></div>
            <div v-if="showImageLoading">
              <progress
                class="progress is-primary"
                :value="percentComplete"
                max="100"
                >{{ percentComplete }}</progress
              >
              <p>Uploading image...</p>
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label">Website</label>
        <div class="control">
          <input
            type="text"
            class="input"
            placeholder="Website URL"
            v-model.lazy="profile.links.web"
            @change="save"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">Instagram Username</label>
        <div class="control">
          <input
            type="text"
            class="input"
            placeholder="Insta Username"
            v-model.lazy="profile.links.instagram"
            @change="save"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">Facebook Pagename</label>
        <div class="control">
          <input
            type="text"
            class="input"
            placeholder="Facebook Username"
            v-model.lazy="profile.links.facebook"
            @change="save"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">Twitter Username</label>
        <div class="control">
          <input
            type="text"
            class="input"
            placeholder="Twitter Username"
            v-model.lazy="profile.links.twitter"
            @change="save" />
        </div>
      </div>
      <div class="field">
        <label class="label">Tax Status</label>
        <div class="field">
          <input
            type="radio"
            id="taxable"
            name="tax"
            v-model="profile.tax"
            value="remit"
            class="is-checkradio"
            @change="save"
          />
          <label class="radio" for="taxable">
            Collect and Remit Sales Tax (default)
          </label>
          <input
            type="radio"
            id="taxexempt"
            name="tax"
            v-model="profile.tax"
            value="exempt"
            class="is-checkradio"
            @change="save"
          />
          <label class="radio" for="taxexempt">
            Tax Exempt (we may ask for documentation)
          </label>
        </div>
      </div>
      <p v-if="profile.id != null">
        (Optional) Add co-owners now or you can always add them later. If they
        don't currently have a Gobo account, they will need to create one.
      </p>
      <InviteUserStarter
        v-if="profile.id != null"
        :disable="profile.id == null"
        :inviterName="user.name"
        :inviterId="user.uid"
        :profileName="profile.name"
        :actId="profile.id"
        :newProfile="false"
      />
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import store from "@/store";
import UploadImage from "@/components/upload-image";
import InviteUserStarter from "@/components/invite-user-starter";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      showImagePicker: true,
      showImageLoading: false,
      percentComplete: 0,
      showMenu: false,
      email: null
    };
  },
  props: {
    profile: Object,
    editing: Boolean
  },
  components: { UploadImage, InviteUserStarter },
  computed: {
    ...mapGetters({
      user: "user",
      profiles: "getProfiles"
    }),
    owners() {
      if (this.profile.owners) {
        let names = "";
        let x = 0;
        for (const owner in this.profile.owners) {
          if (x == 1) names += ", ";
          console.log("owner: ", owner);
          names += this.profile.owners[owner].name;
          x = 1;
        }
        return names;
      } else return "";
    }
  },
  methods: {
    async save() {
      this.$emit("profileSet", this.profile);
    },
    edit() {
      if (!this.profile.links) this.profile.links = {};
      this.$emit("edit", this.profile.id);
    },
    async saveImage(image) {
      var storageRef = firebase.storage().ref();
      var imagestr = "performers/" + this.profile.id + ".jpg";
      var imageRef = storageRef.child(imagestr);
      this.showImagePicker = false;
      this.showImageLoading = true;
      var upload = imageRef.put(image);

      // Update percent complete
      upload.on(
        "state_changed",
        upload => {
          var progress = (this.percentComplete =
            (upload.bytesTransferred / upload.totalBytes) * 100);
          console.log("Upload is " + progress + "% done");
        },
        e => {
          console.log("Upload error: ", e);
        },
        () => {
          upload.snapshot.ref.getDownloadURL().then(url => {
            this.image = url;
            console.log("url: ", url);
            this.showImageLoading = false;

            // Update profile
            this.profile.image = url;
            store.dispatch("savePerformerProfileImage", this.profile);
          });
        }
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
.imagefield {
  background-color: $gray6;
  border-radius: $radius;
  img {
    max-width: 230px;
    margin: 20px;
  }
  .button {
    margin-top: 18%;
  }
}
.managedby {
  font-size: 12px;
  margin: 12.5px;
}
p.artistname {
  margin: 12.5px;
}
</style>
