<template>
  <div class="date-slider" ref="wrapper">
    <span
      id="leftsliderbutton"
      class="is-hidden-touch arrow"
      style="padding-right: 5px"
      v-if="showLeftSlider"
      @click="slideRight"
    >
      <img src="/img/icons/icon-arrow-left.png" alt="slide left" />
    </span>
    <div
      class="date-slider-viewport"
      v-if="dates.length > 1 && width > 0"
      :style="{ width: viewportwidth + 'px' }"
      ref="viewport"
    >
      <div
        class="date-slider-slide"
        ref="slider"
        :style="{
          transform: `translate(${currentOffset}px, 0)`,
          transition: 'transform 0.5s ease 0s',
        }"
        v-if="!initializing"
      >
        <span
          v-for="date in filtereddates"
          :key="date.id"
          class="date-slider-date"
          ref="sliderItem"
          v-bind:class="{ selected: date == selectedDate }"
          @click="setDate(date)"
        >
          {{ shortDate(date) }}
        </span>
      </div>
    </div>
    <span
      id="rightsliderbutton"
      class="is-hidden-touch arrow"
      v-if="showRightSlider"
      style="padding-left: 5px"
      @click="slideLeft"
    >
      <img src="/img/icons/icon-arrow-right.png" alt="slide right" />
    </span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    dates: Array,
    visible: Boolean,
    displayAllDates: Boolean,
  },
  data: function () {
    return {
      width: 0,
      initializing: true,
      currentOffset: 0,
      sliderIndex: 0,
      selectedDate: null,
    };
  },
  methods: {
    setDate(date) {
      this.selectedDate = date;
      this.$emit("setDate", date);
    },
    shortDate: function (date) {
      var filtereddates = this.dates.filter(function (d) {
        return d.startDate == date.startDate;
      });
      if (filtereddates.length > 1) {
        const startTime = moment(date.startDate + " " + date.startTime);
        const displayTime =
          startTime.minutes() == 0
            ? startTime.format("h A")
            : startTime.format("h:mm A");
        return moment(date.startDate).format("ddd, MMM Do") + " " + displayTime;
      }
      return moment(date.startDate).format("ddd, MMM Do");
    },
    slideRight() {
      //console.log("offset");
      //this.currentOffset = this.getIndexOffset(this.sliderIndex + 1);//this.currentOffset - this.$refs.sliderItem[this.sliderIndex].clientWidth - 20;
      if (this.sliderIndex < this.dates.length) this.sliderIndex++;
      this.setIndexOffset();
    },
    slideLeft() {
      this.sliderIndex--;
      this.setIndexOffset();
      //console.log("offset");
      //console.log("Slider count: ", this.$refs.sliderItem.length);
      //this.currentOffset = this.getIndexOffset(this.sliderIndex - 1);//this.currentOffset + this.$refs.sliderItem[this.sliderIndex + 1].clientWidth + 20;
    },
    setIndexOffset() {
      let offset = 0;
      for (let x = 0; x > this.sliderIndex; x--) {
        console.log("x is ", x);
        // client width includes padding but not margin. add 20 for margin.
        if (this.$refs.sliderItem[x * -1])
          offset -= this.$refs.sliderItem[x * -1].clientWidth - 20;
      }
      // if slider index is greater than zero, add 19 for left button
      if (this.sliderIndex > 0) offset = offset + 19;
      this.currentOffset = offset;
    },
  },
  watch: {
    visible: function (val, oldVal) {
      console.log("new: %s, old: %s", val, oldVal);
      this.initializing = true;
      if (this.$refs.wrapper) this.width = this.$refs.wrapper.clientWidth;
      else console.log("wrapper not found");
      console.log("width at watch", this.width);
      this.initializing = false;
    },
  },
  computed: {
    showLeftSlider: function () {
      console.log("left", this.currentOffset, this.sliderIndex);
      return this.sliderIndex != 0;
    },
    showRightSlider: function () {
      //return true;
      if (this.$refs.slider)
        console.log("slider width", this.$refs.slider.width);
      if (this.$refs.viewport)
        console.log("viewport width", this.$refs.viewport.clientWidth);
      if (
        this.dates &&
        this.sliderIndex &&
        this.$refs.slider &&
        this.$refs.viewport
      ) {
        var totalwidth = 0;
        for (var x = 0; x < this.$refs.sliderItem.length; x++) {
          console.log("clientwidth", this.$refs.sliderItem[x].clientWidth);
          // client width includes padding but not margin. add 10 for margin.
          totalwidth = totalwidth + this.$refs.sliderItem[x].clientWidth + 10;
        }
        return (
          this.$refs.slider.clientWidth + this.currentOffset * -1 < totalwidth
        );
      } else return true;
    },
    viewportwidth: function () {
      var vpw = this.width;
      if (this.sliderIndex > 0) vpw = vpw - 100;
      if (this.width < 768) vpw = vpw - 25;
      return vpw;
    },
    filtereddates: function () {
      if (this.displayAllDates) return this.dates;
      else return this.dates.filter(
        (date) =>
          date.status != "soldout" &&
          date.start.toDate() > Date.now() &&
          date.status != "cancelled"
      );
    },
  },
  mounted: function () {
    if (this.$refs.wrapper && this.$refs.wrapper != this.width) {
      this.initializing = true;
      this.width = this.$refs.wrapper.clientWidth;
      console.log("Slider updated!");
      this.initializing = false;
      // if (this.dates && this.filtereddates.length > 0)
      //   this.setDate(this.filtereddates[0]);
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
.date-slider {
  border-top: $gray6 2px solid;
  border-bottom: $gray6 2px solid;
  height: 64px;
  margin-bottom: 16px;
  display: flex;
}
.date-slider-viewport {
  overflow-x: auto;
  overflow-y: hidden;
}
.date-slider-viewport::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  height: 0;
  background: transparent; /* Optional: just make scrollbar invisible */
}
.date-slider-slide {
  display: flex;
}
.date-slider-date {
  flex-shrink: 0;
  height: 36px;
  font-size: 16px;
  line-height: 36px;
  margin: 12px 10px;
  padding: 0 15px;
  display: inline-block;
  border-radius: 4px;
}
.date-slider-date:hover {
  background-color: $gray6;
  cursor: pointer;
}
.date-slider-date.selected {
  background-color: $gold;
}
.arrow {
  margin-top: 21px;
}
</style>
