<template>
  <div class="tier-row">
    <div class="columns">
      <div class="column">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label v-if="type != 'donation'" class="label">Name of Tier</label>
              <label v-if="type == 'donation'" class="label">Donation Title</label>
              <div class="control">
                <input
                  class="input is-primary"
                  type="text"
                  placeholder="Name of Tier"
                  v-model="tier.name"
                  @change="changed"
                  :disabled="disable"
                />
              </div>
              <p class="help is-danger" v-if="false">
                Please enter a name for this tier.
              </p>
            </div>
          </div>
          <div v-if="type != 'donation'" class="column is-one-quarter">
            <div class="field">
              <label class="label">Quantity Type</label>
              <div class="select is-fullwidth">
                <select
                  v-model="tier.quantityType"
                  @change="changed"
                  :disabled="disable"
                >
                  <option value="" v-if="!tier.feeType"></option>
                  <option value="fixed">Reserved</option>
                  <option value="flexible">Shared</option>
                </select>
              </div>
              <p class="help is-danger" v-if="false">
                Please select a quantity type.
              </p>
            </div>
          </div>
          <div v-if="type != 'donation'" class="column is-one-quarter">
            <div class="field">
              <label class="label">Tickets Available</label>
              <div class="control">
                <input
                  v-if="tier.quantityType == 'fixed'"
                  class="input is-primary"
                  type="number"
                  min="1"
                  max="10000"
                  step="1"
                  placeholder="# available"
                  v-model="tier.capacity"
                  @change="changed"
                  :disabled="disable"
                />
                <input
                  v-if="tier.quantityType == 'flexible'"
                  disabled="true"
                  class="input is-primary"
                  type="number"
                  min="1"
                  max="10000"
                  step="1"
                  placeholder="# available"
                  v-model.number="flexible"
                  @change="changed"
                />
              </div>
              <p class="help is-danger" v-if="false">
                Please enter a name for this tier.
              </p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-half">
            <div class="field">
              <label class="label">Tier Description (optional)</label>
              <div class="control">
                <textarea
                  class="textarea is-fullwidth"
                  v-model="tier.description"
                  @change="changed"
                  :disabled="disable"
                ></textarea>
              </div>
              <p class="help is-danger" v-if="false">
                Please enter a name for this tier.
              </p>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="field">
              <label class="label">Fee Type</label>
              <div class="select is-fullwidth">
                <select
                  v-model="tier.feeType"
                  @change="changed"
                  :disabled="disable"
                >
                  <option value="" v-if="!tier.feeType"></option>
                  <option v-if="type != 'donation'" value="fixed">Fixed price</option>
                  <option value="sliding">Sliding Scale</option>
                  <option v-if="type != 'donation'" value="free">Free</option>
                  <option v-if="type == 'donation'" value="free">Preset Tiers</option>
                </select>
              </div>
              <p class="help is-danger" v-if="false">
                Please select a fee type.
              </p>
            </div>
            <div class="field" v-if="tier.feeType == 'sliding'">
              <label class="label">Suggested</label>
              <div class="control has-icons-left">
                <input
                  class="input is-primary"
                  type="number"
                  min="1"
                  max="10000"
                  step="1"
                  placeholder="price"
                  v-model.number="tier.price"
                  @change="changed"
                  :disabled="disable"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="field" v-if="tier.feeType == 'fixed'">
              <label class="label">Price</label>
              <div class="control has-icons-left">
                <input
                  class="input is-primary"
                  type="number"
                  min="1"
                  max="10000"
                  step="1"
                  placeholder="price"
                  v-model.number.lazy="tier.price"
                  @change="changed"
                  :disabled="disable"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
              </div>
              <p class="help is-danger" v-if="tier.price && tier.price < 5">
                Minimum ticket price is $5 (for now).
              </p>
              <p class="help is-danger" v-if="false">
                Please enter a valid price.
              </p>
            </div>

            <div class="field" v-if="tier.feeType == 'sliding'">
              <label class="label">Minimum</label>
              <div class="control has-icons-left">
                <input
                  class="input is-primary"
                  type="number"
                  min="5"
                  max="10000"
                  step="1"
                  placeholder="price"
                  v-model.number.lazy="tier.minPrice"
                  @change="changed"
                  :disabled="disable"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
              </div>
              <p
                class="help is-danger"
                v-if="tier.minPrice && tier.minPrice < 5"
              >
                Minimum ticket price is $5 (for now).
              </p>
            </div>
            <div class="field" v-if="tier.feeType == 'sliding'">
              <label class="label">Maximum</label>
              <div class="control has-icons-left">
                <input
                  class="input is-primary"
                  type="number"
                  min="1"
                  max="10000"
                  step=".01"
                  placeholder="price"
                  v-model.number.lazy="tier.maxPrice"
                  @change="changed"
                  :disabled="disable"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
              </div>
              <p
                class="help is-danger"
                v-if="tier.maxPrice && tier.maxPrice <= tier.minPrice"
              >
                Maximum price must be more than the minimum price.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="type != 'donation'" class="column is-narrow">
        <div class="column is-narrow">
          <span class="icon is-medium delete" v-if="!disable" @click="deleted"
            ></span>
        </div>
      </div>
    </div>
    <div>
      <label class="checkbox">
        <input type="checkbox" v-model="tier.selectDates" :disabled="disable" />
        Apply tier to select dates only
      </label>
    </div>
    <div class="dates" v-if="tier.selectDates">
      <div
        class="date"
        v-for="date in dates"
        :key="date.id"
        :class="{ selected: tier.selectedDates.indexOf(date.id) > -1 }"
        @click="toggleDate(date)"
      >
        {{ shortDate(date.startDate) }}
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/assets/variables.scss";
.tier-row {
  margin-bottom: 1em;
}
.delete {
  margin-top: 20px;
}
div.dates {
  padding-left: 15px;
}
div.date {
  padding: 5px 10px;
  border-radius: $radius;
  display: inline-block;
  margin: 5px;
}
div.date:hover {
  background: $gray6;
}
div.date.selected {
  background: $gold;
}
</style>
<script>
import moment from "moment";

export default {
  data() {
    return {
      datepickerId: "eventDate",
      times: []
    };
  },
  props: {
    tier: Object,
    type: String,
    flexible: Number,
    dates: Array,
    disable: Boolean
  },
  methods: {
    changed() {
      console.log("changed: " + JSON.stringify(this.tier));
      var pricingSet =
        this.tier.feeType == "free" ||
        (this.tier.feeType == "fixed" && this.tier.price > 0) ||
        (this.tier.feeType == "sliding" &&
          this.tier.minPrice > 0 &&
          this.tier.maxPrice > this.tier.minPrice);
      //console.log("Valid: ", this.valid(), "PricingSet: ", pricingSet);
      if (
        this.valid() &&
        pricingSet &&
        this.tier.name &&
        (this.tier.quantityType == "flexible" || this.tier.capacity > 0 || this.type == 'donation')
      ) {
        console.log("Tier set");
        this.$emit("tierSet", this.tier);
      } else (console.log("Invalid Tier"))
    },
    deleted() {
      console.log("deeting teeer");
      this.$emit("deleted", this.tier);
    },
    valid() {
      if (
        this.tier.quantityType == "fixed" &&
        (this.tier.capacity < 1 || this.tier.capacity > 100000)
      )
        return false;
      if (this.tier.feeType == "fixed" && this.tier.price < 5) return false;
      if (this.tier.feeType == "sliding" && this.tier.minPrice < 5)
        return false;
      if (
        this.tier.feeType == "sliding" &&
        this.tier.maxPrice < this.tier.minPrice
      )
        return false;
      return true;
    },
    shortDate: function(date) {
      return moment(date).format("ddd, MMM Do");
    },
    toggleDate(date) {
      var selected = this.tier.selectedDates.indexOf(date.id) > -1;
      if (!this.tier.selectedDates) this.tier.selectedDates = [];
      if (!selected) this.tier.selectedDates.push(date.id);
      else
        this.tier.selectedDates.splice(
          this.tier.selectedDates.indexOf(date.id),
          1
        );
      this.changed();
    }
  }
};
</script>
