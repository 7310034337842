import Vue from "vue";
import firebase from "firebase/app";
import Bugsnag from "@bugsnag/js";
//import "firebase/firestore";

export const state = {
  season: null,
  seasonSettings: null,
  festivalSeasons: [],
  festivals: [],
  festivalEvent: null,
  festivalEvents: [],
  passes: [],
  foundPasses: []
};

// eslint-disable-next-line no-unused-vars
var passesListener = null;

export const mutations = {
  SET_FESTIVAL_SEASON(state, season) {
    state.season = season;
  },
  SET_FESTIVAL_SEASON_SETTINGS(state, settings) {
    state.seasonSettings = settings;
  },
  SET_FESTIVALS(state, festivals) {
    console.log("festivals", festivals);
    state.festivals = festivals;
  },
  ADD_NEW_FESTIVAL_PROFILE(state, festival) {
    console.log("adding festival profile");
    if (
      state.festivals &&
      state.festivals.length > 0 &&
      state.festivals[state.festivals.length - 1].id == null
    )
      Vue.set(state.festivals, state.festivals.length - 1, festival);
    else state.festivals.push(festival);
  },
  ADD_FESTIVAL_EVENT(state, event) {
    state.festivalEvent = event;
  },
  SET_FESTIVAL_EVENTS(state, events) {
    state.festivalEvents = events;
  },
  SET_FESTIVAL_SEASONS(state, seasons) {
    state.festivalSeasons = seasons;
  },
  REMOVE_SEASON_DISCOUNT_ATINDEX(state, index) {
    if (state.seasonSettings.discounts)
      state.seasonSettings.discounts.splice(index, 1);
  },
  SET_PASSES(state, passes) {
    state.passes = passes;
  },
  SET_FOUND_PASSES(state, passes) {
    state.foudPasses = passes;
  }
};

export const actions = {
  // async findPasses({ commit }, search) {
  //   // if (search == "") commit("SET_FOUND_PASSES", []);
  //   // else {
  //   //   var db = firebase.firestore();
  //   //   var docs = await db
  //   //     .collection("passes")
  //   //     .where("email", ">=", search)
  //   //     //.where("email", "<=", search + "\uf8ff")
  //   //     .limit(5)
  //   //     .get();

  //   //   if (!docs.empty) {
  //   //     console.log("docs", docs)
  //   //     commit(
  //   //       "SET_FOUND_PASSES",
  //   //       docs.docs.map(doc => doc.data())
  //   //     );
  //   //   } else {
  //   //     console.log("Could not find any passes with this search.", search);
  //   //     commit("SET_FOUND_PASSES", []);
  //   //   }
  //   // }
  //   var results = state.passes.filter(function (pass) { return pass.email.indexOf(search) === -1; });
  //   commit("SET_FOUND_PASSES", results);
  // },
  async newFestivalSeason({ commit }) {
    const season = { priceTiers: [] };
    const settings = { discounts: [] };
    // if (eventListener) eventListener = null;
    console.log("about to set the damn fest season", season);
    commit("SET_FESTIVAL_SEASON", season);
    commit("SET_FESTIVAL_SEASON_SETTINGS", settings);
  },
  async newFestivalEvent({ commit }, args) {
    console.log("args", args);
    var db = firebase.firestore();
    var docref = await db.collection("events").add({
      festivalId: args.festivalId,
      seasonId: args.seasonId,
      venue: args.venueId,
      venueName: args.venueName,
      ownerId: args.ownerId,
      status: "draft",
      start: null
    });
    var event = (await docref.get()).data();
    event.id = docref.id;
    await docref.update({ id: docref.id });
    console.log("festevent", event);
    commit("ADD_FESTIVAL_EVENT", event);
    return docref.id;
  },
  setSeasonFestival({ commit }, season) {
    console.log("Updating the season", season);
    commit("SET_FESTIVAL_SEASON", season);
  },
  async createFestivalSeason({ commit }, season) {
    season.status = "draft";
    var db = firebase.firestore();
    var docRef = await db
      .collection("festivals")
      .doc(season.festivalId)
      .collection("seasons")
      .add(season)
      .catch(function(error) {
        console.error("Error adding festival season: ", error);
        Bugsnag.notify(error);
      });

    console.log("Document written with ID: " + JSON.stringify(docRef.id));
    // TODO: Update ID with functions
    season.id = docRef.id;
    commit("SET_FESTIVAL_SEASON", season);
    var settings = { discounts: [] };
    db.collection("festivals")
      .doc(season.festivalId)
      .collection("seasons")
      .doc(docRef.id)
      .collection("userData")
      .doc("settings")
      .set(settings);
    commit("SET_FESTIVAL_SEASON_SETTINGS", settings);
  },
  async updateFestivalSeason({ commit }, season) {
    var db = firebase.firestore();
    await db
      .collection("festivals")
      .doc(season.festivalId)
      .collection("seasons")
      .doc(season.id)
      .update(season)
      .catch(function(error) {
        console.error("Error updating festival season: ", error);
        Bugsnag.notify(error);
      });

    // console.log("Document written with ID: " + JSON.stringify(docRef.id));
    // TODO: Update ID with functions
    // season.id = docRef.id;
    commit("SET_FESTIVAL_SEASON", { ...season });
  },
  async updateSeasonSettings({ commit }, settings) {
    var db = firebase.firestore();
    await db
      .collection("festivals")
      .doc(settings.festivalId)
      .collection("seasons")
      .doc(settings.seasonId)
      .collection("userData")
      .doc("settings")
      .update(settings.settings)
      .catch(function(error) {
        console.error("Error updating festival season: ", error);
        Bugsnag.notify(error);
      });

    // TODO: Update ID with functions
    // season.id = docRef.id;
    console.log(settings);
    commit("SET_FESTIVAL_SEASON_SETTINGS", settings.settings);
  },
  async fetchFestival({ commit }, festivalId) {
    var db = firebase.firestore();
    var docRef = db.collection("festivals").doc(festivalId);
    var doc = await docRef.get();

    if (doc.exists) {
      commit("SET_FESTIVAL", doc.data());
    } else {
      console.log("Could not find festival " + festivalId);
    }
  },
  async fetchMyFestivals({ commit }, userId) {
    console.log("fetching festivals", userId);
    var db = firebase.firestore();
    var docRef = db.collection("festivals").where("owners." + userId, "!=", "");
    var docs = await docRef.get();

    if (!docs.empty) {
      commit(
        "SET_FESTIVALS",
        docs.docs.map(doc => doc.data())
      );
    } else {
      console.log("Could not find festivals.");
    }
  },
  async fetchFestivalSeason({ commit }, params) {
    var db = firebase.firestore();
    const season = (
      await db
        .collection("festivals")
        .doc(params.festivalId)
        .collection("seasons")
        .doc(params.seasonId)
        .get()
    ).data();
    if (!season.id) season.id = params.seasonId;
    console.log("Found season", season);
    commit("SET_FESTIVAL_SEASON", season);

    // get settings too
    const settings = (
      await db
        .collection("festivals")
        .doc(params.festivalId)
        .collection("seasons")
        .doc(params.seasonId)
        .collection("userData")
        .doc("settings")
        .get()
    ).data();
    commit("SET_FESTIVAL_SEASON_SETTINGS", settings);
    console.log("settings", settings);
  },
  async fetchFestivalSeasons({ commit }, userId) {
    console.log("fetching festival seasonss", userId);
    var db = firebase.firestore();
    var docRef = db
      .collectionGroup("seasons")
      .where("owners." + userId, "!=", "");
    var docs = await docRef.get();

    if (!docs.empty) {
      commit(
        "SET_FESTIVAL_SEASONS",
        docs.docs.map(doc => doc.data())
      );
    } else {
      console.log("Could not find festival seasons.");
    }
  },
  async saveFestival({ commit }, festival) {
    var db = firebase.firestore();
    await db
      .collection("festivals")
      .doc(festival.id)
      .update(festival)
      .catch(function(error) {
        console.error("Error updating venue: ", error);
      });

    //console.log("Venue Recieved", venue);
    commit("SET_VENUE", festival);
  },
  async saveFestivalProfileImage({ commit }, festival) {
    console.log("saving a festival image");
    var db = firebase.firestore();
    var venueRef = await db.collection("festivals").doc(festival.id);
    await venueRef
      .update({
        image: festival.image
      })
      .catch(function(error) {
        console.error("Error saving document: ", error);
      });

    var savedFestival = await venueRef.get();
    commit("SET_FESTIVAL", savedFestival.data());
  },
  async clearVenue({ commit }) {
    commit("SET_FESTIVAL", null);
  },
  addNewFestivalProfile({ commit }) {
    commit("ADD_NEW_FESTIVAL_PROFILE", { id: null, name: "", tax: "remit" });
  },
  async createFestivalProfile({ commit }, festival) {
    var db = firebase.firestore();
    var newfestRef = await db.collection("festivals").add(festival);
    await newfestRef.update({ id: newfestRef.id });
    var newfest = (await newfestRef.get()).data();
    //]newfest.id = newfestRef.id;
    commit("ADD_NEW_FESTIVAL_PROFILE", newfest);
    return newfest;
  },
  async fetchFestivalEvents({ commit }, seasonId) {
    var db = firebase.firestore();
    var eventsRef = db
      .collection("events")
      .where("seasonId", "==", seasonId)
      .orderBy("title");
    var events = await eventsRef.get();

    if (!events.empty) {
      console.log("Found the festival events.");
      commit(
        "SET_FESTIVAL_EVENTS",
        events.docs.map(doc => doc.data())
      );
    } else {
      console.log("Could not find any events for this festival.", seasonId);
    }
  },
  deleteFestivalDiscount({ commit, state, dispatch }, confirmation) {
    commit("REMOVE_SEASON_DISCOUNT_ATINDEX", confirmation.index);
    console.log("settings", state.seasonSettings);
    dispatch("updateSeasonSettings", {
      festivalId: state.season.festivalId,
      seasonId: state.season.id,
      settings: state.seasonSettings
    });
  },
  async getFestivalPasses({ commit }, request) {
    var db = firebase.firestore();
    const passesRef = db
      .collection("passes")
      .where("festivalId", "==", request.festivalId)
      .where("seasonId", "==", request.seasonId)
      .orderBy("lastName");
    const passes = await passesRef.get();
    if (!passes.empty) {
      console.log("Found the passes.");
      commit(
        "SET_PASSES",
        passes.docs.map(doc => doc.data())
      );
    } else {
      console.log("Could not find any passes.");
    }
    passesListener = passesRef.onSnapshot(function(snapshot) {
      let update = snapshot.docs;
      console.log("audience updated", update);
      commit(
        "SET_PASSES",
        update.map(doc => doc.data())
      );
    });
  }
};

export const getters = {
  season(state) {
    return state.season;
  },
  seasonSettings(state) {
    return state.seasonSettings;
  },
  festivals(state) {
    return state.festivals;
  },
  festivalEvent(state) {
    return state.festivalEvent;
  },
  festivalEvents(state) {
    return state.festivalEvents;
  },
  festivalSeasons(state) {
    return state.festivalSeasons;
  },
  passes(state) {
    return state.passes;
  },
  foundPasses(state) {
    return state.foudPasses;
  }
};
