<template>
  <div class="timeline">{{ longDate }} | {{ price }}</div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    event: Object,
    selectedDate: Object,
  },
  computed: {
    longDate() {
      if (this.selectedDate)
        return (
          moment(this.selectedDate.startDate).format("dddd, MMMM Do YYYY") +
          " | " +
          moment(this.event.start.toDate()).format("h:mma") +
          " - " +
          this.selectedDate.endTime
        );
      if (!this.event.start || !this.event.end) return "";
      if (
        moment(this.event.start.toDate()).isSame(
          moment(this.event.end.toDate()),
          "date"
        )
      )
        return (
          moment(this.event.start.toDate()).format("dddd, MMMM Do YYYY") +
          " | " +
          moment(this.event.start.toDate()).format("h:mma") +
          " - " +
          moment(this.event.end.toDate()).format("h:mma")
        );
      else
        return (
          moment(this.event.start.toDate()).format("MMMM Do") +
          " - " +
          moment(this.event.end.toDate()).format("MMMM Do YYYY")
        );
    },
    price() {
      if (this.event.minPrice == this.event.maxPrice) {
        if (this.event.minPrice == 0) return "FREE";
        else return "$" + this.event.minPrice;
      } else
        return (
          "$" +
          (this.event.minPrice == 0 ? "FREE" : this.event.minPrice) +
          "-" +
          this.event.maxPrice
        );
    },
  },
};
</script>

<style></style>