var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("duet-date-picker", {
    attrs: {
      identifier: "earlyPurchaseEndDate",
      value: _vm.date,
      "first-day-of-week": "0"
    },
    domProps: { localization: _vm.localisation_us },
    on: { duetChange: _vm.updateDate }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }