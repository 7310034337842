<template>
  <div class="body-wrapper" v-if="festivalEvent">
    <div class="body-content gray">
      <div class="container">
        <div class="columns">
          <div class="column is-one-quarter">
            <h1 v-if="user.eventPublished">
              Create <br />
              a festival <br />
              event!
            </h1>
            <div class="box is-hidden-mobile">
              <div class="block">
                <aside class="menu">
                  <p class="menu-label">
                    Event Set Up
                  </p>
                  <ul class="menu-list">
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 1 }"
                        @click="setStep(1)"
                        >Basic Info</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 2 }"
                        @click="setStep(2)"
                        >Dates and Times</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 5 }"
                        @click="setStep(5)"
                        >Discount Codes</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 7 }"
                        @click="setStep(7)"
                        >Preview and Publish</a
                      >
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
          </div>

          <div class="column" v-show="step == 1">
            <h4>Basic Info</h4>
            <div class="box">
              <div class="field" ref="croppiecont">
                <label class="label">Event Image</label>
                <img
                  v-if="festivalEvent.image"
                  :src="festivalEvent.image"
                  class="eventimageheader"
                  alt=""
                />
                <button
                  v-if="
                    festivalEvent.image && festivalEvent.status != 'complete'
                  "
                  class="button"
                  @click="festivalEvent.image = null"
                >
                  Use a Different Image
                </button>
                <UploadImage
                  v-if="!festivalEvent.image && !cropping && !showImageLoading"
                  :outputWidth="800"
                  :outputHeight="360"
                  v-on:imageCropped="crop"
                />
                <div
                  v-if="false"
                  class="dropzone"
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="croppie"
                >
                  <div class="columns is-vcentered">
                    <div class="column is-2 placeholder-img">
                      <img src="/img/icon-image.png" alt="" />
                    </div>
                    <div class="column">
                      <p>
                        <strong>Drag + drop</strong> or click
                        <strong>upload</strong> to add an image.
                      </p>
                      <p class="subtext">
                        Use a high quality image: minimum 800 x 360 px, JPG or
                        PNG, no larger than 10MB.
                      </p>
                    </div>
                    <div class="column is-one-quarter">
                      <input
                        type="file"
                        name="file"
                        id="file"
                        class="inputfile"
                        accept="image/x-png,image/jpeg"
                        @change="croppie"
                      />
                      <label for="file" class="button upload">
                        Upload Image
                      </label>
                    </div>
                  </div>
                </div>
                <div class="croppiecont" v-if="cropping">
                  <vue-croppie
                    ref="croppieRef"
                    :enableOrientation="true"
                    :enableResize="false"
                    :boundary="{ width: croppieWidth, height: croppieHeight }"
                    :viewport="{
                      width: cropWidth,
                      height: cropHeight,
                      type: 'square'
                    }"
                  >
                  </vue-croppie>
                  <div class="buttons is-centered">
                    <button class="button is-flex-mobile" @click="cancel">
                      Cancel
                    </button>
                    <button
                      class="button is-primary is-flex-mobile"
                      @click="crop"
                    >
                      Save Image
                    </button>
                  </div>
                </div>
                <div v-if="showImageLoading">
                  <progress
                    class="progress is-primary"
                    :value="percentComplete"
                    max="100"
                    >{{ percentComplete }}</progress
                  >
                  <p>Uploading image...</p>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">Event Title</label>
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="text"
                        placeholder="Event Title"
                        v-model.lazy="festivalEvent.title"
                        :disabled="festivalEvent.status == 'complete'"
                        :class="{ 'is-danger': errors.title }"
                        @change="saveEvent()"
                      />
                    </div>
                    <p class="help is-danger" v-if="errors.title">
                      {{ errors.title }}
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Event Type</label>
                    <div class="select">
                      <select
                        v-model="festivalEvent.genre"
                        :disabled="festivalEvent.status == 'complete'"
                        @change="eventTypeChange()"
                      >
                        <option value="music">Music</option>
                        <option value="theater">Theater</option>
                        <option value="dance">Dance</option>
                        <option value="improv">Improv</option>
                        <option value="storytelling">Storytelling</option>
                        <option value="standup">Standup Comedy</option>
                        <option value="conferences"
                          >Conferences/Exhibitions</option
                        >
                        <option value="opera">Opera</option>
                        <option value="music theater">Music Theater</option>
                        <option value="art">Visual Art</option>
                        <option value="gatherings">Social Gatherings</option>
                        <option value="sports">Sports</option>
                        <option value="children">Children's Programs</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <p class="help is-danger" v-if="false">
                      Please enter a name.
                    </p>
                  </div>
                  <div class="field" v-if="eventType == 'other'">
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="text"
                        placeholder="Event Type"
                        v-model.lazy="festivalEvent.genre"
                        :disabled="festivalEvent.status == 'complete'"
                        :class="{ 'is-danger': errors.genre }"
                      />
                    </div>
                    <p class="help is-danger" v-if="errors.genre">
                      {{ errors.genre }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Artist</label>
                <div v-for="artist in festivalEvent.artists" :key="artist.id">
                  <div class="columns">
                    <div class="column is-narrow">
                      <div class="avatar-generic">
                        <img :src="artist.image" alt="" />
                      </div>
                    </div>
                    <div class="column">
                      <p class="artistname">
                        <strong>{{ artist.name }}</strong
                        >&nbsp;&nbsp;|&nbsp;&nbsp;{{ artist.type }}
                      </p>
                    </div>
                    <div class="column">
                      <p class="managedby">{{ getowners(artist) }}</p>
                    </div>
                    <div class="column is-narrow">
                      <span
                        class="icon is-medium delete"
                        @click="removeArtist"
                        v-if="festivalEvent.status != 'published'"
                        ></span>
                      <span
                        class="icon is-medium info"
                        @click="removeArtist"
                        v-if="festivalEvent.status == 'published'"
                        ><i class="fas fa-lg fa-info-circle"></i
                      ></span>
                    </div>
                  </div>
                  <div v-if="!artist.owners">
                    <p>
                      {{ artist.name }} is not using Gobo yet.
                      Invite them to help sell tickets with you.
                    </p>
                    <InviteUserStarter
                      :inviterName="user.name"
                      :inviterId="user.uid"
                      :profileName="artist.name"
                      :actId="artist.id"
                      :newProfile="true"
                      placeholderText="Artist Email Address"
                    />
                  </div>
                </div>
                <div class="buttons" v-if="false">
                  <button
                    class="button"
                    @click="addFestival()"
                    :disabled="
                      profiles &&
                        profiles[0] &&
                        !profiles[profiles.length - 1].id
                    "
                  >
                    <span class="icon is-small">
                      <i class="fas fa-plus"></i>
                    </span>
                    <span>Add Artist</span>
                  </button>
                </div>
                <div v-if="!festivalEvent.actId">
                  <input
                    class="input has-icons-left"
                    type="text"
                    v-model="artistName"
                    v-on:keyup="searchArtists"
                    placeholder="Artist Name"
                    @blur="addArtist"
                  />
                </div>
                <div class="results" v-if="!festivalEvent.actId">
                  <div
                    v-for="artist in foundArtists"
                    :key="artist.id"
                    class="columns selectable"
                    @click="setArtist(artist)"
                  >
                    <div class="column is-narrow">
                      <div class="avatar-generic">
                        <img :src="artist.image" alt="" />
                      </div>
                    </div>
                    <div class="column">
                      <p class="artistname">
                        <strong>{{ artist.name }}</strong>
                      </p>
                    </div>
                    <div class="column">
                      <p class="managedby">
                        {{ getowners(artist) }}
                      </p>
                    </div>
                  </div>
                  <div v-if="artistName != ''" class="columns selectable" @click="addArtist">
                    <div class="column is-narrow">
                      <div class="avatar-generic"></div>
                    </div>
                    <div class="column">
                      <p class="artistname">
                        <strong>{{ artistName }}</strong>
                      </p>
                    </div>
                    <div class="column">
                      <p class="managedby">New Artist Profile</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Event Description</label>
                <label class="label"
                  >Add details like what to expect, list of performers or
                  sponsors.</label
                >
                <div class="control">
                  <quill-editor
                    ref="myQuillEditor"
                    :content="descriptionContent"
                    :options="editorOption"
                    :class="{ 'ql-focus': editorHasFocus }"
                    @blur="editorHasFocus = false"
                    @focus="editorHasFocus = true"
                    @change="saveEventDescription($event)"
                  />
                </div>
                <p class="help is-danger" v-if="errors.description">
                  {{ errors.description }}
                </p>
              </div>
            </div>
          </div>

          <div class="column" v-show="step == 2">
            <h4>Dates + Times</h4>
            <div class="box">
              <EventDate
                v-for="date in eventDates"
                :key="date.id"
                :date="date"
                :editing="true"
                :disable="festivalEvent.status == 'complete' || festivalEvent.status == 'published'"
                v-on:dateSet="updateDate"
                v-on:deleted="deleteDate"
              />
              <p class="buttons">
                <button class="button" :disabled="newDate" @click="newEvent">
                  <span class="icon is-medium">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span>Add date</span>
                </button>
              </p>
            </div>
          </div>

          <div class="column" v-show="step == 5">
            <h4>Discount Codes</h4>
            <DiscountCodes
              :discounts="discounts"
              :dates="eventDates"
              :disabled="festivalEvent.status == 'complete'"
              :eventId="festivalEvent.id"
              v-on:hasDiscountsChange="setHasDiscount"
              v-on:newDiscount="newDiscount"
              v-on:codeChanged="saveDiscount"
            />
          </div>

          <div class="column" v-show="step == 7">
            <h4>Preview + Publish</h4>
            <div class="box">
              <div class="columns">
                <div class="column is-half">
                  <div class="event-preview">
                    <div class="event-preview-header">
                      <img
                        v-if="festivalEvent.image"
                        :src="festivalEvent.image"
                        alt=""
                      />
                    </div>
                    <div class="event-preview-body">
                      <EventDateline :event="festivalEvent" />
                      <h3>{{ festivalEvent.title }}</h3>
                      <!-- <h5 v-if="false || venue">{{ venue.name }}</h5> -->
                      <button class="button is-fullwidth" @click="preview()">
                        Preview Event
                      </button>
                    </div>
                  </div>
                </div>
                <div class="column is-half">
                  <div class="control">
                    <label class="radio">
                      <input
                        type="radio"
                        name="answer"
                        v-model="festivalEvent.isPublic"
                        value="true"
                        @change="saveEvent()"
                      />
                      Public
                    </label>
                    <p class="radio-label">Shared on Gobo and searchable</p>
                    <label class="radio">
                      <input
                        type="radio"
                        name="answer"
                        v-model="festivalEvent.isPublic"
                        value="false"
                        @change="saveEvent()"
                      />
                      Private
                    </label>
                    <p class="radio-label">
                      Only available to you and the people you share it with.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="box"
              v-if="cantPublish && festivalEvent.status == 'draft'"
            >
              <h4>Before you publish</h4>
              <p>
                There's a few things you need to do before you can publish your
                event.
              </p>
              <ul>
                <li v-if="!festivalEvent.title">
                  Please give your event a title
                </li>
                <li v-if="!festivalEvent.genre">Set an event type</li>
                <li v-if="!festivalEvent.act">Specify an act</li>
                <li v-if="!festivalEvent.description">
                  Give your event a description
                </li>
                <li v-if="!festivalEvent.image">Add an event image</li>
                <li
                  v-if="
                    !eventDates ||
                      eventDates.length < 1 ||
                      (eventDates.length == 1 && !eventDates[0].id)
                  "
                >
                  Add at least one date
                </li>
              </ul>
            </div>
          </div>

          <div
            class="column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
          ></div>
        </div>
      </div>
    </div>
    <div class="box stepbar">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="buttons is-right">
              <button class="button" @click="returnToFestival">
                Return to Festival
              </button>
              <button class="button" v-if="step > 1" @click="step = step - 1">
                Back
              </button>
              <button
                class="button is-primary"
                v-if="step < 6"
                @click="next()"
                :disabled="cropping"
              >
                Next
              </button>
              <button
                class="button is-primary"
                v-if="step == 6"
                @click="next()"
              >
                Preview
              </button>
              <button
                class="button is-primary"
                v-if="festivalEvent.status == 'draft' && step == 7"
                @click="publish()"
                :disabled="cantPublish"
              >
                Publish
              </button>
            </div>
          </div>
          <div
            class="column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/assets/variables.scss";
.event-preview {
  border-radius: 6px;
}
.event-preview-header,
.event-preview-header img {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  line-height: 0;
}
.event-preview-body {
  padding: 1em;
  border: 2px solid #f2f2f2;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.event-preview-body h3 {
  margin-bottom: 0.5em;
}
.event-preview-body h5 {
  margin-bottom: 1.2em;
}
.event-preview-body h6 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
  margin-bottom: 0.5em;
}
.radio-label {
  font-size: 16px;
  margin-top: 2px;
}
.upload {
  font-family: $family-primary;
  display: flex;
}
.eventimageheader {
  border-radius: 6px;
}
.ql-editor p {
  margin: 1em 0;
}
.ql-editor p:first-of-type {
  margin: 0 0 1em 0;
}
.bumpadown {
  margin-top: 10px;
}
.box .field:not(:first-child) {
  margin-top: 30px;
}
.results {
  margin-top: 30px;
}
.selectable:hover {
  background-color: $gray6;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .is-flex-mobile {
    flex: 1;
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
import EventDate from "@/components/eventDate";
import EventDateline from "@/components/event-dateline";
import DiscountCodes from "@/components/editEvent/discount-codes";
// import Venue from "@/components/venue";
import InviteUserStarter from "@/components/invite-user-starter";
import UploadImage from "@/components/upload-image";
//import moment from "moment";
import firebase from "firebase/app";
// import Bugsnag from "@bugsnag/browser";

export default {
  name: "profile",
  props: ["id"],
  mounted() {},
  async created() {
    console.log("ID ", this.id);
    if (!this.id) {
      if (this.user && this.user.loggedIn && this.user.email)
        console.log("we've got a live one!", this.user);
      else
        store.subscribe(mutation => {
          if (mutation.type === "SET_USER") {
            var artist = null;
            // var venue = null;
            if (this.profiles && this.profiles.length == 1) {
              artist = this.profiles[0];
              this.eventType = artist.type;
            }
            // if (this.venues && this.venues.length == 1) venue = this.venues[0];
            // store.dispatch("newEvent", { artist: artist, venue: venue });
            // if (venue) store.dispatch("fetchVenue", venue.id);
          }
        });
    } else {
      // store.dispatch("stashCurrentEventIdForDiscountsThisIsDumb", this.id);
      console.log("Fetching event ", this.id);
      if (!this.festivalEvent || this.festivalEvent.id != this.id)
        await store.dispatch("fetchEvent", this.id);
      if (this.festivalEvent && this.festivalEvent.description)
        this.descriptionContent = this.festivalEvent.description;
      // if (this.festivalEvent.venue)
      //   await store.dispatch("fetchVenue", this.festivalEvent.venue);
      // if (this.festivalEvent.stepsCompleted || this.festivalEvent.copied) {
      //   this.step = this.festivalEvent.stepsCompleted + 1;
      await store.dispatch("fetchEventDates");
      if (this.eventDates.length > 0) this.newDate = false;
      await store.dispatch("fetchDiscounts", this.id);
    }
  },
  data() {
    return {
      step: 1,
      artistName: "",
      eventType: "music",
      newDate: true,
      newTier: true,
      editing: null,
      cropping: false,
      showImageLoading: false,
      percentComplete: 0,
      locationError: "",
      ticketTotal: null,
      descriptionContent: "",
      emailContent: "",
      emailReminderContent: "",
      emailThankyouContent: "",
      errors: {},
      dirty: {},
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "link", "image"],
            [{ list: "ordered" }, { list: "bullet" }]
          ]
        },
        placeholder: "Add your description here"
      },
      editorHasFocus: false,
      emailEditorHasFocus: false,
      emailReminderEditorHasFocus: false,
      emailThankyouEditorHasFocus: false,
      fields: [
        "place_id",
        "name",
        "formatted_phone_number",
        "website",
        "formatted_address",
        "address_components",
        "geometry"
      ]
    };
  },
  components: {
    EventDate,
    EventDateline,
    DiscountCodes,
    InviteUserStarter,
    UploadImage
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      profile: "getProfile",
      profiles: "profiles",
      festivalEvent: "event",
      eventDates: "eventDates",
      // venue: "venue",
      priceTiers: "priceTiers",
      newtier: "newtier",
      eventDate: "eventDate",
      eventTime: "eventTime",
      eventPrice: "eventPrice",
      settings: "settings",
      user: "user",
      discounts: "discounts",
      // venues: "venues",
      userRequest: "userRequest",
      foundArtists: "foundArtists"
    }),
    cantPublish() {
      if (
        !this.festivalEvent.title ||
        !this.festivalEvent.genre ||
        !this.festivalEvent.act ||
        !this.festivalEvent.description ||
        !this.festivalEvent.image
      )
        return true;
      if (
        !this.eventDates ||
        this.eventDates.length < 1 ||
        (this.eventDates.length == 1 && !this.eventDates[0].id)
      )
        return true;
      return false;
    }
    //,
    // croppieWidth() {
    //   return this.$refs.croppiecont.clientWidth - 30;
    // },
    // croppieHeight() {
    //   return (this.$refs.croppiecont.clientWidth - 30) * 0.75;
    // },
    // cropWidth() {
    //   return (this.$refs.croppiecont.clientWidth - 30) * 0.8;
    // },
    // cropHeight() {
    //   return (this.$refs.croppiecont.clientWidth - 30) * 0.8 * 0.45;
    // },
    // flexibleQuantity() {
    //   return this.event.capacity;
    // },
    // cantPublish() {
    //   if (
    //     !this.event.title ||
    //     !this.event.genre ||
    //     !this.event.act ||
    //     !this.event.description ||
    //     !this.event.image ||
    //     !this.event.venue ||
    //     !this.event.capacity
    //   )
    //     return true;
    //   if (
    //     !this.eventDates ||
    //     this.eventDates.length < 1 ||
    //     (this.eventDates.length == 1 && !this.eventDates[0].id)
    //   )
    //     return true;
    //   if (
    //     !this.priceTiers ||
    //     this.priceTiers.length < 1 ||
    //     (this.priceTiers.length == 1 && !this.priceTiers[0].id)
    //   )
    //     return true;
    //   return false;
    // }
  },
  methods: {
    returnToFestival() {
      this.$router.push({
        name: "editFestival",
        params: {
          id: this.festivalEvent.seasonId,
          festivalId: this.festivalEvent.festivalId
        }
      });
    },
    newDiscount() {
      store.dispatch("newDiscount");
    },
    saveDiscount(event) {
      console.log("id", this.festivalEvent.id, this.id, this.event);
      store.dispatch("saveDiscount", event.discount, this.festivalEvent.id);
    },
    async addArtist() {
      const artist = await store.dispatch("createArtistProfile", { name: this.artistName });
      console.log("artie", artist);
      if (!this.festivalEvent.artists) this.festivalEvent.artists = {};
      this.festivalEvent.artists[artist.id] = artist;
      this.festivalEvent.act = this.artistName;
      this.festivalEvent.actId = artist.id;
      this.saveEvent();
    },
    searchArtists() {
      store.dispatch("findArtists", this.artistName);
    },
    async next() {
      this.step++;
    },
    async saveNewEvent() {
      this.event.stepsCompleted = 1;
      this.event.ownerId = this.user.uid;
      await store.dispatch("createEvent", this.event);
      console.log("Event id: " + this.event.id);
      store.dispatch(
        "stashCurrentEventIdForDiscountsThisIsDumb",
        this.event.id
      );
    },
    async setStep(step) {
      console.log("Step set: " + step);
      this.step = step;
    },
    updateDate(date) {
      console.log("Date: " + JSON.stringify(date));
      if (date.id == null) {
        store.dispatch("createDate", date);
        this.newDate = false;
      } else store.dispatch("updateDate", date);
    },
    deleteDate(date) {
      console.log("beleting");
      store.dispatch("showConfirmation", {
        header: "Delete date?",
        message: "Are you sure you want to delete this event date?",
        cta: "delete",
        action: "deleteDate",
        object: date
      });
      console.log(date);
      //store.dispatch("deleteDate", date);
    },
    newEvent() {
      this.newDate = true;
      store.dispatch("addNewDate");
    },
    newPriceTier() {
      this.newTier = true;
      store.dispatch("addNewTier");
    },
    saveEventDescription(event) {
      this.festivalEvent.description = event.html;
      this.saveEvent();
    },
    // setPlace(e) {
    //   console.log(e);
    //   if (!e.address_components || e.address_components.length < 7) {
    //     this.locationError =
    //       "Invalid location. Location must have a valid address.";
    //     this.$analytics.logEvent("invalid_event_address", { address: e });
    //     Bugsnag.notify("Invalid address response.", e);
    //   } else {
    //     // An eight-part address contains a neighborhood. A seven-part does not.
    //     var offset = e.address_components.length == 7 ? 2 : 3;
    //     this.locationError = "";
    //     store.dispatch("setVenue", {
    //       id: e.place_id,
    //       name: e.name,
    //       phone: e.formatted_phone_number ? e.formatted_phone_number : null,
    //       website: e.website ? e.website : null,
    //       fullAddress: e.formatted_address,
    //       location: {
    //         lat: e.geometry.location.lat(),
    //         lng: e.geometry.location.lng()
    //       },
    //       address:
    //         e.address_components[0].long_name +
    //         " " +
    //         e.address_components[1].long_name,
    //       city: e.address_components[offset].long_name,
    //       state: e.address_components[offset + 2].long_name,
    //       zip: e.address_components[offset + 4].long_name
    //     });

    //     this.event.venue = e.place_id;
    //     this.event.venueName = e.name;
    //     store.dispatch("updateEvent", this.event);
    //   }
    // },
    async saveTier(tier) {
      console.log("saving tier");
      if (tier.id == null) {
        await store.dispatch("createTier", tier);
        this.newTier = false;
      } else await store.dispatch("updateTier", tier);
    },
    deleteTier(tier) {
      console.log("tier");
      store.dispatch("showConfirmation", {
        header: "Delete tier?",
        message: "Are you sure you want to delete this tier?",
        cta: "delete",
        action: "deleteTier",
        object: tier
      });
      console.log(tier);
    },
    async saveEvent() {
      store.dispatch("updateEvent", this.festivalEvent);
    },
    saveImage(e) {
      console.log(e);
    },
    croppie(e) {
      e.preventDefault();
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      e.currentTarget.classList.remove("drop-active");
      this.cropping = true;

      var reader = new FileReader();
      reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };

      reader.readAsDataURL(files[0]);
    },
    cancel() {
      this.cropping = false;
    },
    async crop(image) {
      // Options can be updated.
      // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
      this.cropping = false;
      this.showImageLoading = true;
      // let options = {
      //   type: "blob",
      //   size: { width: 800, height: 360 },
      //   format: "jpeg"
      // };
      // var newevent = {
      //   ...this.event
      // };
      // var redirect = false;
      var output = image; //, output => {
      var storageRef = firebase.storage().ref();
      var imagestr = "events/" + this.festivalEvent.id + ".jpg";
      var imageRef = storageRef.child(imagestr);
      var upload = imageRef.put(output);

      upload.on(
        "state_changed",
        upload => {
          var progress = (this.percentComplete =
            (upload.bytesTransferred / upload.totalBytes) * 100);
          console.log("Upload is " + progress + "% done");
        },
        e => {
          console.log("Upload error: ", e);
        },
        () => {
          upload.snapshot.ref.getDownloadURL().then(url => {
            this.festivalEvent.image = url;
            this.saveEvent();
            this.showImageLoading = false;
            // newevent = {
            //   ...this.festivalEvent
            // };
            // newevent.image = url;
            // store.dispatch("updateEvent", newevent).then(() => {
            //   if (redirect)
            //     this.$router.push({
            //       name: "editEvent",
            //       params: { id: this.event.id }
            //     });
            //   this.showImageLoading = false;
            // });
          });
        }
      );
    },
    dragover(event) {
      event.preventDefault();
      event.currentTarget.classList.add("drop-active");
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove("drop-active");
    },
    drop(event) {
      event.preventDefault();
      var reader = new FileReader();
      reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };
      //this.$refs.croppieRef = event.dataTransfer.files;
      //this.$refs.file.files = event.dataTransfer.files;
      //this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.remove("drop-active");
    },
    preview() {
      this.$router.push({ name: "previewEvent", id: this.festivalEvent.id });
    },
    async publish() {
      var db = firebase.firestore();
      await db
        .collection("events")
        .doc(this.festivalEvent. id)
        .update({ status: "publishMe" })
        .catch(function(error) {
          console.error("Error publishing event: ", error);
        });
      this.$router.push({ name: "eventPublished" });
    },
    eventTypeChange() {
      // if (this.eventType != "other") this.festivalEvent.type = this.eventType;
      // else this.festivalEvent.genre = "";
      this.saveEvent();
    },
    actChanged() {
      if (this.event.actId) {
        for (const profile of this.profiles) {
          if (profile.id == this.event.actId) {
            this.event.act = profile.name;
            this.event.artists[profile.id] = profile;
          }
        }
        this.saveEvent();
      }
    },
    setArtist(artist) {
      this.festivalEvent.act = artist.name;
      this.festivalEvent.actId = artist.id;
      if (!this.festivalEvent.artists) this.festivalEvent.artists = {};
      this.festivalEvent.artists[artist.id] = artist;
      this.saveEvent();
    },
    setHasDiscount(val) {
      console.log("setting has discount", val);
      this.festivalEvent.hasDiscounts = val;
      this.saveEvent();
    },
    getowners(artist) {
      if (artist.owners) {
        let names = "";
        let x = 0;
        for (const owner in artist.owners) {
          if (x == 1) names += ", ";
          console.log("owner: ", owner);
          names += artist.owners[owner].name;
          x = 1;
        }
        return "Managed by " + names;
      } else return "This artist is not using Gobo yet.";
    },
    // clearVenue() {
    //   store.dispatch("clearVenue");
    // },
    removeArtist() {
      this.event.act = null;
      this.event.actId = null;
      this.event.artists = {};
      this.saveEvent();
    }
  },
  watch: {
    event: {
      handler(newval) {
        // Mark dirties
        if (newval.title) this.dirty.title = true;
        if (this.eventType == "other" && newval.genre) this.dirty.genre = true;
        if (newval.description) this.dirty.description = true;
        if (newval.capacity) this.dirty.capacity = true;
        // Title is required
        if (this.dirty.title) {
          if (!newval.title)
            this.errors.title = "Please provide a title for this event.";
          else this.errors.title = null;
        }
        // Genre is required
        if (this.eventType == "other" && this.dirty.genre) {
          if (!newval.genre)
            this.errors.genre = "Please specify the type of event.";
          else this.errors.genre = null;
        }
        // Description is required
        if (this.dirty.description) {
          if (!newval.description)
            this.errors.description = "Please add an event description.";
          else this.errors.description = null;
        }
        // Capacity is required
        if (this.dirty.capacity) {
          if (!newval.capacity)
            this.errors.capacity =
              "Please enter a total number of tickets for this event greater than 0.";
          else this.errors.capacity = null;
        }
      },
      deep: true
    }
  }
};
</script>
