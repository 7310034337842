// import Vue from "vue";
import firebase from "firebase/app";
import Bugsnag from "@bugsnag/js";

export const state = {
    series: null,
    seriesSettings: null,
    seriesEvents: [],
    serieses: [],
    seriesPasses: [],
    seriesOrders: [],
  };

  export const mutations = {
    SET_SERIES(state, series) {
      console.log("event series is getting set", series);
      state.series = series;
    },
    // SERIESES? What are we, Gollum? Listen, it's wild but StackOverflow said it was cool
    SET_SERIESES(state, serieses) {
      state.serieses = serieses;
    },
    SET_SERIES_SETTINGS(state, settings) {
      state.seriesSettings = settings;
    },
    SET_SERIES_EVENTS(state, events) {
      state.seriesEvents = events;
    },
    ADD_SERIES_EVENT(state, event) {
      state.seriesEvents.pus(event);
    },
    SET_SERIES_PASSES(state, passes) {
      state.seriesPasses = passes;
    },
    SET_SERIES_ORDERS(state, orders) {
      state.seriesOrders = orders;
    }
  }

  export const actions = {
    async newSeries({ commit }, params) {
      console.log("What was passed?", params);
      //var actId = params.artist.id;
      var actname = params && params.artist ? params.artist.name : null;
      var artists = {};
      if (params && params.artist) artists[params.artist.id] = params.artist;
      else console.log("Betty White");
      const series = {
        id: null,
        actId: params && params.artist ? params.artist.id : null,
        act: actname,
        artists: artists,
        name: "",
        genre: params && params.artist ? params.artist.type : "",
        description: "",
        venue: params && params.venue ? params.venue.id : null,
        venueName: params && params.venue ? params.venue.name : null,
        start: null,
        status: "draft",
        step: 1,
        stepsCompleted: 0,
        minPrice: 0,
        maxPrice: 0,
        startDate: new Date(),
        isPublic: true
      };
      // if (eventListener) eventListener = null;
      console.log("about to set the damn event", event);
      commit("SET_SERIES", series);
    //   commit("SET_IMAGE", "");
    //   commit("SET_EVENT_DATES", [blankdate]);
    //   commit("SET_PRICE_TIERS", [blanktier]);
    //   commit("SET_SETTINGS", {});
    },
    async createEventSeries({ commit }, series) {
        series.status = "draft";
        var db = firebase.firestore();
        var docRef = await db
          .collection("eventseries")
          .add(series)
          .catch(function(error) {
            console.error("Error adding festival season: ", error);
            Bugsnag.notify(error);
          });
    
        console.log("Document written with ID: " + JSON.stringify(docRef.id));
        // TODO: Update ID with functions
        series.id = docRef.id;
        commit("SET_SERIES", series);
        // var settings = { discounts: [] };
        // db.collection("festivals")
        //   .doc(season.festivalId)
        //   .collection("seasons")
        //   .doc(docRef.id)
        //   .collection("userData")
        //   .doc("settings")
        //   .set(settings);
        // commit("SET_FESTIVAL_SEASON_SETTINGS", settings);
    },
    async fetchEventSeries({ commit }, id) {
        var db = firebase.firestore();
        var docRef = db.collection("eventseries").doc(id);
        var doc = await docRef.get();
    
        if (doc.exists) {
          // ensure passes have ids
          const datadoc = doc.data();
          if (datadoc.passes && datadoc.passes.length > 0) {
            datadoc.passes.forEach((pass) => {
              if (!pass.id) pass.id = firestoreAutoId();
            });
          }
          commit("SET_SERIES", datadoc);
        } else {
          console.log("Could not find series " + id);
        }

        // get settings too
        const settings = (
            await db
            .collection("eventseries")
            .doc(id)
            .collection("userData")
            .doc("settings")
            .get()
        ).data();
        commit("SET_SERIES_SETTINGS", settings);
        console.log("settings", settings);
    },
    async saveEventSeries({ commit }, series) {
        var db = firebase.firestore();
        await db
          .collection("eventseries")
          .doc(series.id)
          .update(series)
          .catch(function(error) {
            console.error("Error updating series: ", error);
          });
    
        commit("SET_SERIES", { ...series });
    },
    async newSeriesEvent({ commit }, args) {
        console.log("args", args);
        var db = firebase.firestore();
        var docref = await db.collection("events").add({
          seriesId: args.seriesId,
          venue: args.venueId,
          venueName: args.venueName,
          ownerId: args.ownerId,
          type: args.type,
          genre: args.genre,
          status: "draft",
          start: null
        });
        var event = (await docref.get()).data();
        event.id = docref.id;
        await docref.update({ id: docref.id });
        console.log("seriesevent", event);
        commit("ADD_SERIES_EVENT", event);
        return docref.id;
      },
      async fetchSeriesEvents({ commit }, seriesId) {
        var db = firebase.firestore();
        var eventsRef = db
          .collection("events")
          .where("seriesId", "==", seriesId);
        var events = await eventsRef.get();
    
        if (!events.empty) {
          console.log("Found the series events.");
          commit(
            "SET_SERIES_EVENTS",
            events.docs.map(doc => doc.data())
          );
        } else {
          console.log("Could not find any events for this series.", seriesId);
        }
      },
      async fetchEventSerieses({ commit }, userId) {
        console.log("fetching precious seasonses", userId);
        var db = firebase.firestore();
        var docRef = db
          .collection("eventseries")
          .where("owners." + userId, "!=", "");
        var docs = await docRef.get();

        if (!docs.empty) {
          commit(
            "SET_SERIESES",
            docs.docs.map(doc => doc.data())
          );
        } else {
          console.log("Could not find festival seasons.");
        }
      },
      async getSeriesPasses({ commit }, seriesId) {
          var db = firebase.firestore();
          const passesRef = db
            .collection("eventseries")
            .doc(seriesId)
            .collection("passes")
            .orderBy("lastName");
          const passes = await passesRef.get();
          if (!passes.empty) {
            console.log("Found the series passes.");
            commit(
              "SET_SERIES_PASSES",
              passes.docs.map(doc => doc.data())
            );
          } else {
            console.log("Could not find any series passes.");
          }
        },
        async getSeriesOrders({ commit }, seriesId) {
          var db = firebase.firestore();
          var ordersRef = db
            .collection("eventseries")
            .doc(seriesId)
            .collection("orders")
            .orderBy("created", "desc");
          var docs = await ordersRef.get();
      
          if (!docs.empty) {
              console.log("Got the orders", docs.docs.length);
              commit(
                "SET_SERIES_ORDERS",
                docs.docs.map(doc => doc.data())
              );
            } else {
              commit("SET_ORDERS", []);
              console.log("Could not find any orders for this event.");
            }
        }
}

export const getters = {
    series(state) {
      return state.series;
    },
    seriesSettings(state) {
        return state.seriesSettings;
    },
    seriesEvents(state) {
        return state.seriesEvents;
    },
    serieses(state) {
      return state.serieses;
    },
    seriesPasses(state) {
      return state.seriesPasses;
    },
    seriesOrders(state) {
      return state.seriesOrders;
    },
}

export const firestoreAutoId = () => {
  const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  let autoId = ''

  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(
      Math.floor(Math.random() * CHARS.length)
    )
  }
  return autoId
}