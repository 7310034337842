import firebase from "firebase/app";
import "firebase/firestore";
//import store from ".";

//const db = firebase.firestore();
//const storage = firebase.storage();
// TODO: Fix this workaround
/* eslint-disable no-unused-vars */
var userListener = null;
var requestListener = null;
var stripeListener = null;

export const state = {
  user: {
    loggedIn: false,
    name: "",
    avatar: "/img/icons/icon-profile.svg"
  },
  profile: {
    owner: null,
    name: "",
    type: "",
    avatar: ""
  },
  profiles: [],
  userRequest: {},
  stripelinkStatus: null
};

export const mutations = {
  SET_USER(state, data) {
    if (data) {
      state.user = data;
    }
  },
  SET_LOGGED_IN(state, value) {
    state.user.loggedIn = value;
  },
  SET_AVATAR(state, avatar) {
    state.user.avatar = avatar;
  },
  SET_PROFILE(state, data) {
    state.profile.name = data.name;
    state.profile.actType = data.actType;
    state.profile.avatar = data.avatar;
  },
  SET_USER_PROFILES(state, data) {
    if (data) state.profiles = data;
  },
  ADD_NEW_PROFILE(state, profile) {
    state.profiles.push(profile);
  },
  UPDATE_NEW_SAVED_PROFILE(state, profile) {
    console.log("PROFILE", JSON.stringify(profile));
    if (!state.profiles[0].id) state.profiles[0] = profile;
    else throw new Error("The first profile had an ID!");
  },
  UPDATE_PROFILE(state, profile) {
    for (let x = 0; x < state.profiles.length; x++) {
      if (state.profiles[x].id == profile.id) state.profiles[x] = profile;
    }
  },
  SET_STRIPE_LINK(state, profile) {
    state.user.stripeLink = profile.stripeLink;
  },
  SET_USER_REQUEST(state, request) {
    state.userRequest = request;
  },
  SET_STRIPELINK_STATUS(state, request) {
    state.stripelinkStatus = request;
  }
};

export const actions = {
  async fetchUser({ commit, dispatch }, user) {
    commit("SET_LOGGED_IN", user !== null);
    if (user) {
      // get profile
      var db = firebase.firestore();
      var storage = firebase.storage();
      var docRef = db.collection("users").doc(user.uid);
      var userRef = await docRef.get().catch(function(error) {
        console.error("Error getting document: ", error);
      });
      const userData = userRef.data();

      // If stripeLink and stripe account are null, listen for changes
      if (!userData.stripeLink && !userData.stripeAccount) {
        console.log("setting up listener for stripe link");
        userListener = docRef.onSnapshot(function(snapshot) {
          let update = snapshot.data();
          console.log("user updated", update);
          commit("SET_STRIPE_LINK", update);
        });
      }

      var avatarRef = storage.ref("/avatars/" + user.uid + ".jpg");
      var avatar = await avatarRef.getDownloadURL().catch(error => {
        console.log("Couldn't find: " + error);
        user.avatar = "/img/icons/icon-profile.svg";
      });

      // Moving above so SET_USER marks completion
      await dispatch("fetchProfiles", user.uid);
      if (userData.venueManager) await dispatch("fetchMyVenues", user.uid);
      if (userData.festivalManager) await dispatch("fetchMyFestivals", user.uid);

      // TODO: Stop setting fields explicitly
      commit("SET_USER", {
        name: userData.name,
        email: user.email,
        uid: user.uid,
        avatar: avatar ? avatar : "/img/icons/icon-profile.svg",
        loggedIn: true,
        stripeLink: userData.stripeLink,
        stripeAccount: userData.stripeAccount,
        stripeStatus: userData.stripeStatus,
        eventPublished: userData.eventPublished,
        venueManager: userData.venueManager,
        performingArtist: userData.performingArtist,
        festivalManager: userData.festivalManager
      });
    } else {
      commit("SET_USER", null);
    }
  },
  async fetchProfiles(context, uid) {
    var db = firebase.firestore();
    console.log("getting profiles");
    var docsRef = db.collection("performers").where("owner", "==", uid);
    var docs = await docsRef.get();

    if (!docs.empty) {
      console.log("Found the user profiles.");
      context.commit(
        "SET_USER_PROFILES",
        docs.docs.map(doc => doc.data())
      );
      context.commit("SET_PROFILE", state.profiles[0]);
    } else {
      // context.commit("ADD_NEW_PROFILE", { name: "", type: "" });
      console.log("Could not find any user profiles. ");
    }
  },
  async saveUser({ commit }, user) {
    console.log("saving user", user);
    var db = firebase.firestore();
    await db
      .collection("users")
      .doc(user.uid)
      .update({
        uid: user.uid,
        name: user.name,
        avatar: user.avatar,
        venueManager: user.venueManager ? true : false,
        festivalManager: user.festivalManager ? true : false
      })
      .then(function(docRef) {
        console.log("Document written with ID: " + JSON.stringify(docRef));
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });

    commit("SET_USER", user);
    //this.$emit("saved", this.user);
    //var docRef = db.collection("users").doc(this.state.user.uid);
  },
  addNewProfile({ commit }, user) {
    const profile = { name: "", type: "", links: {}, tax: "remit" };
    if (user) {
      profile.owner = user.uid;
      const owners = {};
      owners[user.uid] = {name: user.name};
      profile.owners = owners;
    }
    commit("ADD_NEW_PROFILE", profile);
  },
  async createPerformerProfile({ commit }, profile) {
    console.log("creating a new profile");
    var db = firebase.firestore();
    var newDocRef = await db.collection("performers").doc();
    const owners = {};
      owners[state.user.uid] = {name: state.user.name};
    await newDocRef
      .set({
        id: newDocRef.id,
        owner: state.user.uid,
        owners: owners,
        name: profile.name,
        type: profile.type
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });

    var newprofile = await newDocRef.get();
    commit("UPDATE_NEW_SAVED_PROFILE", newprofile.data());
    return newprofile;
  },
  async savePerformerProfile({ commit }, profile) {
    console.log("saving a profile");
    var db = firebase.firestore();
    var profileRef = await db.collection("performers").doc(profile.id);
    await profileRef
      .update({
        name: profile.name,
        type: profile.type,
        links: profile.links,
        tax: (profile.tax) ? profile.tax : "remit"
      })
      .catch(function(error) {
        console.error("Error saving document: ", error);
      });

    var savedProfile = await profileRef.get();
    commit("UPDATE_PROFILE", savedProfile.data());
  },
  async savePerformerProfileImage({ commit }, profile) {
    console.log("saving a profile");
    var db = firebase.firestore();
    var profileRef = await db.collection("performers").doc(profile.id);
    await profileRef
      .update({
        image: profile.image
      })
      .catch(function(error) {
        console.error("Error saving document: ", error);
      });

    var savedProfile = await profileRef.get();
    commit("UPDATE_PROFILE", savedProfile.data());
  },
  setAvatar({ commit }, url) {
    commit("SET_AVATAR", url);
  },
  async refreshStripeUrl({ commit }, status) {
    var db = firebase.firestore();
    if (status != "refresh" && status != "success")
      throw new Error("Invalid stripe status.");
    var userRef = db.collection("users").doc(state.user.uid);
    await userRef.update({ stripeStatus: status });
    userRef.onSnapshot(doc => {
      var user = doc.data();
      console.log("Updated user: ", doc.data());
      commit("SET_USER", user);
    });
  },
  async sendTestEmail({ commit }, props) {
    commit("SET_USER_REQUEST", null);
    var db = firebase.firestore();
    const req = {
      userId: props.userId,
      task: "sendTestEmail",
      emailType: props.type,
      emailAddress: props.email,
      eventId: props.eventId,
      status: "submitted"
    };
    var reqRef = await db.collection("userRequests").add(req);
    commit("SET_USER_REQUEST", req);
    // requestListener = reqRef.onSnapshot(doc => {
    //   let req = doc.data();
    //   console.log("Updating user request.", req);
    //   commit("SET_USER_REQUEST", req);
    // });
    requestListener = reqRef.onSnapshot(function(snapshot) {
      let update = snapshot.data();
      console.log("Updating user request.", update);
      commit("SET_USER_REQUEST", update);
    });
  },
  async createStripeLink({ commit }, props) {
    commit("SET_STRIPELINK_STATUS", "submitted");
    var db = firebase.firestore();
    const req = {
      userId: props.userId,
      task: "createStripeLink",
      status: "submitted"
    };
    var reqRef = await db.collection("userRequests").add(req);
    stripeListener = reqRef.onSnapshot(function(snapshot) {
      let update = snapshot.data();
      console.log("Updating user request.", update.status);
      commit("SET_STRIPELINK_STATUS", update.status);
    });
  }
};
export const getters = {
  profiles(state) {
    return state.profiles;
  },
  getProfile(state) {
    return state.profile;
  },
  getProfiles(state) {
    return state.profiles;
  },
  user(state) {
    return state.user;
  },
  profile(state) {
    return state.profile;
  },
  userRequest(state) {
    return state.userRequest;
  },
  stripelinkStatus(state) {
    return state.stripelinkStatus;
  }
};
