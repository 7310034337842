<template>
  <div class="body-wrapper" v-if="series">
    <div class="body-content gray">
      <div class="container">
        <div class="columns">
          <div class="column is-one-quarter">
            <h1>
              Create an<br />
              event series!
            </h1>
            <div class="box is-hidden-mobile" v-if="step > 0">
              <div class="block">
                <aside class="menu">
                  <p class="menu-label">
                    Event Series Set Up
                  </p>
                  <ul class="menu-list">
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 1 }"
                        @click="setStep(1)"
                        >Basic Info</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 2 }"
                        @click="setStep(2)"
                        >Location</a
                      >
                    </li>
                    <!-- <li>
                      <a
                        v-bind:class="{ 'is-active': step == 3 }"
                        @click="setStep(3)"
                        >Ticket Info</a
                      >
                    </li> -->
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 4 }"
                        @click="setStep(4)"
                        >Passes</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 5 }"
                        @click="setStep(5)"
                        >Discounts</a
                      >
                    </li>
                    <!-- <li>
                      <a
                        v-bind:class="{ 'is-active': step == 6 }"
                        @click="setStep(6)"
                        >Email Settings</a
                      >
                    </li> -->
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 7 }"
                        @click="setStep(7)"
                        >Shows</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 8 }"
                        @click="setStep(8)"
                        >Preview and Publish</a
                      >
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
          </div>

          <div class="column" v-show="step == 1">
            <h3>Basic Info</h3>
            <div class="box">
              <div class="field" ref="croppiecont">
                <label class="label">Series Image</label>
                <img
                  v-if="series.image"
                  :src="series.image"
                  class="eventimageheader"
                  alt=""
                />
                <button
                  v-if="series.image && series.status != 'complete'"
                  class="button"
                  @click="series.image = null"
                >
                  Use a Different Image
                </button>
                <UploadImage
                  v-if="!series.image && !cropping && !showImageLoading"
                  :outputWidth="800"
                  :outputHeight="360"
                  v-on:imageCropped="crop"
                />
                <div v-if="showImageLoading">
                  <progress
                    class="progress is-primary"
                    :value="percentComplete"
                    max="100"
                    >{{ percentComplete }}</progress
                  >
                  <p>Uploading image...</p>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">Series Title</label>
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="text"
                        placeholder="Event Series Title"
                        v-model.lazy="series.title"
                        :disabled="series.status == 'complete'"
                        :class="{ 'is-danger': errors.title }"
                        @change="saveSeries()"
                      />
                    </div>
                    <p class="help is-danger" v-if="errors.title">
                      {{ errors.title }}
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Series Type</label>
                    <div class="select">
                      <select
                        v-model="series.genre"
                        :disabled="series.status == 'complete'"
                        @change="seriesTypeChange()"
                      >
                        <option value="music">Music</option>
                        <option value="theater">Theater</option>
                        <option value="dance">Dance</option>
                        <option value="improv">Improv</option>
                        <option value="storytelling">Storytelling</option>
                        <option value="standup">Standup Comedy</option>
                        <option value="conferences"
                          >Conferences/Exhibitions</option
                        >
                        <option value="opera">Opera</option>
                        <option value="music theater">Music Theater</option>
                        <option value="art">Visual Art</option>
                        <option value="gatherings">Social Gatherings</option>
                        <option value="sports">Sports</option>
                        <option value="children">Children's Programs</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <p class="help is-danger" v-if="false">
                      Please choose a type.
                    </p>
                  </div>
                  <div class="field" v-if="series.type == 'other'">
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="text"
                        placeholder="Event Type"
                        v-model.lazy="series.typeOther"
                        :disabled="series.status == 'complete'"
                        :class="{ 'is-danger': errors.genre }"
                      />
                    </div>
                    <p class="help is-danger" v-if="errors.genre">
                      {{ errors.genre }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Series Description</label>
                <label class="label"
                  >Add details like what to expect, list of performers or
                  sponsors.</label
                >
                <div class="control">
                  <quill-editor
                    ref="myQuillEditor"
                    :content="series.description"
                    :options="editorOption"
                    :class="{ 'ql-focus': editorHasFocus }"
                    @blur="editorHasFocus = false"
                    @focus="editorHasFocus = true"
                    @change="saveSeriesDescription($event)"
                  />
                </div>
                <p class="help is-danger" v-if="errors.description">
                  {{ errors.description }}
                </p>
              </div>
            </div>
          </div>

          <div class="column" v-show="step == 2">
            <h3>Location</h3>
            <div class="box">
              <div
                class="field"
                v-if="
                  series.status != 'published' && series.status != 'complete'
                "
              >
                <label class="label">Venue Location</label>
                <div class="control has-icons-left" v-if="!venue">
                  <GmapAutocomplete
                    class="input"
                    @place_changed="setPlace"
                    :fields="fields"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-search"></i>
                  </span>
                </div>
                <p class="notification is-danger" v-if="locationError">
                  {{ locationError }}
                </p>
              </div>
              <VenueMap v-if="venue" :venue="venue" />
              <div v-if="venue && venue.owner">
                <p>{{ venue.name }} is using Gobo!</p>
                <Venue :profile="venue" :editable="false" />
              </div>
              <div v-if="venue && !venue.owner">
                <p>
                  {{ venue.name }} is not using Gobo yet. Invite them to help
                  sell tickets with you.
                </p>
                <InviteUserStarter
                  :inviterName="user.name"
                  :inviterId="user.uid"
                  :profileName="venue.name"
                  :venueId="venue.id"
                  :newProfile="true"
                />
              </div>
              <hr v-if="venue" />
              <div v-if="venue">
                <button class="button" @click="clearVenue">
                  Use a Different Venue
                </button>
              </div>
            </div>
          </div>

          <!-- <div class="column" v-show="step == 3">
            <h3>Ticket Info</h3>
            <div class="box">
              <p>
                You can create up to five named tiers of tickets (e.g.,
                Pre-sale, VIP, General Admission, Guest List, etc.)
              </p>
              <nav class="level">
                <div class="level-left">
                  <div class="field">
                    <label class="label">Total tickets available</label>
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="number"
                        placeholder="Number of tickets"
                        v-model.number="series.capacity"
                        :disabled="series.status == 'complete'"
                        :class="{ 'is-danger': errors.capacity }"
                        @change="saveSeries()"
                      />
                    </div>
                    <p class="help is-danger" v-if="errors.capacity">
                      {{ errors.capacity }}
                    </p>
                  </div>
                </div>
                <div class="level-right">
                  <div class="field">
                    <input
                      id="donation"
                      type="checkbox"
                      name="switchExample"
                      class="switch"
                      v-model="series.donation"
                      @change="saveSeries()"
                    />
                    <label for="donation">Artist Donation</label>
                  </div>
                </div>
              </nav>
              <hr />
              <PricingTier
                v-for="tier in series.priceTiers"
                :key="tier.id"
                :tier="tier"
                :flexible="series.capacity"
                :disable="series.status == 'complete'"
                v-on:tierSet="saveTier"
                v-on:deleted="deleteTier"
              />
              <PricingTier
                v-if="unsavedTier"
                :tier="unsavedTier"
                :flexible="series.capacity"
                v-on:tierSet="saveTier"
                v-on:deleted="deleteTier"
              />
              <p class="buttons">
                <button
                  class="button"
                  :disabled="
                    unsavedTier ||
                      (series.priceTiers && series.priceTiers.length > 4)
                  "
                  @click="newPriceTier"
                >
                  <span class="icon is-medium">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span>Add ticket tier</span>
                </button>
              </p>
              <div
                v-if="
                  series.priceTiers &&
                    series.priceTiers.length > 0 &&
                    series.priceTiers.filter(t => t.feeType != 'free').length >
                      0
                "
              ></div>
            </div>
          </div> -->

          <div class="column" v-show="step == 5">
            <h3>Discounts</h3>
            <div class="box">
              <DiscountCodes
                v-if="seriesSettings && seriesSettings.discounts"
                :discounts="seriesSettings.discounts"
                :disabled="series.status == 'complete'"
                :seriesId="series.id"
                v-on:newDiscount="newDiscount"
                v-on:codeChanged="saveDiscount"
              />
              <h4>Early Purchase Discount</h4>
              <p>Early Purchase Discounts give special pricing for passes for a limited time when the event series goes live.
                You can discount some or all passes, but only the discounted items will be available until the early purchase period closes.
              </p>
              <div class="control">
                <input
                  type="checkbox"
                  id="sendThankyouEmails"
                  v-model="series.earlyPurchaseDiscount"
                  :class="{
                    'has-background-color': series.earlyPurchaseDiscount
                  }"
                  class="is-checkradio is-success"
                  @change="saveSeries"
                />
                <label for="sendThankyouEmails">
                  Enable Early Purchase Discount
                </label>
              </div>
              <div v-if="series.earlyPurchaseDiscount" class="earlyPurchaseDetails">
                <div class="field">
                    <label class="label">Early Purchase End Date</label>
                    <div class="control">
                      <DateField :date="series.earlyDiscountEndDate" @changed="earlyDiscountDateSet"/>
                    </div>
                  </div>
                  <div class="availableDiscounts">
                    <h5>Available Discounts</h5>
                    <div v-for="(pass, index) in series.passes" :key="index" class="discountLine">
                      <div class="discountSelector">
                        <input
                          type="checkbox"
                          :id="'passdiscount-' + index"
                          v-model="pass.hasEarlyDiscount"
                          :class="{
                            'has-background-color': pass.hasEarlyDiscount
                          }"
                          class="is-checkradio is-success"
                          @change="saveSeries()"
                        />
                        <label :for="'passdiscount-' + index">
                          {{ pass.name }}
                        </label>
                      </div>
                      <div>
                        <label class="label">Early Purchase Price</label>
                        <div class="control has-icons-left">
                          <input
                            class="input is-primary"
                            type="number"
                            min="1"
                            max="10000"
                            step="1"
                            placeholder="early price"
                            v-model.number.lazy="pass.earlyPrice"
                            @change="saveSeries()"
                            :disabled="!pass.hasEarlyDiscount"
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                        <div class="discountlimit">
                        <input
                          type="checkbox"
                          :id="'passdiscountlimit-' + index"
                          v-model="pass.earlyDiscountLimit"
                          :class="{
                            'has-background-color': pass.earlyDiscountLimit
                          }"
                          class="is-checkradio is-success"
                          @change="saveSeries()"
                        />
                        <label :for="'passdiscountlimit-' + index" class="limitQuantity">
                          Limit Quantity
                        </label>
                        <label class="label">Passes Available for Discount</label>
                        <div class="control has-icons-left">
                          <input
                            class="input is-primary"
                            type="number"
                            min="1"
                            max="10000"
                            step="1"
                            placeholder="passes available"
                            v-model.number.lazy="pass.earlyDiscountQuantity"
                            @change="saveSeries()"
                            :disabled="!pass.hasEarlyDiscount || !pass.earlyDiscountLimit"
                          />
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div class="column" v-show="step == 4">
            <h3>Passes</h3>
            <div class="box">
              <p>
                Passes allow patrons access to more than one show with a single
                purchase. Passes can be for every event in the sereice or access to a set number of
                shows.
              </p>
              <div
                class="columns"
                v-for="(pass, index) in series.passes"
                :key="index"
              >
                <div class="column">
                  <div class="field">
                    <label class="label">Pass Name</label>
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="text"
                        placeholder="Pass Name"
                        v-model="pass.name"
                        :disabled="series.status == 'complete'"
                        :class="{ 'is-danger': errors.capacity }"
                        @change="saveSeries()"
                      />
                    </div>
                    <p class="help is-dange r" v-if="errors.capacity">
                      {{ errors.capacity }}
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Pass Description</label>
                    <div class="control">
                      <textarea
                        class="textarea is-fullwidth"
                        v-model="pass.description"
                        @change="saveSeries()"
                        :disabled="series.status == 'complete'"
                      ></textarea>
                    </div>
                    <p class="help is-danger" v-if="errors.capacity">
                      {{ errors.capacity }}
                    </p>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">Price</label>
                    <div class="control has-icons-left">
                      <input
                        class="input is-primary"
                        type="number"
                        placeholder="Price"
                        v-model="pass.price"
                        :disabled="series.status == 'complete'"
                        :class="{ 'is-danger': errors.capacity }"
                        @change="saveSeries()"
                      />
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                    <p class="help is-danger" v-if="errors.capacity">
                      {{ errors.capacity }}
                    </p>
                  </div>
                  <div class="passlimit">
                  <div class="control">
                    <input
                      type="checkbox"
                      :id="'passlimit-' + index"
                      v-model="pass.limitQuantity"
                      :class="{
                        'has-background-color': pass.limitQuantity
                      }"
                      class="is-checkradio is-success"
                      @change="saveSeries"
                    />
                    <label :for="'passlimit-' + index" class="limitQuantity">
                      Limit Quantity
                    </label>
                  </div>
                    <div class="field" v-if="pass.limitQuantity">
                    <label class="label">Quantity Available</label>
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="number"
                        placeholder="Limit"
                        v-model="pass.inventoryLimit"
                        :disabled="series.status == 'complete'"
                        :class="{ 'is-danger': errors.inventoryLimit }"
                        @change="saveSeries()"
                      />
                    </div>
                    <p class="help is-danger" v-if="errors.inventoryLimit">
                      {{ errors.inventoryLimit }}
                    </p>
                  </div>
                </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">Type</label>
                    <div class="select is-fullwidth">
                      <select
                        v-model="pass.type"
                        @change="saveSeries"
                        :disabled="series.status == 'complete'"
                      >
                        <option value="all">All Events</option>
                        <option value="select">Select Events</option>
                      </select>
                    </div>
                  </div>
                  <div class="field" v-if="pass.type == 'select'">
                    <label class="label">Limit</label>
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="number"
                        placeholder="Limit"
                        v-model="pass.limit"
                        :disabled="series.status == 'complete'"
                        :class="{ 'is-danger': errors.capacity }"
                        @change="saveSeries()"
                      />
                    </div>
                    <p class="help is-danger" v-if="errors.capacity">
                      {{ errors.capacity }}
                    </p>
                  </div>
                </div>
                <div class="column is-narrow">
                  <div class="column is-narrow">
                    <span
                      class="icon is-medium delete"
                      v-if="series.status != 'complete'"
                      @click="deletePass(index)"
                      ></span>
                  </div>
                </div>
              </div>
              <p class="buttons">
                <button
                  class="button"
                  :disabled="false"
                  @click="newPass"
                >
                  <span class="icon is-medium">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span>Add pass</span>
                </button>
              </p>
            </div>
          </div>

          <div class="column" v-show="step == 6" v-if="seriesSettings">
            <h3>Email Settings</h3>
            <div class="box">
              <div class="field">
                <label class="label">Email Message</label>
                <label class="label"
                  >Add any extra information you would like to include about the
                  event to be added to the end of the email confirmation
                  (parking, transportation, doors open time, etc.).</label
                >
                <div class="control">
                  <quill-editor
                    ref="emailMessageEditor"
                    :content="seriesSettings.emailMessage"
                    :options="editorOption"
                    :class="{ 'ql-focus': emailEditorHasFocus }"
                    @blur="emailEditorHasFocus = false"
                    @focus="emailEditorHasFocus = true"
                    @change="updateEmail($event)"
                  />
                </div>
              </div>
              <TestEmailSender
                v-if="false"
                type="confirmation"
                :eventId="event.id"
              />
            </div>
            <div class="box">
              <div class="control">
                <input
                  type="checkbox"
                  v-model="seriesSettings.sendReminderEmails"
                  id="showPreshowReminder"
                  @change="saveSettings"
                  :class="{
                    'has-background-color': seriesSettings.sendReminderEmails
                  }"
                  class="is-checkradio is-success"
                />
                <label for="showPreshowReminder">
                  Send Preshow Reminder Emails
                </label>
              </div>
              <div
                class="field bumpadown"
                v-if="seriesSettings && seriesSettings.sendReminderEmails"
              >
                <label class="label">Preshow Reminder Email</label>
                <label class="label"
                  >Add any extra information you would like to include about the
                  event to be added to the end of the preshow reminder email
                  (parking, transportation, doors open time, etc.).</label
                >
                <div class="control">
                  <quill-editor
                    ref="emailMessageEditor"
                    :content="seriesSettings.emailReminderMessage"
                    :options="editorOption"
                    :class="{ 'ql-focus': emailReminderEditorHasFocus }"
                    @blur="emailReminderEditorHasFocus = false"
                    @focus="emailReminderEditorHasFocus = true"
                    @change="updateReminderEmail($event)"
                  />
                </div>
                <TestEmailSender
                  v-if="false"
                  type="reminder"
                  :eventId="event.id"
                />
              </div>
            </div>
            <div class="box">
              <div class="control">
                <input
                  type="checkbox"
                  id="sendThankyouEmails"
                  v-model="seriesSettings.sendThankyouEmails"
                  :class="{
                    'has-background-color': seriesSettings.sendThankyouEmails
                  }"
                  class="is-checkradio is-success"
                  @change="saveSettings"
                />
                <label for="sendThankyouEmails">
                  Send Postshow Thank-You Emails
                </label>
              </div>
              <div
                class="field bumpadown"
                v-if="seriesSettings && seriesSettings.sendThankyouEmails"
              >
                <label class="label">Postshow Thank-You Email</label>
                <label class="label"
                  >Add any extra information you would like to include about the
                  event to be added to the end of the postshow thank-you email
                  (survey, request for feedback, etc.).</label
                >
                <div class="control">
                  <quill-editor
                    ref="emailMessageEditor"
                    :content="seriesSettings.emailThankyouMessage"
                    :options="editorOption"
                    :class="{ 'ql-focus': emailThankyouEditorHasFocus }"
                    @blur="emailThankyouEditorHasFocus = false"
                    @focus="emailThankyouEditorHasFocus = true"
                    @change="updateThankyouEmail($event)"
                  />
                </div>
              </div>
              <TestEmailSender
                v-if="false"
                type="thankyou"
                :eventId="event.id"
              />
            </div>
          </div>

          <div class="column" v-show="step == 7">
            <h3>Shows</h3>
            <div class="box">
              <p>
                Add shows to your event series. Shows are events within the event series
                that tickets are sold to on an individual or group basis.
              </p>
              <div v-for="event in events" :key="event.id" class="columns">
                <div class="column is-narrow">
                  <div class="avatar-generic">
                    <img :src="event.image" alt="" class="eventimage" />
                  </div>
                </div>
                <div class="column">
                  <p class="artistname">
                    <strong
                      ><router-link
                        :to="{
                          name: 'editEvent',
                          params: { id: event.id }
                        }"
                        >{{ (event.title) ? event.title : 'Untitled Event' }}</router-link
                      ></strong
                    > &nbsp;&nbsp;|&nbsp;&nbsp;
                    <span class="city">{{ event.act }}</span>
                  </p>
                </div>
              </div>
              <p class="buttons">
                <button class="button" @click="newShow">
                  <span class="icon is-medium">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span>Add show</span>
                </button>
              </p>
            </div>
          </div>

          <div class="column" v-show="step == 8">
            <h3>Preview + Publish</h3>
            <div class="box">
              <div class="columns">
                <div class="column is-half">
                  <div class="event-preview">
                    <div class="event-preview-header">
                      <img v-if="series.image" :src="series.image" alt="" />
                    </div>
                    <div class="event-preview-body">
                      <!-- <EventDateline :event="event" /> -->
                      <h3>{{ series.title }}</h3>
                      <!-- <h5 v-if="venue">{{ venue.name }}</h5> -->
                      <button class="button is-fullwidth" @click="preview()">
                        Preview Event Series
                      </button>
                    </div>
                  </div>
                </div>
                <div class="column is-half">
                  <div class="control">
                    <label class="radio">
                      <input
                        type="radio"
                        name="answer"
                        v-model="series.isPublic"
                        value="true"
                        @change="saveSeries()"
                      />
                      Public
                    </label>
                    <p class="radio-label">Shared on Gobo and searchable</p>
                    <label class="radio">
                      <input
                        type="radio"
                        name="answer"
                        v-model="series.isPublic"
                        value="false"
                        @change="saveSeries()"
                      />
                      Private
                    </label>
                    <p class="radio-label">
                      Only available to you and the people you share it with.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box" v-if="cantPublish && series.status == 'draft'">
              <h4>Before you publish</h4>
              <p>
                There's a few things you need to do before you can publish your
                series.
              </p>
              <ul>
                <li v-if="!series.title">
                  Please give your event series a title
                </li>
                <li v-if="!series.genre">Set an series type</li>
                <li v-if="!series.description">
                  Give your event series a description
                </li>
                <li v-if="!series.image">Add an image for your event series</li>
                <!-- <li
                  v-if="
                    !festivalDates ||
                      eventDates.length < 1 ||
                      (eventDates.length == 1 && !eventDates[0].id)
                  "
                >
                  Add at least one date
                </li> -->
                <!-- <li v-if="!event.venue">Set a location for your event</li>
                <li v-if="!event.capacity">Specify your event capacity</li>
                <li
                  v-if="
                    !priceTiers ||
                      priceTiers.length < 1 ||
                      (priceTiers.length == 1 && !priceTiers[0].id)
                  "
                >
                  Set at least one pricing tier for your event
                </li> -->
              </ul>
            </div>
          </div>

          <div
            class="column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
          ></div>
        </div>
      </div>
    </div>
    <div class="box stepbar">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="buttons is-right">
              <button class="button" v-if="step > 1" @click="back()">
                Back
              </button>
              <button
                class="button is-primary"
                v-if="step < 7"
                @click="next()"
                :disabled="cropping || (step == 0 && !series)"
              >
                Next
              </button>
              <button
                class="button is-primary"
                v-if="step == 7"
                @click="next()"
              >
                Preview
              </button>
              <button
                class="button is-primary"
                v-if="series.status == 'draft' && step == 8"
                @click="publish()"
                :disabled="cantPublish"
              >
                Publish
              </button>
            </div>
          </div>
          <div
            class="column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/assets/variables.scss";
.profile {
  border-bottom: none;
}
.event-preview {
  border-radius: 6px;
}
.event-preview-header,
.event-preview-header img {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  line-height: 0;
}
.event-preview-body {
  padding: 1em;
  border: 2px solid #f2f2f2;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.event-preview-body h3 {
  margin-bottom: 0.5em;
}
.event-preview-body h5 {
  margin-bottom: 1.2em;
}
.event-preview-body h6 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
  margin-bottom: 0.5em;
}
.radio-label {
  font-size: 16px;
  margin-top: 2px;
}
.upload {
  font-family: $family-primary;
  display: flex;
}
.eventimageheader {
  border-radius: 6px;
}
.ql-editor p {
  margin: 1em 0;
}
.ql-editor p:first-of-type {
  margin: 0 0 1em 0;
}
.bumpadown {
  margin-top: 10px;
}
.box .field:not(:first-child) {
  margin-top: 30px;
}
p.artistname a {
  color: $active-green;
}
.passlimit {
  margin-top: 25px;
}
.limitQuantity {
  font-size: 14px !important;
}
.discountlimit {
  margin-top: 15px;
}
@media screen and (max-width: 768px) {
  .is-flex-mobile {
    flex: 1;
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
//import EventDate from "@/components/eventDate";
//import EventDateline from "@/components/event-dateline";
//import PricingTier from "@/components/pricingTier";
import DiscountCodes from "@/components/editEvent/discount-codes";
import VenueMap from "@/components/venue-map";
import Venue from "@/components/venue";
import UploadImage from "@/components/upload-image";
import DateField from "../components/formControls/date-field.vue";
//ximport TestEmailSender from "@/components/test-email-sender"
//import moment from "moment";
import firebase from "firebase/app";
import Bugsnag from "@bugsnag/browser";

export default {
  name: "editEventSeries",
  props: ["eventSeriesId", "id"],
  mounted() {},
  async created() {
    if (!this.id) {
      // if (this.user && this.user.loggedIn && this.user.email)
      //   console.log("we've got a live one!", this.user);
      // else
      //   store.subscribe(mutation => {
      //     if (mutation.type === "SET_USER") {
      //       var artist = null;
      //       var venue = null;
      //       if (this.profiles && this.profiles.length == 1) {
      //         artist = this.profiles[0];
      //         this.eventType = artist.type;
      //       }
      //       if (this.venues && this.venues.length == 1) venue = this.venues[0];
      //       store.dispatch("newEvent", { artist: artist, venue: venue });
      //       if (venue) store.dispatch("fetchVenue", venue.id);
      //     }
      //   });
      console.log("new series");
      store.dispatch("newSeries", {});
    } else {
      console.log("Getting the series");
      await store.dispatch("fetchEventSeries", this.id);
      // this.seriesDescription = this.series.description;
      if (this.series.venue)
        await store.dispatch("fetchVenue", this.series.venue);
      await store.dispatch("fetchSeriesEvents", this.series.id);
      this.step = 1;
    }
  },
  data() {
    return {
      step: 1,
      newDate: true,
      newTier: true,
      unsavedTier: null,
      editing: null,
      cropping: false,
      showImageLoading: false,
      percentComplete: 0,
      locationError: "",
      seriesDescription: "",
      ticketTotal: null,
      errors: {},
      dirty: {},
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "link", "image"],
            [{ list: "ordered" }, { list: "bullet" }]
          ]
        },
        placeholder: "Add your description here"
      },
      editorHasFocus: false,
      emailEditorHasFocus: false,
      emailReminderEditorHasFocus: false,
      emailThankyouEditorHasFocus: false,
      fields: [
        "place_id",
        "name",
        "formatted_phone_number",
        "website",
        "formatted_address",
        "address_components",
        "geometry"
      ]
    };
  },
  components: {
    // EventDate,
    // PricingTier,
    // EventDateline,
    DiscountCodes,
    DateField,
    VenueMap,
    Venue,
    UploadImage
    // TestEmailSender
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      series: "series",
      seriesSettings: "seriesSettings",
      user: "user",
      venue: "venue",
      events: "seriesEvents",
    }),
    cantPublish() {
        if (
          !this.series.title ||
          !this.series.genre ||
          !this.series.description ||
          !this.series.image ||
          !this.series.venue
        ) return true;
        return false;
    }
  },
  methods: {
    async next() {
      if (this.step == 1 && !this.series.id) {
        await this.saveSeries();
      } else {
        if (this.step > this.series.stepsCompleted)
          this.series.stepsCompleted = this.step;
        await this.saveSeries();
      }
      this.step++;
      if (this.step == 3) this.step = 4;
      if (this.step == 6) this.step = 7;
    },
    async back() {
      this.step--;
      if (this.step < 1) this.step = 1;
      if (this.step == 3) this.step = 2;
      if (this.step == 6) this.step = 5;
    },
    // async saveNewEvent() {
    //   this.event.stepsCompleted = 1;
    //   this.event.ownerId = this.user.uid;
    //   await store.dispatch("createEvent", this.event);
    //   console.log("Event id: " + this.event.id);
    //   store.dispatch(
    //     "stashCurrentEventIdForDiscountsThisIsDumb",
    //     this.event.id
    //   );
    // },
    async setStep(step) {
      console.log("Step set: " + step);
      if (this.series.step == 1 && !this.series.id) {
        await this.saveSeries();
      }
      this.step = step;
    },
    // newEvent() {
    //   this.newDate = true;
    //   store.dispatch("addNewDate");
    // },
    // newPriceTier() {
    //   this.newTier = true;
    //   this.unsavedTier = {};
    // },
    newPass() {
      if (!this.series.passes) this.series.passes = [];
      this.series.passes.push({ name: "" });
      this.saveSeries();
    },
    newDiscount() {
      console.log("Adding discount");
      if (!this.seriesSettings.discounts) this.seriesSettings.discounts = [];
      this.seriesSettings.discounts.push({ discountCode: "" });
      // TODO: Wrap all these in a method
      store.dispatch("updateseriesSettings", {
        festivalId: this.series.festivalId,
        seriesId: this.series.id,
        settings: this.seriesSettings
      });
    },
    async newShow() {
      var neweventId = await store.dispatch("newSeriesEvent", {
        seriesId: this.series.id,
        venueId: this.series.venue,
        venueName: this.series.venueName,
        genre: this.series.genre,
        type: this.series.genre,
        ownerId: this.user.uid
      });
      console.log("id", neweventId);
      this.$router.push({
        name: "editEvent",
        params: { id: neweventId }
      });
    },
    deletePass(index) {
      //console.log("index is", index);
      this.series.passes.splice(index, 1);
      this.saveSeries();
    },
    saveDiscount(change) {
      console.log("got the change", change);
      //this.seriesSettings.discounts[change.index] == change.discount;
      this.seriesSettings.discounts.splice(change.index, 1, change.discount);
      this.saveSettings();
    },
    saveSeriesDescription(event) {
      this.series.description = event.html;
      this.saveSeries();
    },
    setPlace(e) {
      console.log(e);
      if (!e.address_components || e.address_components.length < 7) {
        this.locationError =
          "Invalid location. Location must have a valid address.";
        this.$analytics.logEvent("invalid_event_address", { address: e });
        Bugsnag.notify("Invalid address response.", e);
      } else {
        // An eight-part address contains a neighborhood. A seven-part does not.
        var offset = e.address_components.length == 7 ? 2 : 3;
        this.locationError = "";
        store.dispatch("setVenue", {
          id: e.place_id,
          name: e.name,
          phone: e.formatted_phone_number ? e.formatted_phone_number : null,
          website: e.website ? e.website : null,
          fullAddress: e.formatted_address,
          location: {
            lat: e.geometry.location.lat(),
            lng: e.geometry.location.lng()
          },
          address:
            e.address_components[0].long_name +
            " " +
            e.address_components[1].long_name,
          city: e.address_components[offset].long_name,
          state: e.address_components[offset + 2].long_name,
          zip: e.address_components[offset + 4].long_name
        });

        this.series.venue = e.place_id;
        this.series.venueName = e.name;
        store.dispatch("saveEventSeries", this.series);
      }
    },
    // async saveTier(tier) {
    //   console.log("saving tier");
    //   if (!this.series.priceTiers) this.series.priceTiers = [];
    //   if (tier.id == null) {
    //     // await store.dispatch("createTier", tier);
    //     // this.newTier = false;
    //     tier.id = this.series.priceTiers.length;
    //     this.series.priceTiers.push(tier);
    //     this.saveSeries();
    //     this.unsavedTier = null;
    //   } else await store.dispatch("updateTier", tier);
    // },
    // deleteTier(tier) {
    //   console.log("tier");
    //   store.dispatch("showConfirmation", {
    //     header: "Delete tier?",
    //     message: "Are you sure you want to delete this tier?",
    //     cta: "delete",
    //     action: "deleteTier",
    //     object: tier
    //   });
    //   console.log(tier);
    // },
    earlyDiscountDateSet(date) {
      console.log("early discount end date set", date);
      this.series.earlyDiscountEndDate = date;
      this.saveSeries();
    },
    saveSettings() {
      store.dispatch("updateseriesSettings", {
        festivalId: this.series.festivalId,
        seriesId: this.series.id,
        settings: this.seriesSettings
      });
    },
    updateEmail(event) {
      console.log("editor change!", event.html);
      this.seriesSettings.emailMessage = event.html;
      this.saveSettings();
      //this.content = html;
    },
    updateReminderEmail(event) {
      console.log("editor change!", event.html);
      this.seriesSettings.emailReminderMessage = event.html;
      this.saveSettings();
    },
    updateThankyouEmail(event) {
      console.log("editor change!", event.html);
      this.seriesSettings.emailThankyouMessage = event.html;
      this.saveSettings();
    },
    async saveSeries() {
      if (!this.series.id) {
        this.series.ownerId = this.user.uid;
        this.series.owners = {};
        this.series.owners[this.user.uid] = { name: this.user.name };
        await store.dispatch("createEventSeries", this.series);
        this.$router.push({
          name: "editEventSeries",
          params: { id: this.series.id }
        });
      } else store.dispatch("saveEventSeries", this.series);
    },
    saveImage(e) {
      console.log(e);
    },
    croppie(e) {
      e.preventDefault();
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      e.currentTarget.classList.remove("drop-active");
      this.cropping = true;

      var reader = new FileReader();
      reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };

      reader.readAsDataURL(files[0]);
    },
    cancel() {
      this.cropping = false;
    },
    async crop(image) {
      // Options can be updated.
      // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
      this.cropping = false;
      this.showImageLoading = true;
      // let options = {
      //   type: "blob",
      //   size: { width: 800, height: 360 },
      //   format: "jpeg"
      // };
      var output = image; //, output => {
      var storageRef = firebase.storage().ref();
      var imagestr = "festivalseriess/" + this.series.id + ".jpg";
      var imageRef = storageRef.child(imagestr);
      var upload = imageRef.put(output);

      upload.on(
        "state_changed",
        upload => {
          var progress = (this.percentComplete =
            (upload.bytesTransferred / upload.totalBytes) * 100);
          console.log("Upload is " + progress + "% done");
        },
        e => {
          console.log("Upload error: ", e);
        },
        () => {
          upload.snapshot.ref.getDownloadURL().then(async url => {
            this.series.image = url;
            await this.saveSeries();
            this.showImageLoading = false;
          });
        }
      );
    },
    dragover(event) {
      event.preventDefault();
      event.currentTarget.classList.add("drop-active");
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove("drop-active");
    },
    drop(event) {
      event.preventDefault();
      var reader = new FileReader();
      reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };
      //this.$refs.croppieRef = event.dataTransfer.files;
      //this.$refs.file.files = event.dataTransfer.files;
      //this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.remove("drop-active");
    },
    preview() {
      this.$router.push({ name: "previewSeries", id: this.series.id });
    },
    async publish() {
      var db = firebase.firestore();
      await db
        .collection("eventseries")
        .doc(this.series.id)
        .update({ status: "publishMe" })
        .catch(function(error) {
          console.error("Error publishing event: ", error);
        });
      this.$router.push({ name: "eventSeriesPublished" });
    },
    seriesTypeChange() {
      // console.log("etc", this.series.genre, this.eventType);
      // if (this.eventType != "other") this.series.genre = this.eventType;
      // else this.series.genre = "";
      this.saveSeries();
    },
    setHasDiscount(val) {
      console.log("setting has discount", val);
      this.event.hasDiscounts = val;
      this.saveSeries();
    },
    getowners(artist) {
      if (artist.owners) {
        let names = "";
        let x = 0;
        for (const owner in artist.owners) {
          if (x == 1) names += ", ";
          console.log("owner: ", owner);
          names += artist.owners[owner].name;
          x = 1;
        }
        return names;
      } else return "This artist is not using Gobo yet.";
    },
    clearVenue() {
      store.dispatch("clearVenue");
    },
  },
  watch: {
    event: {
      handler(newval) {
        // Mark dirties
        if (newval.title) this.dirty.title = true;
        if (this.eventType == "other" && newval.genre) this.dirty.genre = true;
        if (newval.description) this.dirty.description = true;
        if (newval.capacity) this.dirty.capacity = true;
        // Title is required
        if (this.dirty.title) {
          if (!newval.title)
            this.errors.title = "Please provide a title for this event.";
          else this.errors.title = null;
        }
        // Genre is required
        if (this.eventType == "other" && this.dirty.genre) {
          if (!newval.genre)
            this.errors.genre = "Please specify the type of event.";
          else this.errors.genre = null;
        }
        // Description is required
        if (this.dirty.description) {
          if (!newval.description)
            this.errors.description = "Please add an event description.";
          else this.errors.description = null;
        }
        // Capacity is required
        if (this.dirty.capacity) {
          if (!newval.capacity)
            this.errors.capacity =
              "Please enter a total number of tickets for this event greater than 0.";
          else this.errors.capacity = null;
        }
      },
      deep: true
    }
  }
};
</script>
