var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body-wrapper" }, [
    _c("div", { staticClass: "body-content gray" }, [
      _c("div", { staticClass: "typecont" }, [
        _c("h1", [_vm._v("Choose Event Type")]),
        _c("div", [
          _c("div", { staticClass: "columns" }, [
            _vm._m(0),
            _c("div", { staticClass: "column" }, [
              _c("h3", [_vm._v("Single Event")]),
              _c("p", [
                _vm._v(
                  "The same artist or line up of artists for one or more performances at the same venue."
                )
              ]),
              _c("div", { staticClass: "buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-primary",
                    on: {
                      click: function($event) {
                        return _vm.createEvent()
                      }
                    }
                  },
                  [_vm._v("Create Event")]
                )
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "columns", staticStyle: { "margin-top": "30px" } },
            [
              _vm._m(1),
              _c("div", { staticClass: "column" }, [
                _c("h3", [_vm._v("Event Series")]),
                _c("p", [
                  _vm._v(
                    "The same artist or line up of artists for one or more performances at the same venue."
                  )
                ]),
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button is-primary",
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "createEventSeries" })
                        }
                      }
                    },
                    [_vm._v("Create Event Series")]
                  )
                ])
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("img", {
        attrs: {
          src: "/img/fill-house.png",
          alt: "single event",
          width: "140px"
        }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("img", { attrs: { src: "/img/series.png", alt: "event series" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }