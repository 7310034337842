import Vue from "vue";
import Vuex from "vuex";

import * as user from "@/store/user.js";
import * as events from "@/store/events.js";
import * as venues from "@/store/venues.js";
import * as festivals from "@/store/festivals.js";
import * as app from "@/store/app.js";
import * as tix from "@/store/tix.js";
import * as cart from "@/store/cart.js";
import * as discounts from "@/store/discounts.js";
import * as invite from "@/store/invite.js";
import * as artist from "@/store/artist.js";
import * as orders from "@/store/orders.js";
import * as series from "@/store/series.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    events,
    venues,
    festivals,
    app,
    tix,
    cart,
    discounts,
    invite,
    artist, 
    orders,
    series
  }
});
