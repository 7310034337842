<template>
  <div id="buytix">
    <div id="modalcolumns" class="columns modalcolumns" v-if="!orderComplete">
      <div id="ticketleft" class="column mobilepad">
        <div
          class="modal-content-wrap"
          v-if="!checkout"
          :class="{ 'is-hidden-mobile': cart }"
        >
          <EventPreview
            :event="event"
            :selectedDate="selectedDate"
            :venue="venue"
          />
          <DateSlider
            :dates="eventDates"
            :visible="visible"
            @setDate="setDate"
            v-if="
              eventDates.length > 1 &&
                (!event.eventType || event.eventType != 'videoOnDemand')
            "
          />
          <div class="price-tiers mobilepad" v-if="selectedDate">
            <div v-for="tier in priceTiers" class="pricetier" :key="tier.id">
              <div
                class="columns is-mobile"
                v-if="
                  !tier.selectDates ||
                    (tier.selectDates &&
                      tier.selectedDates.indexOf(selectedDate.id) > -1)
                "
              >
                <div class="column">
                  <h4>{{ tier.name }}</h4>
                  <p class="tier-description" v-if="tier.description">
                    {{ tier.description }}
                  </p>
                  <p v-if="tier.feeType == 'fixed'">${{ tier.price }}</p>
                  <p v-if="tier.feeType == 'free'">Free!</p>
                  <div v-if="tier.feeType == 'sliding'">
                    <div>
                      <span
                        ><strong>${{ tier.price }} per ticket</strong></span
                      >
                    </div>
                    <div>
                      <input
                        class="slider is-fullwidth is-success"
                        step="1"
                        :min="tier.minPrice"
                        :max="tier.maxPrice"
                        v-model.number="tier.price"
                        type="range"
                      />
                    </div>
                    <div class="level is-mobile">
                      <div class="level-left">${{ tier.minPrice }}</div>
                      <div class="level-right">${{ tier.maxPrice }}</div>
                    </div>
                  </div>
                </div>
                <div class="column is-narrow quantity">
                  <div class="select">
                    <select v-model.number="tier.quantity">
                      <option value="0">0</option>
                      <option
                        value="1"
                        v-if="
                          !selectedDate.maxSale || selectedDate.maxSale >= 1
                        "
                      >
                        1
                      </option>
                      <option
                        value="2"
                        v-if="
                          !selectedDate.maxSale || selectedDate.maxSale >= 2
                        "
                      >
                        2
                      </option>
                      <option
                        value="3"
                        v-if="
                          !selectedDate.maxSale || selectedDate.maxSale >= 3
                        "
                      >
                        3
                      </option>
                      <option
                        value="4"
                        v-if="
                          !selectedDate.maxSale || selectedDate.maxSale >= 4
                        "
                      >
                        4
                      </option>
                      <option
                        value="5"
                        v-if="
                          !selectedDate.maxSale || selectedDate.maxSale >= 5
                        "
                      >
                        5
                      </option>
                      <option
                        value="6"
                        v-if="
                          !selectedDate.maxSale || selectedDate.maxSale >= 6
                        "
                      >
                        6
                      </option>
                      <option
                        value="7"
                        v-if="
                          !selectedDate.maxSale || selectedDate.maxSale >= 7
                        "
                      >
                        7
                      </option>
                      <option
                        value="8"
                        v-if="
                          !selectedDate.maxSale || selectedDate.maxSale >= 8
                        "
                      >
                        8
                      </option>
                      <option
                        value="9"
                        v-if="
                          !selectedDate.maxSale || selectedDate.maxSale >= 9
                        "
                      >
                        9
                      </option>
                      <option
                        value="10"
                        v-if="
                          !selectedDate.maxSale || selectedDate.maxSale >= 10
                        "
                      >
                        10
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="donatio pricetier" v-if="event.donation">
              <h4 v-if="!event.donationDetails || !event.donationDetails.name">
                Artist Donation
              </h4>
              <h4 v-if="event.donationDetails && event.donationDetails.name">
                {{ event.donationDetails.name }}
              </h4>
              <p
                v-if="
                  !event.donationDetails || !event.donationDetails.description
                "
                class="tier-description"
              >
                Add a tip for the artist
              </p>
              <p
                v-if="
                  event.donationDetails && event.donationDetails.description
                "
                class="tier-description"
              >
                {{ event.donationDetails.description }}
              </p>
              <div
                v-if="
                  !event.donationDetails ||
                    event.donationDetails.type != 'sliding'
                "
                class="control donations"
              >
                <div class="field">
                  <input
                    type="radio"
                    id="donationfive"
                    class="is-checkradio"
                    name="donation"
                    v-model.number="donation"
                    value="5"
                  />
                  <label for="donationfive">$5</label>
                  <input
                    type="radio"
                    id="donationten"
                    class="is-checkradio"
                    name="donation"
                    v-model.number="donation"
                    value="10"
                  />
                  <label for="donationten">$10</label>
                  <input
                    type="radio"
                    id="donationfifteen"
                    class="is-checkradio"
                    name="donation"
                    v-model.number="donation"
                    value="15"
                  />
                  <label for="donationfifteen">$15</label>
                  <input
                    type="radio"
                    id="donationtwenty"
                    class="is-checkradio"
                    v-model.number="donation"
                    name="donation"
                    value="20"
                  />
                  <label for="donationtwenty">$20</label>
                  <input
                    type="radio"
                    id="donationtwentyfive"
                    class="is-checkradio"
                    v-model.number="donation"
                    name="donation"
                    value="25"
                  />
                  <label for="donationtwentyfive">$25</label>
                </div>
              </div>
              <div
                v-if="
                  event.donationDetails &&
                    event.donationDetails.type == 'sliding'
                "
              >
                <div>
                  <span
                    ><strong>${{ donation }} donation</strong></span
                  >
                </div>
                <div>
                  <input
                    class="slider is-fullwidth is-success"
                    step="1"
                    :min="event.donationDetails.minPrice"
                    :max="event.donationDetails.maxPrice"
                    v-model.number="donation"
                    type="range"
                  />
                </div>
                <div class="level is-mobile">
                  <div class="level-left">${{ event.donationDetails.minPrice }}</div>
                  <div class="level-right">${{ event.donationDetails.maxPrice }}</div>
                </div>
              </div>
            </div>

            <div class="donatio pricetier" v-if="event.festivalDonation">
              <h4>Festival Donation</h4>
              <p class="tier-description">
                Add a donation to the Twin Cities Horror Festival.
              </p>
              <div class="control donations">
                <div class="field">
                  <input
                    type="radio"
                    id="donationfive"
                    class="is-checkradio"
                    name="donation"
                    v-model.number="donation"
                    value="5"
                  />
                  <label for="donationfive">$5</label>
                  <input
                    type="radio"
                    id="donationten"
                    class="is-checkradio"
                    name="donation"
                    v-model.number="donation"
                    value="10"
                  />
                  <label for="donationten">$10</label>
                  <input
                    type="radio"
                    id="donationfifteen"
                    class="is-checkradio"
                    name="donation"
                    v-model.number="donation"
                    value="15"
                  />
                  <label for="donationfifteen">$15</label>
                  <input
                    type="radio"
                    id="donationtwenty"
                    class="is-checkradio"
                    v-model.number="donation"
                    name="donation"
                    value="20"
                  />
                  <label for="donationtwenty">$20</label>
                  <input
                    type="radio"
                    id="donationtwentyfive"
                    class="is-checkradio"
                    v-model.number="donation"
                    name="donation"
                    value="25"
                  />
                  <label for="donationtwentyfive">$25</label>
                </div>
              </div>
            </div>
          </div>
          <div class="expand"></div>
          <div class="columns mobilepad" style="margin-bottom: 15px">
            <div class="column" id="poweredby">
              <img src="/img/powered-by-gobo2.svg" alt="gobo" />
            </div>
            <div class="is-hidden-mobile column">
              <button
                class="button is-pulled-right"
                @click="addToCart()"
                :disabled="eventDates > 1 && !selectedDate"
              >
                Add to Cart
              </button>
            </div>
            <div class="is-hidden-tablet column">
              <button
                class="button is-fullwidth"
                @click="addToCart()"
                :disabled="eventDates > 1 && !selectedDate"
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
        <div v-if="checkout">
          <div class="checkout-header columns">
            <div class="column is-narrow">
              <span class="icon is-large" @click="checkout = false">
                <i class="fas fa-2x fa-chevron-left"></i>
              </span>
            </div>
            <div class="column has-text-centered">
              <h3 class=".has-text-centered">Checkout</h3>
              <p>Time Left {{ timeRemaining }}</p>
            </div>
          </div>
          <hr />
          <h3>Contact information</h3>
          <p v-if="false">
            Continue as a guest or sign in for a faster experience
          </p>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">First Name</label>
                <div class="control">
                  <input
                    v-if="profile && profile.firstName"
                    class="input"
                    type="text"
                    disabled="true"
                    placeholder="Email"
                    :value="profile.firstName"
                  />
                  <input
                    v-if="!profile || !profile.firstName"
                    class="input"
                    type="text"
                    placeholder="First Name"
                    v-model="firstName"
                  />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Last Name</label>
                <div class="control">
                  <input
                    v-if="profile && profile.lastName"
                    class="input"
                    type="text"
                    disabled="true"
                    placeholder="Email"
                    :value="profile.lastName"
                  />
                  <input
                    v-if="!profile || !profile.lastName"
                    class="input"
                    type="text"
                    placeholder="Last Name"
                    v-model="lastName"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Email</label>
                <div class="control">
                  <input
                    v-if="profile && profile.email"
                    class="input"
                    type="text"
                    disabled="true"
                    placeholder="Email"
                    :value="profile.email"
                  />
                  <input
                    v-if="!profile || !profile.email"
                    class="input"
                    type="text"
                    placeholder="Email"
                    v-model="email"
                  />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Confirm Email</label>
                <div class="control">
                  <input
                    v-if="profile && profile.email"
                    class="input"
                    type="text"
                    disabled="true"
                    placeholder="Email"
                    :value="profile.email"
                  />
                  <input
                    v-if="!profile || !profile.email"
                    class="input"
                    type="text"
                    placeholder="Confirm Email"
                    v-model="confirmEmail"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              summary.discountCode || (summary.total > 0 && event.hasDiscounts)
            "
          >
            <hr />
            <h3>Discount Code</h3>
            <div class="field" v-if="!summary.discountCode">
              <label class="label">Enter Discount Code, if applicable</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="Discount Code"
                  v-model="discountCode"
                  style="text-transform: uppercase"
                />
              </div>
            </div>
            <p class="help is-danger" v-if="this.discountError">
              {{ this.discountError }}
            </p>
            <p v-if="summary.discountCode">
              Discount code <strong>{{ summary.discountCode }}</strong> applied.
            </p>
            <button
              class="button"
              @click="applyDiscount()"
              :disabled="discountCode == ''"
              v-if="!summary.discountCode"
            >
              Apply Code
            </button>
          </div>
          <div v-if="inProgressPaid || summary.total > 0">
            <hr />
            <h3>Payment Information</h3>
            <form id="payment-form">
              <div id="card-element">
                <!-- Elements will create input elements here -->
              </div>

              <!-- We'll put the error messages in this element -->
              <div id="card-errors" role="alert"></div>
            </form>
          </div>
          <hr />
          <label class="checkbox">
            <input type="checkbox" v-model="optin" /> Please add me to the
            artist’s mailing list</label
          >
          <p class="legal">
            By clicking “Place Order”, I accept the Terms of Service and have
            read the Privacy Policy. I agree that Gobo may share my information
            with the event organizer.
          </p>
        </div>
      </div>
      <div
        class="column is-one-third modal-right"
        id="ordersummary"
        :class="{ 'is-hidden-mobile': !cart }"
      >
        <div>
          <a id="modalclose" class="is-hidden-mobile" @click="canceled()"
            ><i class="fas fa-times"></i
          ></a>
        </div>

        <h4 class="ordersummary">Order Summary</h4>
        <p class="is-family-primary" v-if="cartExpired">
          Your cart has timed out and your tickets have been released back into
          the wild.
        </p>
        <div
          class="empty-cart is-flex is-flex-wrap-wrap is-align-content-center is-justify-content-center is-flex-grow-1"
          v-if="lineItems.length == 0"
        >
          <div><img src="/img/cart.png" alt="cart" /></div>
        </div>
        <div class="cart-summary" v-if="summary.total != null">
          <div
            class="columns is-mobile cart-line"
            v-for="(item, key) in lineItems"
            :key="key"
          >
            <div class="column">
              <strong>{{ moment(item.date).format("M/D") }}:</strong>
              {{ item.quantity }} {{ item.description }}<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.eventTitle }}
            </div>
            <div
              class="column is-narrow"
              v-if="item.total != 0 && !item.discount"
            >
              ${{ formatCurrency(item.total) }}
            </div>
            <div
              class="column is-narrow"
              v-if="item.total != 0 && item.discount && item.originalPrice"
            >
              ${{ formatCurrency(item.originalPrice) }}
            </div>
            <div class="column is-narrow" v-if="item.total == 0">FREE!</div>
            <div class="column is-narrow trash">
              <span class="icon" @click="removeLineItem(key)">
                <i class="fa fa-trash"></i>
              </span>
            </div>
          </div>
          <div class="columns is-mobile cart-line" v-if="summary.donation">
            <div class="column">Donation</div>
            <div class="column is-narrow">
              ${{ formatCurrency(summary.donation) }}
            </div>
            <div class="column is-narrow trash">
              <span class="icon" @click="removeDonation()">
                <i class="fa fa-trash"></i>
              </span>
            </div>
          </div>
          <hr />
          <div class="columns is-mobile subtotal cart-line">
            <div class="column">Subtotal</div>
            <div class="column has-text-right" v-if="summary.subtotal != 0">
              <span v-if="summary && summary.subtotal && !summary.discount"
                >${{ formatCurrency(summary.subtotal) }}</span
              >
              <span v-if="summary && summary.subtotal && summary.discount"
                >${{
                  formatCurrency(summary.subtotal + summary.discount)
                }}</span
              >
            </div>
            <div class="column has-text-right" v-if="summary.subtotal == 0">
              FREE!
            </div>
          </div>
          <div
            class="columns is-mobile subtotal cart-line discount"
            v-if="summary.discount"
          >
            <div class="column">Total Discount</div>
            <div class="column has-text-right" v-if="summary.total != 0">
              -${{ formatCurrency(summary.discount) }}
            </div>
          </div>
          <div v-if="passes">
            <div
              class="columns is-mobile subtotal cart-line discount"
              v-for="pass in summary.passes"
              :key="pass.id"
            >
              <div class="column">{{ pass.passname }}</div>
              <div class="column has-text-right">
                -${{ formatCurrency(pass.discount) }}
              </div>
            </div>
          </div>
          <div class="columns cart-line">
            <div class="column">Never any fees!</div>
          </div>
          <div class="columns cart-line" v-if="event.chargeSalesTax">
            <div class="column">Sales Tax</div>
            <div class="column has-text-right">${{ summary.salesTax }}</div>
          </div>
          <hr />
          <div class="columns is-mobile cart-total">
            <div class="column">Total</div>
            <div class="column has-text-right" v-if="summary.total != 0">
              ${{ formatCurrency(summary.total) }}
            </div>
            <div class="column has-text-right" v-if="summary.total == 0">
              FREE!
            </div>
          </div>
        </div>
        <button
          class="button is-primary"
          @click="checkOut()"
          :disabled="lineItems.length < 1"
          v-if="!checkout"
        >
          Checkout
        </button>
        <button
          class="button is-primary is-fullwidth"
          @click="placeOrder"
          :disabled="!orderReady"
          v-if="checkout"
          :class="{ 'is-loading': inProgress }"
        >
          Place Order
        </button>
        <p v-if="inProgress">
          Placing order: please be patient, this may take a few seconds.
        </p>
        <p v-if="inProgress">
          Step {{ orderProgress }} of 3: {{ orderStatus }}
        </p>
        <p v-if="errorMessage" class="error">Error: {{ errorMessage }}</p>
      </div>
    </div>
    <div id="complete" v-if="orderComplete">
      <a id="modalclose" @click="canceled()"><i class="fas fa-times"></i></a>
      <div class="confirmation-message">
        <div class="columns">
          <div class="column is-one-fifth"></div>
          <div class="column">
            <h2>Thanks for supporting live {{ event.genre }}!</h2>
            <p>Your order is complete!</p>
            <div class="columns event-preview">
              <div class="column is-one-third">
                <img :src="event.image" alt="event.title" class="event-image" />
              </div>
              <div class="column">
                <div class="timeline" v-for="item in lineItems" :key="item.row">
                  <strong
                    >{{ moment(item.date).format("dddd MMM Do") }}:</strong
                  >
                  {{ item.quantity }} {{ item.description }}
                </div>
                <h3>{{ event.title }}</h3>
                <p class="venue" v-if="venue">{{ venue.name }}</p>
              </div>
            </div>
          </div>
          <div class="column is-one-fifth"></div>
        </div>
        <div class="expand"></div>
        <div class="confirmation-footer">
          <img src="/img/powered-by-gobo-white.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import moment from "moment";
import EventPreview from "@/components/event-preview";
import DateSlider from "@/components/date-slider-new";
import { loadStripe } from "@stripe/stripe-js";

export default {
  props: {
    visible: Boolean
  },
  data: function() {
    return {
      selectedDate: null,
      moment: moment,
      cart: false,
      carttimer: null,
      cartExpired: false,
      checkout: false,
      orderComplete: false,
      inProgress: false,
      inProgressPaid: false,
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      optin: false,
      currentOffset: 0,
      sliderIndex: 0,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      orderProgress: 0,
      orderStatus: "Not submitted",
      token: null,
      card: null,
      stripe: null,
      discountCode: "",
      errorMessage: "",
      timeRemaining: "10:00",
      donation: 0
    };
  },
  mounted() {
    console.log("hi");
    if (!this.checkout) this.computeSliderWidth();
    if (this.eventDates.length == 1) this.selectedDate = this.eventDates[0];
    if (this.event.eventType && this.event.eventType == "videoOnDemand")
      this.selectedDate = this.eventDates[this.eventDates.length - 1];
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      event: "event",
      priceTiers: "priceTiers",
      venue: "venue",
      eventDates: "eventDates",
      summary: "summary",
      lineItems: "lineItems",
      discountError: "discountError",
      passes: "passes",
      profile: "profile"
    }),
    orderReady: function() {
      return (
        (this.profile &&
          this.profile.firstName &&
          this.profile.lastName &&
          this.profile.email) ||
        (this.firstName != "" &&
          this.lastName != "" &&
          this.email != "" &&
          this.email == this.confirmEmail)
      );
    },
    passdiscount: function() {
      if (!this.passes) return 0;
      if (this.lineItems) return 0;
      const pass = this.passes[0];
      if (pass.type == "unlimited") return this.lineItems[0].price;
      else {
        // var qty = this.lineItems[0].quantity - this.passes[0].quantity;
        return 15;
      }
      // return 15;
    }
  },
  components: {
    EventPreview,
    DateSlider
  },
  methods: {
    canceled() {
      this.$emit("canceled");
    },
    setTimer() {
      var countDownTime = moment(new Date().getTime())
        .add(10, "m")
        .toDate();
      const self = this;
      this.carttimer = setInterval(function() {
        var now = new Date().getTime();
        var distance = countDownTime - now;
        if (distance > 0) {
          var minutes = moment(distance).get("minutes");
          var seconds = moment(distance).get("seconds");

          seconds = seconds < 10 ? "0" + seconds : seconds;
          self.timeRemaining = minutes + ":" + seconds;
        } else {
          self.clearCart();
          console.log("cart expired");
        }
      }, 1000);
    },
    clearCart() {
      this.checkout = this.cart = false;
      this.cartExpired = true;
      clearInterval(this.carttimer);
      this.carttimer = null;
      store.dispatch("clearCart");
    },
    removeDonation() {
      this.donation = 0;
      store.dispatch("clearDonation");
    },
    addToCart() {
      this.cartExpired = false;
      this.setTimer();
      if (this.eventDates.length == 1) this.selectedDate = this.eventDates[0];
      if (this.donation) store.dispatch("addDonation", this.donation);
      else console.log("wtf");
      this.priceTiers.forEach(tier => {
        if (tier.quantity) {
          let price = 0;
          if (tier.feeType == "fixed" || tier.feeType == "sliding")
            price = tier.price;
          const lineItem = {
            eventId: this.event.id,
            eventTitle: this.event.title,
            dateId: this.selectedDate.id,
            date: this.selectedDate.startDate,
            time: this.selectedDate.startTime,
            tierId: tier.id,
            description: tier.name,
            quantity: tier.quantity,
            price: price,
            total: price * tier.quantity
          };
          if (this.passes && this.passes.length > 0) {
            console.log("Applying passes");
            var passUse = 0;
            const pass = this.passes[0];
            if (pass.type == "limited") {
              if (pass.limit > tier.quantity) passUse = tier.quantity;
              else passUse = passUse = pass.limit;
            } else passUse = 1;
            lineItem.passUse = passUse;
            lineItem.passDiscount = passUse * lineItem.price;
            lineItem.passId = this.passes[0].id;
            store.dispatch("applyPass", {
              passId: this.passes[0].id,
              passname: this.passes[0].passname,
              discount: passUse * lineItem.price
            });
            // lineItem.total = lineItem.total - lineItem.passDiscount;
          }
          store.dispatch("addToCart", lineItem);
          this.$analytics.logEvent("add_to_cart", {
            eventId: this.event.id,
            eventTitle: this.event.title,
            dateId: this.selectedDate.id,
            dateStart: this.selectedDate.start,
            tierId: lineItem.tierId,
            tierDescription: lineItem.description,
            quantity: lineItem.quantity,
            price: lineItem.price,
            total: lineItem.total
          });
          tier.quantity = 0;
        }
      });
      this.cart = true;
    },
    shortDate: function(date) {
      return moment(date).format("ddd, MMM Do");
    },
    formatCurrency: function(dollas) {
      // eslint-disable-next-line no-undef
      if (dollas && !isNaN(dollas)) return dollas.toFixed(2);
      else return "";
    },
    setDate(date) {
      this.selectedDate = date;
      this.$analytics.logEvent("date_selected", {
        eventId: this.event.id,
        eventTitle: this.event.title,
        dateId: date.id,
        dateStart: date.start
      });
    },
    async checkOut() {
      this.checkout = true;
      this.$analytics.logEvent("checkout", {
        eventId: this.event.id,
        eventTitle: this.event.title
      });
      // if money is involved, set up stripe
      if (this.summary.total > 0) {
        this.stripe = await loadStripe(
          "pk_live_51ISVqtJUm2M5Rjrou6d5CyOzMyvXjOMMMESOYjAPOoqkDrExQb17w6yruAXyanmDx8nAADWhUwxKgNihTDvxG3kr00NSYSKxRi",
          {
            stripeAccount: this.event.stripeAccount
          }
        );

        // Set up Stripe.js and Elements to use in checkout form
        var elements = this.stripe.elements();
        var style = {
          base: {
            color: "#32325d"
          }
        };

        this.card = elements.create("card", { style: style });
        this.card.mount("#card-element");

        this.card.on("change", function(event) {
          var displayError = document.getElementById("card-errors");
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = "";
          }
        });
      }
    },
    placeOrder() {
      if (this.summary.total > 0) this.inProgressPaid = true;
      this.inProgress = true;
      this.completeOrder();
    },
    computeSliderWidth() {
      console.log("computing slider width");
      if (this.$refs.slider)
        console.log("width", this.$refs.slider.clientWidth);
    },
    tokenCreated(token) {
      console.log("Stripe token", token);
      this.completeOrder(token);
      // handle the token
      // send it to your server
    },
    completeOrder() {
      const header = {
        eventId: this.event.id,
        lastName: this.lastName,
        firstName: this.firstName,
        email: this.email,
        subtotal: this.summary.subtotal,
        total: this.summary.total,
        optin: this.optin,
        discountCode: this.discountCode
      };
      if (this.summary.passDiscountTotal)
        header.passDiscountTotal = this.summary.passDiscountTotal;
      if (this.summary.passes) header.passes = this.summary.passes;
      if (this.profile) {
        header.firstName = this.profile.firstName;
        header.lastName = this.profile.lastName;
        header.email = this.profile.email;
      }
      if (this.summary.salesTax) header.salesTax = this.summary.salesTax;
      console.log("summart", this.summary);
      console.log("header", header);
      store.dispatch("placeOrder", header, ...this.lineItems);
      this.orderProgress = 1;
      this.orderStatus = "Submitting order";
      this.$analytics.logEvent("placing_order", {
        eventId: this.event.id,
        eventTitle: this.event.title
      });
    },
    applyDiscount() {
      store.dispatch("getDiscount", {
        eventId: this.event.id,
        code: this.discountCode
      });
    }
  },
  watch: {
    summary(newval, oldval) {
      if (newval.clientSecret && !oldval.clientSecret) {
        this.orderProgress = 2;
        this.orderStatus = "Processing payment";
        this.$analytics.logEvent("processing_payment", {
          eventId: this.event.id,
          eventTitle: this.event.title
        });
        this.stripe
          .confirmCardPayment(newval.clientSecret, {
            payment_method: {
              card: this.card,
              billing_details: {
                name: newval.firstName + "" + newval.lastName
              }
            }
          })
          .then(result => {
            if (result.error) {
              // Show error to your customer (e.g., insufficient funds)
              console.log(result.error.message);
              this.errorMessage = result.error.message;
              this.inProgress = false;
              this.$analytics.logEvent("payment_error", {
                eventId: this.event.id,
                eventTitle: this.event.title,
                errorCode: result.error.code,
                errorType: result.error.type,
                errorMessage: result.error.message
              });
            } else {
              // The payment has been processed!
              if (result.paymentIntent.status === "succeeded") {
                store.dispatch("paymentComplete", result);
                // Show a success message to your customer
                // There's a risk of the customer closing the window before callback
                // execution. Set up a webhook or plugin to listen for the
                // payment_intent.succeeded event that handles any business critical
                // post-payment actions.
                this.$analytics.logEvent("payment_complete", {
                  eventId: this.event.id,
                  eventTitle: this.event.title
                });
              } else console.log("something else happened");
            }
          });
      } else if (newval.status == "paymentComplete") {
        this.orderComplete = true;
        store.dispatch("stopListener");
      }
    }
  }
  //   async created() {
  //     await store.dispatch("fetchPriceTiers");
  //   },
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";

#modalclose {
  float: right;
}
.summary {
  background-color: $gray6;
}
.modal-content-wrap {
  margin: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
#modalcolumns {
  margin: 0;
}
.modal-right {
  flex-direction: column;
  justify-content: space-between;
  display: flex !important;
  padding-bottom: 15px;
}
.modal-right .button {
  margin-top: auto;
}
.event-preview {
  .timeline {
    font-size: 12px;
    margin-bottom: 0.5em;
  }
  h3 {
    margin-bottom: 0;
  }
  p.venue {
    font-size: 18px;
    font-family: $family-primary;
    margin: 0.5em 0;
  }
}
div.columns.price-tiers {
  margin-bottom: 3em;
}
.price-tiers {
  margin-bottom: 3em;
  h4 {
    margin-bottom: 0.1em;
  }
  p {
    margin: 0;
  }
}
.cart-summary {
  font-family: $family-secondary;
  margin: 2rem 0;
  h4 {
    font-size: 12px;
    text-transform: uppercase;
  }
  hr {
    background-color: $gray5;
    height: 1px;
    margin: 1rem 0;
  }
  .cart-line {
    font-size: 14px;
  }
  .subtotal {
    font-weight: bold;
  }
  .cart-total {
    font-weight: bold;
  }
}
.legal {
  font-size: 14px;
}
#complete {
  background: url("/img/hero-complete.png") no-repeat;
  background-size: cover;
  align-content: center;
  height: 600px;
  border-radius: $radius;
  .confirmation-message {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}
.expand {
  flex: 1;
}
.confirmation-footer {
  padding: 0 0 20px 30px;
}
#progress {
  padding: 30% 100px;
}
.discount {
  color: $active-green;
}
p.tier-description {
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 16px;
}
.error {
  color: $oops-red;
}
.checkout-header {
  p {
    font-family: $family-sans-serif;
    margin: 0;
  }
  h3 {
    margin-bottom: 5px;
  }
  .column {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
#ordersummary {
  padding-bottom: 1.5rem;
}
.ordersummary {
  font-size: 12px;
  text-transform: uppercase;
}
#modalclose {
  height: 40px !important;
}
.quantity {
  margin-top: auto;
  margin-bottom: auto;
}
.donations {
  margin-top: 24px;
}
.pricetier {
  border-top: 1px solid $gray6;
  padding: 16px 0;
}
.pricetier:first-child {
  border: 0;
}
@media screen and (max-width: 769px) {
  div.columns.is-mobile.event-preview {
    margin: 0 15px;
  }
  #poweredby {
    display: flex;
    justify-content: center;
    padding: 40px 0;
  }
  #buytix {
    padding: 20px 0;
  }
}
</style>
