var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [
    _c(
      "div",
      { class: { hero: !_vm.user.loggedIn }, attrs: { id: "hero" } },
      [
        _c(
          "nav",
          {
            staticClass: "navbar",
            attrs: {
              id: "nav",
              role: "navigation",
              "aria-label": "main navigation"
            }
          },
          [
            _c(
              "div",
              { staticClass: "navbar-brand" },
              [
                _c("router-link", { attrs: { to: { name: "events" } } }, [
                  _c("img", {
                    staticClass: "navlogo",
                    attrs: { src: "/img/gobo-logo-black.svg", alt: "" }
                  })
                ]),
                _c(
                  "a",
                  {
                    staticClass: "navbar-burger",
                    class: { "is-active": _vm.burgerToggle },
                    attrs: {
                      role: "button",
                      "aria-label": "menu",
                      "aria-expanded": "false"
                    },
                    on: {
                      click: function($event) {
                        _vm.burgerToggle = !_vm.burgerToggle
                      }
                    }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }),
                    _c("span", { attrs: { "aria-hidden": "true" } }),
                    _c("span", { attrs: { "aria-hidden": "true" } })
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "navbar-menu",
                class: { "is-active": _vm.burgerToggle }
              },
              [
                _vm.user && _vm.user.loggedIn
                  ? _c(
                      "div",
                      {
                        staticClass: "navbar-item is-hidden-mobile",
                        attrs: { id: "center" }
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "navbar-item",
                            attrs: { to: { name: "events" } }
                          },
                          [_vm._v("My Events")]
                        ),
                        _c(
                          "router-link",
                          {
                            staticClass: "navbar-item",
                            attrs: { to: "/help" }
                          },
                          [_vm._v("Help")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.user || !_vm.user.loggedIn
                  ? _c(
                      "div",
                      {
                        staticClass: "navbar-item is-hidden-mobile",
                        attrs: { id: "center" }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "navbar-item",
                            attrs: { href: "https://tix.gobo.show" }
                          },
                          [_vm._v("Upcoming Events")]
                        ),
                        _c(
                          "router-link",
                          {
                            staticClass: "navbar-item",
                            attrs: { to: "/help" }
                          },
                          [_vm._v("Help")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "navbar-end",
                    on: {
                      click: function($event) {
                        _vm.burgerToggle = !_vm.burgerToggle
                      }
                    }
                  },
                  [
                    !_vm.user || !_vm.user.loggedIn
                      ? _c("div", { staticClass: "navbar-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "navbar-item",
                              on: {
                                click: function($event) {
                                  _vm.login = true
                                }
                              }
                            },
                            [_vm._v("Sign In")]
                          )
                        ])
                      : _vm._e(),
                    _vm.user && _vm.user.loggedIn && !_vm.user.venueManager
                      ? _c("div", { staticClass: "navbar-item shaded" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button is-dark is-flex-mobile",
                              on: { click: _vm.createEvent }
                            },
                            [_vm._v("Create Event")]
                          )
                        ])
                      : _vm._e(),
                    _vm.user && _vm.user.loggedIn && _vm.user.venueManager
                      ? _c("div", { staticClass: "navbar-item shaded" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button is-dark is-flex-mobile",
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "chooseEventType"
                                  })
                                }
                              }
                            },
                            [_vm._v("Create Event")]
                          )
                        ])
                      : _vm._e(),
                    _vm.user && _vm.user.loggedIn && _vm.user.festivalManager
                      ? _c("div", { staticClass: "navbar-item shaded" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button is-dark is-flex-mobile",
                              on: { click: _vm.createFestival }
                            },
                            [_vm._v("Create Festival")]
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "router-link",
                      {
                        staticClass: "navbar-item is-hidden-tablet",
                        attrs: { to: { name: "events" } }
                      },
                      [_vm._v("My Events")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "navbar-item is-hidden-tablet",
                        attrs: { to: "/help" }
                      },
                      [_vm._v("Help")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "navbar-item is-hidden-tablet",
                        on: {
                          click: function($event) {
                            _vm.logout = true
                          }
                        }
                      },
                      [_vm._v("Sign Out")]
                    ),
                    _vm.user && _vm.user.loggedIn
                      ? _c(
                          "div",
                          { staticClass: "navbar-item is-hidden-mobile" },
                          [
                            _c("Avatar"),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "navbar-item has-dropdown is-hoverable"
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "navbar-link",
                                    attrs: { to: "/profile" }
                                  },
                                  [_vm._v(_vm._s(_vm.user.name))]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "navbar-dropdown is-boxed" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "navbar-item",
                                        attrs: { to: "/profile" }
                                      },
                                      [_vm._v("Account Settings")]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "navbar-item",
                                        on: {
                                          click: function($event) {
                                            _vm.logout = true
                                          }
                                        }
                                      },
                                      [_vm._v(" Sign Out ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.user || !_vm.user.loggedIn
                      ? _c("div", { staticClass: "buttons" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button is-dark",
                              on: {
                                click: function($event) {
                                  _vm.signup = true
                                }
                              }
                            },
                            [_vm._v(" Get Started ")]
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ]
        ),
        _c("router-view", {
          on: {
            signmeup: _vm.signmeup,
            addcomp: _vm.addcomp,
            walkup: _vm.walkup,
            sellTickets: _vm.sellTickets,
            sellSeriesPasses: _vm.sellSeriesPasses,
            pass: _vm.pass,
            addpass: _vm.newpass,
            move: _vm.move
          }
        }),
        !_vm.user || !_vm.user.loggedIn ? _c("div") : _vm._e(),
        _c(
          "div",
          {
            staticClass: "modal",
            class: {
              "is-active":
                _vm.createArtist ||
                _vm.createVenue ||
                _vm.signup ||
                _vm.login ||
                _vm.logout ||
                _vm.showConfirm ||
                _vm.addingcomp ||
                _vm.walkingup ||
                _vm.applyingpass ||
                _vm.addingpass ||
                _vm.userInvite ||
                _vm.buyTickets ||
                _vm.buySeriesPasses ||
                _vm.moving
            }
          },
          [
            _vm._v(" > "),
            _c("div", { staticClass: "modal-background" }),
            _c(
              "div",
              {
                staticClass: "modal-content",
                class: { buyTickets: _vm.buyTickets || _vm.buySeriesPasses }
              },
              [
                _c(
                  "div",
                  { staticClass: "box", attrs: { id: "modalwrapper" } },
                  [
                    !_vm.buyTickets
                      ? _c("div", { attrs: { id: "modalheader" } }, [
                          !_vm.invitationId
                            ? _c(
                                "a",
                                {
                                  attrs: { id: "modalclose" },
                                  on: {
                                    click: function($event) {
                                      return _vm.canceled()
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fas fa-times" })]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "modal-inner-wrapper",
                        class: { checkout: _vm.walkingup }
                      },
                      [
                        _vm.signup
                          ? _c("SignUp", {
                              attrs: {
                                invitationId: _vm.invitationId,
                                invitationError: _vm.invitationError
                              },
                              on: {
                                canceled: _vm.canceled,
                                saved: _vm.createduser
                              }
                            })
                          : _vm._e(),
                        _vm.login
                          ? _c("LogIn", {
                              on: {
                                canceled: _vm.canceled,
                                loggedin: _vm.loggedin
                              }
                            })
                          : _vm._e(),
                        _vm.logout
                          ? _c("LogOut", {
                              on: {
                                canceled: _vm.canceled,
                                loggedout: _vm.loggedout
                              }
                            })
                          : _vm._e(),
                        _vm.createArtist
                          ? _c("CreateArtist", {
                              on: {
                                canceled: _vm.canceled,
                                profileCreated: _vm.profileCreated
                              }
                            })
                          : _vm._e(),
                        _vm.createVenue
                          ? _c("CreateVenue", {
                              on: {
                                canceled: _vm.canceled,
                                profileCreated: _vm.profileCreated
                              }
                            })
                          : _vm._e(),
                        _vm.showConfirm && _vm.confirmation
                          ? _c("Confirm", {
                              attrs: {
                                header: _vm.confirmation.header,
                                message: _vm.confirmation.message,
                                cta: _vm.confirmation.cta
                              },
                              on: { canceled: _vm.canceled }
                            })
                          : _vm._e(),
                        _vm.addingcomp
                          ? _c("AddComp", {
                              on: {
                                canceled: _vm.canceled,
                                compAdded: _vm.canceled
                              }
                            })
                          : _vm._e(),
                        _vm.walkingup
                          ? _c("Walkup", {
                              on: {
                                canceled: _vm.canceled,
                                walkupAdded: _vm.canceled
                              }
                            })
                          : _vm._e(),
                        _vm.applyingpass
                          ? _c("ApplyPass", {
                              on: {
                                canceled: _vm.canceled,
                                passApplied: _vm.canceled
                              }
                            })
                          : _vm._e(),
                        _vm.addingpass
                          ? _c("AddPass", {
                              on: {
                                canceled: _vm.canceled,
                                passApplied: _vm.canceled
                              }
                            })
                          : _vm._e(),
                        _vm.moving
                          ? _c("Move", {
                              on: {
                                canceled: _vm.canceled,
                                moved: _vm.canceled
                              }
                            })
                          : _vm._e(),
                        _vm.buyTickets
                          ? _c("BuyTickets", { on: { canceled: _vm.canceled } })
                          : _vm._e(),
                        _vm.buySeriesPasses
                          ? _c("BuySeriesPasses", {
                              on: { canceled: _vm.canceled }
                            })
                          : _vm._e(),
                        _vm.userInvite
                          ? _c("InviteUser", { on: { canceled: _vm.canceled } })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("div", { attrs: { id: "modalfooter" } })
                  ]
                )
              ]
            )
          ]
        )
      ],
      1
    ),
    _c("footer", { staticClass: "footer" }, [
      _c(
        "nav",
        {
          staticClass: "navbar is-hidden-mobile",
          attrs: {
            id: "footer-nav-top",
            role: "navigation",
            "aria-label": "secondary navigation"
          }
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "navbar-end" }, [
            !_vm.user || !_vm.user.loggedIn
              ? _c("div", { staticClass: "navbar-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "navbar-item",
                      on: {
                        click: function($event) {
                          _vm.login = true
                        }
                      }
                    },
                    [_vm._v("Sign In")]
                  )
                ])
              : _vm._e(),
            !_vm.user || !_vm.user.loggedIn
              ? _c("div", { staticClass: "buttons" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button is-light",
                      on: {
                        click: function($event) {
                          return _vm.signmeup("footer")
                        }
                      }
                    },
                    [_vm._v(" Get Started ")]
                  )
                ])
              : _vm._e()
          ])
        ]
      ),
      _vm._m(1),
      _c("nav", { staticClass: "level is-mobile is-hidden-tablet" }, [
        _vm._m(2),
        _c("div", { staticClass: "level-right" }, [
          _c("div", { attrs: { id: "copy" } }, [
            _vm._v("©" + _vm._s(new Date().getFullYear()) + " Gobo Show, Inc")
          ])
        ])
      ]),
      _c("div", { staticClass: "content has-text-centered is-hidden-mobile" }, [
        _c("div", { attrs: { id: "spacer" } }),
        _c(
          "nav",
          {
            staticClass: "navbar",
            attrs: {
              id: "footer-nav",
              role: "navigation",
              "aria-label": "secondary navigation"
            }
          },
          [
            _c("div", { staticClass: "navbar-brand" }, [
              _c("div", { attrs: { id: "copy" } }, [
                _vm._v(
                  "©" + _vm._s(new Date().getFullYear()) + " Gobo Show, Inc"
                )
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "navbar-item is-hidden-mobile",
                attrs: { id: "center" }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "navbar-item",
                    attrs: { href: "https://www.gobo.show/about-us" }
                  },
                  [_vm._v("Our Story")]
                ),
                _c(
                  "router-link",
                  { staticClass: "navbar-item", attrs: { to: "/help" } },
                  [_vm._v("Help")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "navbar-item",
                    attrs: { href: "https://www.gobo.show/privacy-notice" }
                  },
                  [_vm._v("Privacy Notice")]
                ),
                false
                  ? _c(
                      "router-link",
                      { staticClass: "navbar-item", attrs: { to: "/about" } },
                      [_vm._v("Terms of Service")]
                    )
                  : _vm._e(),
                _vm._m(3)
              ],
              1
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "navbar-brand" }, [
      _c("img", {
        staticClass: "navlogo",
        attrs: { src: "/img/gobo-logo-white.svg", alt: "Gobo" }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "social is-hidden-tablet" }, [
      _c("a", { attrs: { href: "https://twitter.com/goboshow/" } }, [
        _c("span", { staticClass: "icon is-large" }, [
          _c("i", { staticClass: "fab fa-2x fa-twitter" })
        ])
      ]),
      _c("a", { attrs: { href: "https://www.facebook.com/Goboshow/" } }, [
        _c("span", { staticClass: "icon is-large" }, [
          _c("i", { staticClass: "fab fa-2x fa-facebook-f" })
        ])
      ]),
      _c("a", { attrs: { href: "https://www.instagram.com/goboshow/" } }, [
        _c("span", { staticClass: "icon is-large" }, [
          _c("i", { staticClass: "fab fa-2x fa-instagram" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "level-left" }, [
      _c("img", {
        staticClass: "navlogo",
        attrs: { src: "/img/gobo-logo-white.svg", alt: "Gobo" }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "navbar-end" }, [
      _c("div", { staticClass: "navbar-item" }, [
        _c("a", { attrs: { href: "https://twitter.com/goboshow" } }, [
          _c("img", {
            staticClass: "socialicon",
            attrs: {
              src: "/img/icons/Twitter.svg",
              alt: "Follow us on Twitter"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "navbar-item" }, [
        _c("a", { attrs: { href: "https://www.facebook.com/Goboshow/" } }, [
          _c("img", {
            staticClass: "socialicon",
            attrs: {
              src: "/img/icons/Facebook.svg",
              alt: "Follow us on Facebook"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "navbar-item" }, [
        _c("a", { attrs: { href: "https://www.instagram.com/Goboshow/" } }, [
          _c("img", {
            staticClass: "socialicon",
            attrs: {
              src: "/img/icons/Instagram.svg",
              alt: "Follow us on Instagram"
            }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }