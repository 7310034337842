var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.series
    ? _c("div", { staticClass: "body-wrapper" }, [
        _c("div", { staticClass: "body-content gray" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "columns is-gapless" }, [
              _c("div", { staticClass: "column is-1 is-hidden-mobile" }),
              _vm.series
                ? _c("div", { staticClass: "column maincont" }, [
                    _c("h1", [_vm._v(_vm._s(_vm.series.title))]),
                    _c("div", { staticClass: "box" }, [
                      _c("div", { staticClass: "tabs" }, [
                        _c("ul", [
                          _c(
                            "li",
                            {
                              class: { "is-active": _vm.tab == "shows" },
                              on: {
                                click: function($event) {
                                  _vm.tab = "shows"
                                }
                              }
                            },
                            [_c("a", [_vm._v("Shows")])]
                          ),
                          _c(
                            "li",
                            {
                              class: { "is-active": _vm.tab == "passes" },
                              on: {
                                click: function($event) {
                                  _vm.tab = "passes"
                                }
                              }
                            },
                            [_c("a", [_vm._v("Pass Sales")])]
                          ),
                          _c(
                            "li",
                            {
                              class: { "is-active": _vm.tab == "orders" },
                              on: {
                                click: function($event) {
                                  _vm.tab = "orders"
                                }
                              }
                            },
                            [_c("a", [_vm._v("Orders")])]
                          )
                        ])
                      ]),
                      _vm.tab == "shows"
                        ? _c("div", [
                            _c("h1", [_vm._v("Events")]),
                            _c(
                              "table",
                              { attrs: { id: "eventTable" } },
                              [
                                _vm._m(0),
                                _vm._l(_vm.events, function(event) {
                                  return _c("EventSummary", {
                                    key: event.id,
                                    attrs: { summary: event }
                                  })
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e(),
                      _vm.tab == "passes"
                        ? _c(
                            "div",
                            [
                              _c("h2", [_vm._v("Pass Sales")]),
                              _vm._l(_vm.series.passes, function(passType) {
                                return _c(
                                  "div",
                                  {
                                    key: passType.id,
                                    staticClass: "passresults"
                                  },
                                  [
                                    _c("h3", [_vm._v(_vm._s(passType.name))]),
                                    _c(
                                      "table",
                                      { staticClass: "table is-fullwidth" },
                                      [
                                        _vm._m(1, true),
                                        _vm._l(
                                          _vm.getPasses(passType.id),
                                          function(pass, index) {
                                            return _c("tr", { key: index }, [
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(pass.firstName) +
                                                    " " +
                                                    _vm._s(pass.lastName)
                                                )
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(pass.email))
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(pass.status))
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(pass.quantity))
                                              ]),
                                              _c("td", [
                                                _vm._v("$" + _vm._s(pass.total))
                                              ])
                                            ])
                                          }
                                        ),
                                        _c("tfoot", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { colspan: "3" } },
                                              [_vm._v("Total")]
                                            ),
                                            _c("th", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .getPasses(passType.id)
                                                    .reduce(
                                                      (sum, pass) =>
                                                        sum + pass.quantity,
                                                      0
                                                    )
                                                )
                                              )
                                            ]),
                                            _c("th", [
                                              _vm._v(
                                                " $" +
                                                  _vm._s(
                                                    _vm
                                                      .getPasses(passType.id)
                                                      .reduce(
                                                        (sum, pass) =>
                                                          sum + pass.total,
                                                        0
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ])
                                        ])
                                      ],
                                      2
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.tab == "orders"
                        ? _c(
                            "div",
                            [
                              _c("OrdersList", {
                                attrs: { orders: _vm.orders }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "column is-1 is-hidden-mobile" })
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "header is-hidden-mobile" }, [
      _c("th", [_vm._v("Event Details")]),
      _c("th", [_vm._v("Sold")]),
      _c("th", [_vm._v("Gross")]),
      _c("th", [_vm._v("Status")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _c("th", [_vm._v("Email")]),
        _c("th", [_vm._v("Status")]),
        _c("th", [_vm._v("Qty")]),
        _c("th", [_vm._v("Paid")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }