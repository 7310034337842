<template>
  <div>
    <h4>Discount Codes</h4>
    <p>
      You can create up to five discount codes for your event (e.g., FREE, %
      off, fixed $ amount and/or BOGO). First type the discount code word,
      ideally nothing too obvious and keep to under 10 characters, then choose
      the discount type.
    </p>
    <DiscountCode
      v-for="(discount, index) in discounts"
      :key="index"
      :codeIndex="index"
      :discount="discount"
      :disabled="disabled"
      :dates="dates"
      :eventId="eventId"
      v-on:codeChanged="codeChanged"
      v-on:deleted="deleteDiscount"
    />
    <p class="buttons">
      <button
        class="button"
        :disabled="hasNew || (discounts && discounts.length > 4)"
        v-if="!disabled"
        @click="newDiscountCode"
      >
        <span class="icon is-medium">
          <i class="fas fa-plus"></i>
        </span>
        <span>Add discount code</span>
      </button>
    </p>
  </div>
</template>

<script>
import store from "@/store";
import DiscountCode from "@/components/discount-code";

export default {
  props: {
    discounts: [],
    disabled: Boolean,
    dates: [],
    priceTiers: [],
    passes: [],
    eventId: String,
    festivalId: String
  },
  components: {
    DiscountCode
  },
  data: function() {
    return {
      newDiscount: false,
      hasdiscounts: false,
      epEndDate: null,
    };
  },
  methods: {
    deleteDiscount(event) {
      console.log("discount", event);
      var confirm = {
        header: "Delete discount code?",
        message: "Are you sure you want to delete this discount code?",
        cta: "delete",
        action: "deleteDiscount",
        object: event,
        eventId: this.eventId,
        festivalId: this.festivalId
      };
      if (this.festivalId) { 
        confirm.action = "deleteFestivalDiscount";
      }
      store.dispatch("showConfirmation", confirm);
      // console.log(discount);
    },
    newDiscountCode() {
      console.log("adding discount code");
      this.$emit("newDiscount");
    },
    codeChanged(change) {
      console.log("detectedChange", change.discount, change.index);
      this.$emit("codeChanged", change);
    }
  },
  computed: {
    hasNew() {
      if (!this.discounts) return false;
      else return this.discounts.findIndex(d => d.id == -1) > -1;
    }
  },
  watch: {
    discounts(newdisc) {
      const filtered = newdisc.filter(disc => disc.id != -1);
      if (filtered.length == 0 && this.hasdiscounts)
        this.$emit("hasDiscountsChange", false);
      else if (filtered.length > 0 && !this.hasdiscounts)
        this.$emit("hasDiscountsChange", true);
      this.hasdiscounts = filtered.length > 0;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
.earlyPurchaseDetails {
  margin-top: 15px;
  margin-left: 35px;
}
.availableDiscounts {
  margin-top: 30px;
}
.discountLine {
  margin: 25px 0;
  display: flex;
  align-items: center;
}
.discountLine div {
  flex: 1;
}
.discountSelector {
  margin-top: 15px;
}
</style>
