<template>
  <div class="body-wrapper" v-if="season">
    <div class="body-content gray">
      <div class="container">
        <div class="columns">
          <div class="column is-one-quarter">
            <h1>
              Create a<br />
              festival!
            </h1>
            <div class="box is-hidden-mobile" v-if="step > 0">
              <div class="block">
                <aside class="menu">
                  <p class="menu-label">
                    Festival Set Up
                  </p>
                  <ul class="menu-list">
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 1 }"
                        @click="setStep(1)"
                        >Basic Info</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 2 }"
                        @click="setStep(2)"
                        >Location</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 3 }"
                        @click="setStep(3)"
                        >Ticket Info</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 4 }"
                        @click="setStep(4)"
                        >Discount Codes</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 5 }"
                        @click="setStep(5)"
                        >Passes</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 6 }"
                        @click="setStep(6)"
                        >Email Settings</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 7 }"
                        @click="setStep(7)"
                        >Shows</a
                      >
                    </li>
                    <li>
                      <a
                        v-bind:class="{ 'is-active': step == 8 }"
                        @click="setStep(8)"
                        >Preview and Publish</a
                      >
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
          </div>

          <div class="column" v-show="step == 0">
            <h4>Choose Festival Profile</h4>
            <div class="box">
              <p>
                Choose a festival profile who is presenting this festival
                season.
              </p>
              <Festival
                v-for="(festival, index) in festivals"
                :key="index"
                :festival="festival"
                :selectable="true"
                :selected="season.festivalId == festival.id"
                v-on:selected="selectFestival"
              />
              <button class="button">
                <span class="icon is-small">
                  <i class="fas fa-plus"></i>
                </span>
                <span>New Festival</span>
              </button>
            </div>
          </div>

          <div class="column" v-show="step == 1">
            <h4>Basic Info</h4>
            <div class="box">
              <div class="field" ref="croppiecont">
                <label class="label">Festival Image</label>
                <img
                  v-if="season.image"
                  :src="season.image"
                  class="eventimageheader"
                  alt=""
                />
                <button
                  v-if="season.image && season.status != 'complete'"
                  class="button"
                  @click="season.image = null"
                >
                  Use a Different Image
                </button>
                <UploadImage
                  v-if="!season.image && !cropping && !showImageLoading"
                  :outputWidth="800"
                  :outputHeight="360"
                  v-on:imageCropped="crop"
                />
                <div v-if="showImageLoading">
                  <progress
                    class="progress is-primary"
                    :value="percentComplete"
                    max="100"
                    >{{ percentComplete }}</progress
                  >
                  <p>Uploading image...</p>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">Festival Title</label>
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="text"
                        placeholder="Festival Title"
                        v-model.lazy="season.title"
                        :disabled="season.status == 'complete'"
                        :class="{ 'is-danger': errors.title }"
                        @change="saveFestival()"
                      />
                    </div>
                    <p class="help is-danger" v-if="errors.title">
                      {{ errors.title }}
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Festival Profile</label>
                    <Festival
                      v-if="season.festivalId"
                      :festival="festival"
                      :editable="false"
                    />
                  </div>
                  <div class="field">
                    <label class="label">Festival Type</label>
                    <div class="select">
                      <select
                        v-model="season.type"
                        :disabled="season.status == 'complete'"
                        @change="eventTypeChange()"
                      >
                        <option value="music">Music</option>
                        <option value="theater">Theater</option>
                        <option value="dance">Dance</option>
                        <option value="improv">Improv</option>
                        <option value="storytelling">Storytelling</option>
                        <option value="standup">Standup Comedy</option>
                        <option value="conferences"
                          >Conferences/Exhibitions</option
                        >
                        <option value="opera">Opera</option>
                        <option value="music theater">Music Theater</option>
                        <option value="art">Visual Art</option>
                        <option value="gatherings">Social Gatherings</option>
                        <option value="sports">Sports</option>
                        <option value="children">Children's Programs</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <p class="help is-danger" v-if="false">
                      Please choose a type.
                    </p>
                  </div>
                  <div class="field" v-if="season.type == 'other'">
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="text"
                        placeholder="Event Type"
                        v-model.lazy="season.typeOther"
                        :disabled="season.status == 'complete'"
                        :class="{ 'is-danger': errors.genre }"
                      />
                    </div>
                    <p class="help is-danger" v-if="errors.genre">
                      {{ errors.genre }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="field">
                <label class="label">Festival Description</label>
                <label class="label"
                  >Add details like what to expect, list of performers or
                  sponsors.</label
                >
                <div class="control">
                  <quill-editor
                    ref="myQuillEditor"
                    :content="seasonDescription"
                    :options="editorOption"
                    :class="{ 'ql-focus': editorHasFocus }"
                    @blur="editorHasFocus = false"
                    @focus="editorHasFocus = true"
                    @change="saveSeasonDescription($event)"
                  />
                </div>
                <p class="help is-danger" v-if="errors.description">
                  {{ errors.description }}
                </p>
              </div>
            </div>
          </div>

          <div class="column" v-show="step == 2">
            <h4>Location</h4>
            <div class="box">
              <div
                class="field"
                v-if="
                  season.status != 'published' && season.status != 'complete'
                "
              >
                <label class="label">Venue Location</label>
                <div class="control has-icons-left" v-if="!venue">
                  <GmapAutocomplete
                    class="input"
                    @place_changed="setPlace"
                    :fields="fields"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-search"></i>
                  </span>
                </div>
                <p class="notification is-danger" v-if="locationError">
                  {{ locationError }}
                </p>
              </div>
              <VenueMap v-if="venue" :venue="venue" />
              <div v-if="venue && venue.owner">
                <p>{{ venue.name }} is using Gobo!</p>
                <Venue :profile="venue" :editable="false" />
              </div>
              <div v-if="venue && !venue.owner">
                <p>
                  {{ venue.name }} is not using Gobo yet. Invite them to help
                  sell tickets with you.
                </p>
                <InviteUserStarter
                  :inviterName="user.name"
                  :inviterId="user.uid"
                  :profileName="venue.name"
                  :venueId="venue.id"
                  :newProfile="true"
                />
              </div>
              <hr v-if="venue" />
              <div v-if="venue">
                <button class="button" @click="clearVenue">
                  Use a Different Venue
                </button>
              </div>
            </div>
          </div>

          <div class="column" v-show="step == 3">
            <h4>Ticket Info</h4>
            <div class="box">
              <p>
                You can create up to five named tiers of tickets (e.g.,
                Pre-sale, VIP, General Admission, Guest List, etc.)
              </p>
              <nav class="level">
                <div class="level-left">
                  <div class="field">
                    <label class="label">Total tickets available</label>
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="number"
                        placeholder="Number of tickets"
                        v-model.number="season.capacity"
                        :disabled="season.status == 'complete'"
                        :class="{ 'is-danger': errors.capacity }"
                        @change="saveFestival()"
                      />
                    </div>
                    <p class="help is-danger" v-if="errors.capacity">
                      {{ errors.capacity }}
                    </p>
                  </div>
                </div>
                <div class="level-right">
                  <div class="field">
                    <input
                      id="donation"
                      type="checkbox"
                      name="switchExample"
                      class="switch"
                      v-model="season.donation"
                      @change="saveFestival()"
                    />
                    <label for="donation">Artist Donation</label>
                  </div>
                </div>
              </nav>
              <hr />
              <PricingTier
                v-for="tier in season.priceTiers"
                :key="tier.id"
                :tier="tier"
                :flexible="season.capacity"
                :disable="season.status == 'complete'"
                v-on:tierSet="saveTier"
                v-on:deleted="deleteTier"
              />
              <PricingTier
                v-if="unsavedTier"
                :tier="unsavedTier"
                :flexible="season.capacity"
                v-on:tierSet="saveTier"
                v-on:deleted="deleteTier"
              />
              <p class="buttons">
                <button
                  class="button"
                  :disabled="
                    unsavedTier ||
                      (season.priceTiers && season.priceTiers.length > 4)
                  "
                  @click="newPriceTier"
                >
                  <span class="icon is-medium">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span>Add ticket tier</span>
                </button>
              </p>
              <div
                v-if="
                  season.priceTiers &&
                    season.priceTiers.length > 0 &&
                    season.priceTiers.filter(t => t.feeType != 'free').length >
                      0
                "
              ></div>
            </div>
          </div>

          <div class="column" v-show="step == 4">
            <h4>Discount Codes</h4>
            <DiscountCodes
              v-if="seasonSettings && seasonSettings.discounts"
              :discounts="seasonSettings.discounts"
              :disabled="season.status == 'complete'"
              :festivalId="season.id"
              v-on:newDiscount="newDiscount"
              v-on:codeChanged="saveDiscount"
            />
          </div>

          <div class="column" v-show="step == 5">
            <h4>Passes</h4>
            <div class="box">
              <p>
                Passes allow patrons access to more than one show with a single
                purchase. Allow unlimited access or access to a set number of
                shows.
              </p>
              <div
                class="columns"
                v-for="(pass, index) in season.passes"
                :key="index"
              >
                <div class="column">
                  <div class="field">
                    <label class="label">Pass Name</label>
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="text"
                        placeholder="Pass Name"
                        v-model="pass.name"
                        :disabled="season.status == 'complete'"
                        :class="{ 'is-danger': errors.capacity }"
                        @change="saveFestival()"
                      />
                    </div>
                    <p class="help is-dange r" v-if="errors.capacity">
                      {{ errors.capacity }}
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Pass Description</label>
                    <div class="control">
                      <textarea
                        class="textarea is-fullwidth"
                        v-model="pass.description"
                        @change="saveFestival()"
                        :disabled="season.status == 'complete'"
                      ></textarea>
                    </div>
                    <p class="help is-danger" v-if="errors.capacity">
                      {{ errors.capacity }}
                    </p>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">Price</label>
                    <div class="control has-icons-left">
                      <input
                        class="input is-primary"
                        type="number"
                        placeholder="Price"
                        v-model="pass.price"
                        :disabled="season.status == 'complete'"
                        :class="{ 'is-danger': errors.capacity }"
                        @change="saveFestival()"
                      />
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                    <p class="help is-danger" v-if="errors.capacity">
                      {{ errors.capacity }}
                    </p>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">Type</label>
                    <div class="select is-fullwidth">
                      <select
                        v-model="pass.type"
                        @change="saveFestival"
                        :disabled="season.status == 'complete'"
                      >
                        <option value="unlimited">Unlimited</option>
                        <option value="limited">Limited</option>
                      </select>
                    </div>
                  </div>
                  <div class="field" v-if="pass.type == 'limited'">
                    <label class="label">Limit</label>
                    <div class="control">
                      <input
                        class="input is-primary"
                        type="number"
                        placeholder="Limit"
                        v-model="pass.limit"
                        :disabled="season.status == 'complete'"
                        :class="{ 'is-danger': errors.capacity }"
                        @change="saveFestival()"
                      />
                    </div>
                    <p class="help is-danger" v-if="errors.capacity">
                      {{ errors.capacity }}
                    </p>
                  </div>
                </div>
                <div class="column is-narrow">
                  <div class="column is-narrow">
                    <span
                      class="icon is-medium delete"
                      v-if="season.status != 'complete'"
                      @click="deletePass(index)"
                      ></span>
                  </div>
                </div>
              </div>
              <p class="buttons">
                <button
                  class="button"
                  :disabled="
                    unsavedTier ||
                      (season.priceTiers && season.priceTiers.length > 4)
                  "
                  @click="newPass"
                >
                  <span class="icon is-medium">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span>Add pass</span>
                </button>
              </p>
            </div>
          </div>

          <div class="column" v-show="step == 6" v-if="seasonSettings">
            <h4>Email Settings</h4>
            <div class="box">
              <div class="field">
                <label class="label">Email Message</label>
                <label class="label"
                  >Add any extra information you would like to include about the
                  event to be added to the end of the email confirmation
                  (parking, transportation, doors open time, etc.).</label
                >
                <div class="control">
                  <quill-editor
                    ref="emailMessageEditor"
                    :content="seasonSettings.emailMessage"
                    :options="editorOption"
                    :class="{ 'ql-focus': emailEditorHasFocus }"
                    @blur="emailEditorHasFocus = false"
                    @focus="emailEditorHasFocus = true"
                    @change="updateEmail($event)"
                  />
                </div>
              </div>
              <TestEmailSender
                v-if="false"
                type="confirmation"
                :eventId="event.id"
              />
            </div>
            <div class="box">
              <div class="control">
                <input
                  type="checkbox"
                  v-model="seasonSettings.sendReminderEmails"
                  id="showPreshowReminder"
                  @change="saveSettings"
                  :class="{
                    'has-background-color': seasonSettings.sendReminderEmails
                  }"
                  class="is-checkradio is-success"
                />
                <label for="showPreshowReminder">
                  Send Preshow Reminder Emails
                </label>
              </div>
              <div
                class="field bumpadown"
                v-if="seasonSettings && seasonSettings.sendReminderEmails"
              >
                <label class="label">Preshow Reminder Email</label>
                <label class="label"
                  >Add any extra information you would like to include about the
                  event to be added to the end of the preshow reminder email
                  (parking, transportation, doors open time, etc.).</label
                >
                <div class="control">
                  <quill-editor
                    ref="emailMessageEditor"
                    :content="seasonSettings.emailReminderMessage"
                    :options="editorOption"
                    :class="{ 'ql-focus': emailReminderEditorHasFocus }"
                    @blur="emailReminderEditorHasFocus = false"
                    @focus="emailReminderEditorHasFocus = true"
                    @change="updateReminderEmail($event)"
                  />
                </div>
                <TestEmailSender
                  v-if="false"
                  type="reminder"
                  :eventId="event.id"
                />
              </div>
            </div>
            <div class="box">
              <div class="control">
                <input
                  type="checkbox"
                  id="sendThankyouEmails"
                  v-model="seasonSettings.sendThankyouEmails"
                  :class="{
                    'has-background-color': seasonSettings.sendThankyouEmails
                  }"
                  class="is-checkradio is-success"
                  @change="saveSettings"
                />
                <label for="sendThankyouEmails">
                  Send Postshow Thank-You Emails
                </label>
              </div>
              <div
                class="field bumpadown"
                v-if="seasonSettings && seasonSettings.sendThankyouEmails"
              >
                <label class="label">Postshow Thank-You Email</label>
                <label class="label"
                  >Add any extra information you would like to include about the
                  event to be added to the end of the postshow thank-you email
                  (survey, request for feedback, etc.).</label
                >
                <div class="control">
                  <quill-editor
                    ref="emailMessageEditor"
                    :content="seasonSettings.emailThankyouMessage"
                    :options="editorOption"
                    :class="{ 'ql-focus': emailThankyouEditorHasFocus }"
                    @blur="emailThankyouEditorHasFocus = false"
                    @focus="emailThankyouEditorHasFocus = true"
                    @change="updateThankyouEmail($event)"
                  />
                </div>
              </div>
              <TestEmailSender
                v-if="false"
                type="thankyou"
                :eventId="event.id"
              />
            </div>
          </div>

          <div class="column" v-show="step == 7">
            <h4>Shows</h4>
            <div class="box">
              <p>
                Add shows to your festival. Shows are events within the festival
                that tickets are sold to on an individual or group basis.
              </p>
              <div v-for="event in events" :key="event.id" class="columns">
                <div class="column is-narrow">
                  <div class="avatar-generic">
                    <img :src="event.image" alt="" class="eventimage" />
                  </div>
                </div>
                <div class="column">
                  <p class="artistname">
                    <strong
                      ><router-link
                        :to="{
                          name: 'editFestivalEvent',
                          params: { id: event.id }
                        }"
                        >{{ event.title }}</router-link
                      ></strong
                    > &nbsp;&nbsp;|&nbsp;&nbsp;
                    <span class="city">{{ event.act }}</span>
                  </p>
                </div>
              </div>
              <p class="buttons">
                <button class="button" @click="newShow">
                  <span class="icon is-medium">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span>Add show</span>
                </button>
              </p>
            </div>
          </div>

          <div class="column" v-show="step == 8">
            <h4>Preview + Publish</h4>
            <div class="box">
              <div class="columns">
                <div class="column is-half">
                  <div class="event-preview">
                    <div class="event-preview-header">
                      <img v-if="season.image" :src="season.image" alt="" />
                    </div>
                    <div class="event-preview-body">
                      <!-- <EventDateline :event="event" /> -->
                      <h3>{{ season.title }}</h3>
                      <!-- <h5 v-if="venue">{{ venue.name }}</h5> -->
                      <button class="button is-fullwidth" @click="preview()">
                        Preview Festival
                      </button>
                    </div>
                  </div>
                </div>
                <div class="column is-half">
                  <div class="control">
                    <label class="radio">
                      <input
                        type="radio"
                        name="answer"
                        v-model="season.isPublic"
                        value="true"
                        @change="saveFestival()"
                      />
                      Public
                    </label>
                    <p class="radio-label">Shared on Gobo and searchable</p>
                    <label class="radio">
                      <input
                        type="radio"
                        name="answer"
                        v-model="season.isPublic"
                        value="false"
                        @change="saveFestival()"
                      />
                      Private
                    </label>
                    <p class="radio-label">
                      Only available to you and the people you share it with.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box" v-if="cantPublish && season.status == 'draft'">
              <h4>Before you publish</h4>
              <p>
                There's a few things you need to do before you can publish your
                festival.
              </p>
              <ul>
                <li v-if="!season.title">
                  Please give your festival a title
                </li>
                <li v-if="!season.genre">Set an festival type</li>
                <li v-if="!season.act">Specify an act</li>
                <li v-if="!season.description">
                  Give your festival a description
                </li>
                <li v-if="!season.image">Add an festival image</li>
                <!-- <li
                  v-if="
                    !festivalDates ||
                      eventDates.length < 1 ||
                      (eventDates.length == 1 && !eventDates[0].id)
                  "
                >
                  Add at least one date
                </li> -->
                <!-- <li v-if="!event.venue">Set a location for your event</li>
                <li v-if="!event.capacity">Specify your event capacity</li>
                <li
                  v-if="
                    !priceTiers ||
                      priceTiers.length < 1 ||
                      (priceTiers.length == 1 && !priceTiers[0].id)
                  "
                >
                  Set at least one pricing tier for your event
                </li> -->
              </ul>
            </div>
          </div>

          <div
            class="column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
          ></div>
        </div>
      </div>
    </div>
    <div class="box stepbar">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="buttons is-right">
              <button class="button" v-if="step > 1" @click="step = step - 1">
                Back
              </button>
              <button
                class="button is-primary"
                v-if="step < 6"
                @click="next()"
                :disabled="cropping || (step == 0 && !season.festivalId)"
              >
                Next
              </button>
              <button
                class="button is-primary"
                v-if="step == 6"
                @click="next()"
              >
                Preview
              </button>
              <button
                class="button is-primary"
                v-if="season.status == 'draft' && step == 7"
                @click="publish()"
                :disabled="cantPublish"
              >
                Publish
              </button>
            </div>
          </div>
          <div
            class="column is-2 is-1-desktop is-hidden-tablet-only is-hidden-mobile"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/assets/variables.scss";
.profile {
  border-bottom: none;
}
.event-preview {
  border-radius: 6px;
}
.event-preview-header,
.event-preview-header img {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  line-height: 0;
}
.event-preview-body {
  padding: 1em;
  border: 2px solid #f2f2f2;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.event-preview-body h3 {
  margin-bottom: 0.5em;
}
.event-preview-body h5 {
  margin-bottom: 1.2em;
}
.event-preview-body h6 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
  margin-bottom: 0.5em;
}
.radio-label {
  font-size: 16px;
  margin-top: 2px;
}
.upload {
  font-family: $family-primary;
  display: flex;
}
.eventimageheader {
  border-radius: 6px;
}
.ql-editor p {
  margin: 1em 0;
}
.ql-editor p:first-of-type {
  margin: 0 0 1em 0;
}
.bumpadown {
  margin-top: 10px;
}
.box .field:not(:first-child) {
  margin-top: 30px;
}
p.artistname a {
  color: $active-green;
}
@media screen and (max-width: 768px) {
  .is-flex-mobile {
    flex: 1;
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
import Festival from "@/components/festival";
//import EventDate from "@/components/eventDate";
//import EventDateline from "@/components/event-dateline";
import PricingTier from "@/components/pricingTier";
import DiscountCodes from "@/components/editEvent/discount-codes";
import VenueMap from "@/components/venue-map";
import Venue from "@/components/venue";
//import InviteUserStarter from "@/components/invite-user-starter";
import UploadImage from "@/components/upload-image";
//ximport TestEmailSender from "@/components/test-email-sender"
//import moment from "moment";
import firebase from "firebase/app";
import Bugsnag from "@bugsnag/browser";

export default {
  name: "editFestival",
  props: ["festivalId", "id"],
  mounted() {},
  async created() {
    console.log(
      "Fest season ID ",
      this.id,
      this.festivalId,
      this.$route.params
    );
    if (!this.id) {
      if (this.season && this.festivals && this.festivals.length == 1) {
        this.selectFestival(this.festivals[0].id);
        this.step = 1;
      } else console.log("WTF batman");
      // if (this.user && this.user.loggedIn && this.user.email)
      //   console.log("we've got a live one!", this.user);
      // else
      //   store.subscribe(mutation => {
      //     if (mutation.type === "SET_USER") {
      //       var artist = null;
      //       var venue = null;
      //       if (this.profiles && this.profiles.length == 1) {
      //         artist = this.profiles[0];
      //         this.eventType = artist.type;
      //       }
      //       if (this.venues && this.venues.length == 1) venue = this.venues[0];
      //       store.dispatch("newEvent", { artist: artist, venue: venue });
      //       if (venue) store.dispatch("fetchVenue", venue.id);
      //     }
      //   });
    } else {
      console.log("Getting the season");
      await store.dispatch("fetchFestivalSeason", {
        festivalId: this.festivalId,
        seasonId: this.id
      });
      this.seasonDescription = this.season.description;
      if (this.season.venue)
        await store.dispatch("fetchVenue", this.season.venue);
      await store.dispatch("fetchFestivalEvents", this.season.id);
      this.step = 1;
    }
  },
  data() {
    return {
      step: 0,
      newDate: true,
      newTier: true,
      unsavedTier: null,
      editing: null,
      cropping: false,
      showImageLoading: false,
      percentComplete: 0,
      locationError: "",
      seasonDescription: "",
      ticketTotal: null,
      errors: {},
      dirty: {},
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "link", "image"],
            [{ list: "ordered" }, { list: "bullet" }]
          ]
        },
        placeholder: "Add your description here"
      },
      editorHasFocus: false,
      emailEditorHasFocus: false,
      emailReminderEditorHasFocus: false,
      emailThankyouEditorHasFocus: false,
      fields: [
        "place_id",
        "name",
        "formatted_phone_number",
        "website",
        "formatted_address",
        "address_components",
        "geometry"
      ]
    };
  },
  components: {
    // EventDate,
    PricingTier,
    // EventDateline,
    DiscountCodes,
    VenueMap,
    Venue,
    // InviteUserStarter,
    Festival,
    UploadImage
    // TestEmailSender
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      season: "season",
      seasonSettings: "seasonSettings",
      festivals: "festivals",
      user: "user",
      venue: "venue",
      events: "festivalEvents"
    }),
    festival() {
      return this.festivals.find(f => f.id == this.season.festivalId);
    },
    cantPublish() {
      return true;
      //   if (
      //     !this.festival.title ||
      //     !this.festival.genre ||
      //     !this.festival.act ||
      //     !this.festival.description ||
      //     !this.festival.image ||
      //     !this.festival.venue ||
      //     !this.festival.capacity
      //   )
      //     return true;
      //   if (
      //     !this.eventDates ||
      //     this.eventDates.length < 1 ||
      //     (this.eventDates.length == 1 && !this.eventDates[0].id)
      //   )
      //     return true;
      //   if (
      //     !this.priceTiers ||
      //     this.priceTiers.length < 1 ||
      //     (this.priceTiers.length == 1 && !this.priceTiers[0].id)
      //   )
      //     return true;
      //   return false;
    }
  },
  methods: {
    selectFestival(festivalId) {
      console.log("selected fest", festivalId);
      const fest = this.festivals.find(f => f.id == festivalId);
      this.season.festivalId = fest.id;
      this.season.title = fest.name;
      this.season.type = fest.type;
      store.dispatch("setSeasonFestival", this.season);
    },
    async next() {
      if (this.step == 1 && !this.festival.id) {
        await this.saveNewEvent();
        this.$router.push({
          name: "editEvent",
          params: { id: this.festival.id }
        });
      } else {
        if (this.step > this.festival.stepsCompleted)
          this.event.stepsCompleted = this.step;
        await store.dispatch("updateEvent", this.festival);
      }
      this.step++;
    },
    // async saveNewEvent() {
    //   this.event.stepsCompleted = 1;
    //   this.event.ownerId = this.user.uid;
    //   await store.dispatch("createEvent", this.event);
    //   console.log("Event id: " + this.event.id);
    //   store.dispatch(
    //     "stashCurrentEventIdForDiscountsThisIsDumb",
    //     this.event.id
    //   );
    // },
    async setStep(step) {
      console.log("Step set: " + step);
      if (this.season.step == 1 && !this.season.id) {
        await this.saveNewEvent();
        this.$router.push({ name: "editEvent", params: { id: this.event.id } });
      }
      this.step = step;
    },
    // newEvent() {
    //   this.newDate = true;
    //   store.dispatch("addNewDate");
    // },
    newPriceTier() {
      this.newTier = true;
      this.unsavedTier = {};
    },
    newPass() {
      if (!this.season.passes) this.season.passes = [];
      this.season.passes.push({ name: "" });
      this.saveFestival();
    },
    newDiscount() {
      console.log("Adding discount");
      if (!this.seasonSettings.discounts) this.seasonSettings.discounts = [];
      this.seasonSettings.discounts.push({ discountCode: "" });
      // TODO: Wrap all these in a method
      store.dispatch("updateSeasonSettings", {
        festivalId: this.season.festivalId,
        seasonId: this.season.id,
        settings: this.seasonSettings
      });
    },
    async newShow() {
      var neweventId = await store.dispatch("newFestivalEvent", {
        festivalId: this.season.festivalId,
        seasonId: this.season.id,
        venueId: this.season.venue,
        venueName: this.season.venueName,
        ownerId: this.user.uid
      });
      console.log("id", neweventId);
      this.$router.push({
        name: "editFestivalEvent",
        params: { id: neweventId }
      });
    },
    deletePass(index) {
      //console.log("index is", index);
      this.season.passes.splice(index, 1);
      this.saveFestival();
    },
    saveDiscount(change) {
      console.log("got the change", change);
      //this.seasonSettings.discounts[change.index] == change.discount;
      this.seasonSettings.discounts.splice(change.index, 1, change.discount);
      this.saveSettings();
    },
    saveSeasonDescription(event) {
      this.season.description = event.html;
      this.saveFestival();
    },
    setPlace(e) {
      console.log(e);
      if (!e.address_components || e.address_components.length < 7) {
        this.locationError =
          "Invalid location. Location must have a valid address.";
        this.$analytics.logEvent("invalid_event_address", { address: e });
        Bugsnag.notify("Invalid address response.", e);
      } else {
        // An eight-part address contains a neighborhood. A seven-part does not.
        var offset = e.address_components.length == 7 ? 2 : 3;
        this.locationError = "";
        store.dispatch("setVenue", {
          id: e.place_id,
          name: e.name,
          phone: e.formatted_phone_number ? e.formatted_phone_number : null,
          website: e.website ? e.website : null,
          fullAddress: e.formatted_address,
          location: {
            lat: e.geometry.location.lat(),
            lng: e.geometry.location.lng()
          },
          address:
            e.address_components[0].long_name +
            " " +
            e.address_components[1].long_name,
          city: e.address_components[offset].long_name,
          state: e.address_components[offset + 2].long_name,
          zip: e.address_components[offset + 4].long_name
        });

        this.season.venue = e.place_id;
        this.season.venueName = e.name;
        store.dispatch("updateFestivalSeason", this.season);
      }
    },
    async saveTier(tier) {
      console.log("saving tier");
      if (!this.season.priceTiers) this.season.priceTiers = [];
      if (tier.id == null) {
        // await store.dispatch("createTier", tier);
        // this.newTier = false;
        tier.id = this.season.priceTiers.length;
        this.season.priceTiers.push(tier);
        this.saveFestival();
        this.unsavedTier = null;
      } else await store.dispatch("updateTier", tier);
    },
    deleteTier(tier) {
      console.log("tier");
      store.dispatch("showConfirmation", {
        header: "Delete tier?",
        message: "Are you sure you want to delete this tier?",
        cta: "delete",
        action: "deleteTier",
        object: tier
      });
      console.log(tier);
    },
    saveSettings() {
      store.dispatch("updateSeasonSettings", {
        festivalId: this.season.festivalId,
        seasonId: this.season.id,
        settings: this.seasonSettings
      });
    },
    updateEmail(event) {
      console.log("editor change!", event.html);
      this.seasonSettings.emailMessage = event.html;
      this.saveSettings();
      //this.content = html;
    },
    updateReminderEmail(event) {
      console.log("editor change!", event.html);
      this.seasonSettings.emailReminderMessage = event.html;
      this.saveSettings();
    },
    updateThankyouEmail(event) {
      console.log("editor change!", event.html);
      this.seasonSettings.emailThankyouMessage = event.html;
      this.saveSettings();
    },
    async saveFestival() {
      if (!this.season.id) {
        await store.dispatch("createFestivalSeason", this.season);
        this.$router.push({
          name: "editFestival",
          params: { festivalId: this.season.festivalId, id: this.season.id }
        });
      } else store.dispatch("updateFestivalSeason", this.season);
    },
    saveImage(e) {
      console.log(e);
    },
    croppie(e) {
      e.preventDefault();
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      e.currentTarget.classList.remove("drop-active");
      this.cropping = true;

      var reader = new FileReader();
      reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };

      reader.readAsDataURL(files[0]);
    },
    cancel() {
      this.cropping = false;
    },
    async crop(image) {
      // Options can be updated.
      // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
      this.cropping = false;
      this.showImageLoading = true;
      // let options = {
      //   type: "blob",
      //   size: { width: 800, height: 360 },
      //   format: "jpeg"
      // };
      var output = image; //, output => {
      var storageRef = firebase.storage().ref();
      var imagestr = "festivalSeasons/" + this.season.id + ".jpg";
      var imageRef = storageRef.child(imagestr);
      var upload = imageRef.put(output);

      upload.on(
        "state_changed",
        upload => {
          var progress = (this.percentComplete =
            (upload.bytesTransferred / upload.totalBytes) * 100);
          console.log("Upload is " + progress + "% done");
        },
        e => {
          console.log("Upload error: ", e);
        },
        () => {
          upload.snapshot.ref.getDownloadURL().then(async url => {
            this.season.image = url;
            await this.saveFestival();
            this.showImageLoading = false;
          });
        }
      );
    },
    dragover(event) {
      event.preventDefault();
      event.currentTarget.classList.add("drop-active");
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove("drop-active");
    },
    drop(event) {
      event.preventDefault();
      var reader = new FileReader();
      reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };
      //this.$refs.croppieRef = event.dataTransfer.files;
      //this.$refs.file.files = event.dataTransfer.files;
      //this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.remove("drop-active");
    },
    preview() {
      this.$router.push({ name: "previewEvent", id: this.event.id });
    },
    async publish() {
      var db = firebase.firestore();
      await db
        .collection("events")
        .doc(this.event.id)
        .update({ status: "publishMe" })
        .catch(function(error) {
          console.error("Error publishing event: ", error);
        });
      this.$router.push({ name: "eventPublished" });
    },
    eventTypeChange() {
      if (this.eventType != "other") this.event.genre = this.eventType;
      else this.event.genre = "";
    },
    actChanged() {
      if (this.event.actId) {
        for (const profile of this.profiles) {
          if (profile.id == this.event.actId) {
            this.event.act = profile.name;
            this.event.artists[profile.id] = profile;
          }
        }
        this.saveFestival();
      }
    },
    setHasDiscount(val) {
      console.log("setting has discount", val);
      this.event.hasDiscounts = val;
      this.saveFestival();
    },
    getowners(artist) {
      if (artist.owners) {
        let names = "";
        let x = 0;
        for (const owner in artist.owners) {
          if (x == 1) names += ", ";
          console.log("owner: ", owner);
          names += artist.owners[owner].name;
          x = 1;
        }
        return names;
      } else return "This artist is not using Gobo yet.";
    },
    clearVenue() {
      store.dispatch("clearVenue");
    },
    removeArtist() {
      this.event.act = null;
      this.event.actId = null;
      this.event.artists = {};
      this.saveFestival();
    }
  },
  watch: {
    event: {
      handler(newval) {
        // Mark dirties
        if (newval.title) this.dirty.title = true;
        if (this.eventType == "other" && newval.genre) this.dirty.genre = true;
        if (newval.description) this.dirty.description = true;
        if (newval.capacity) this.dirty.capacity = true;
        // Title is required
        if (this.dirty.title) {
          if (!newval.title)
            this.errors.title = "Please provide a title for this event.";
          else this.errors.title = null;
        }
        // Genre is required
        if (this.eventType == "other" && this.dirty.genre) {
          if (!newval.genre)
            this.errors.genre = "Please specify the type of event.";
          else this.errors.genre = null;
        }
        // Description is required
        if (this.dirty.description) {
          if (!newval.description)
            this.errors.description = "Please add an event description.";
          else this.errors.description = null;
        }
        // Capacity is required
        if (this.dirty.capacity) {
          if (!newval.capacity)
            this.errors.capacity =
              "Please enter a total number of tickets for this event greater than 0.";
          else this.errors.capacity = null;
        }
      },
      deep: true
    }
  }
};
</script>
