<template>
  <div class="discountbox">
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">Discount Code</label>
          <div class="control">
            <input
              class="input"
              name="discountCode"
              type="text"
              style="text-transform:uppercase"
              placeholder="Discount Code"
              :value="discount.discountCode"
              :disabled="disable"
              @change="save"
            />
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="field">
          <label class="label">Discount Type</label>
          <div class="select">
            <select
              :value="discount.discountType"
              name="discountType"
              :disabled="disable"
              @change="save"
            >
              <option value="comp">Free</option>
              <option value="percent">% Off</option>
              <option value="ammount">Fixed $ Off</option>]
              <option value="price">Set Price</option>
              <option value="bogo">BOGO</option>
            </select>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field" v-if="discount.discountType == 'percent'">
          <label class="label">Percentage Off</label>
          <div class="control has-icons-right">
            <input
              class="input"
              type="number"
              min="1"
              max="100"
              name="percentOff"
              :value="discount.percentOff"
              placeholder="Percentage Off"
              :disabled="disable"
              @change="save"
            />
            <span class="icon is-small is-right">
              <i class="fas fa-percent"></i>
            </span>
          </div>
        </div>
        <div class="field" v-if="discount.discountType == 'dollars'">
          <label class="label">Amount Off</label>
          <div class="control has-icons-left">
            <input
              class="input"
              type="number"
              name="dollarsOff"
              :value="discount.dollarsOff"
              placeholder="Amount Off"
              min="1"
              max="1000"
              step="1"
              :disabled="disable"
              @change="save"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-dollar-sign"></i>
            </span>
          </div>
        </div>
        <div class="field" v-if="discount.discountType == 'price'">
          <label class="label">Discounted Ticket Price</label>
          <div class="control has-icons-left">
            <input
              class="input"
              type="number"
              name="fixedPrice"
              :value="discount.fixedPrice"
              placeholder="Amount Off"
              min="1"
              max="1000"
              step="1"
              :disabled="disable"
              @change="save"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-dollar-sign"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="field">
          <label class="label">Limit</label>
          <div class="control">
            <input
              class="input"
              name="limit"
              type="number"
              min="1"
              max="10"
              style="text-transform:uppercase"
              placeholder="Limit"
              :value="discount.limit"
              :disabled="disable"
              @change="save"
            />
          </div>
        </div>
      </div>
      <div class="column is-narrow final">
        <span class="icon is-medium delete" @click="deleted" v-if="!disable"
          ></span>
      </div>
    </div>
    <div v-if="dates && dates.length > 1">
      <input
        type="checkbox"
        :id="'selectDatesOnly-' + discount.id"
        name="selectDates"
        :value="discount.selectDates"
        :disabled="disable"
        :class="{
          'has-background-color': discount.selectDates
        }"
        class="is-checkradio is-success"
        @change="save"
      />
      <label :for="'selectDatesOnly-' + discount.id">
        Apply code to select dates only
      </label>
    </div>
    <div class="dates" v-if="dates && dates.length > 1 && discount.selectDates">
      <div
        class="date"
        v-for="date in dates"
        :key="date.id"
        :class="{
          selected:
            discount.selectedDates &&
            discount.selectedDates.indexOf(date.id) > -1
        }"
        @click="toggleDate(date)"
      >
        {{ shortDate(date.startDate) }}
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import moment from "moment";

export default {
  props: ["discount", "dates", "disabled", "eventId", "codeIndex"],
  data: function() {
    return {
      discountType: null,
      initializing: true,
      currentOffset: 0,
      sliderIndex: 0,
      selectedDate: null
    };
  },
  methods: {
    deleted() {
      console.log("deleting discount code");
      this.$emit("deleted", { discount: this.discount, index: this.codeIndex });
    },
    save(e) {
      console.log(e);
      let newcode = {};
      Object.assign(newcode, this.$props.discount);
      console.log("val", e.target.value);
      console.log("new code", newcode);
      console.log("prop", this.discount);
      if (e.target.type == "number")
        newcode[e.target.name] = parseFloat(e.target.value);
      else if (e.target.type == "checkbox")
        newcode[e.target.name] = e.target.checked;
      else newcode[e.target.name] = e.target.value;
      console.log("saving", newcode);
      this.$emit("codeChanged", { discount: newcode, index: this.codeIndex });
      // store.dispatch("saveDiscount", newcode, this.eventId);
    },
    shortDate: function(date) {
      return moment(date).format("ddd, MMM Do");
    },
    toggleDate(date) {
      var selected = this.discount.selectedDates.indexOf(date.id) > -1;
      if (!this.discount.selectedDates) this.discount.selectedDates = [];
      if (!selected) this.discount.selectedDates.push(date.id);
      else
        this.discount.selectedDates.splice(
          this.discount.selectedDates.indexOf(date.id),
          1
        );
      store.dispatch("saveDiscount", this.discount);
    }
  },
  computed: {
    disable() {
      return this.disabled || this.discount.state == "used";
    }
  }
};
</script>

<style lang="scss">
div.final {
  padding-top: 25px;
}
.discountbox {
  margin-bottom: 25px;
}
</style>
