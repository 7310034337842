var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Let's Try Another Date")]),
      _c("EventPreview", {
        attrs: {
          event: _vm.event,
          selectedDate: _vm.dateSummary,
          venue: _vm.venue
        }
      }),
      _c("div", { staticClass: "orderline" }, [
        _c("div", { staticClass: "orderlineitem" }, [
          _vm._v(_vm._s(_vm.order.firstName) + " " + _vm._s(_vm.order.lastName))
        ]),
        _c("div", { staticClass: "orderlineitem" }, [
          _vm._v(_vm._s(_vm.order.email))
        ]),
        _c("div", { staticClass: "orderlineitem" }, [
          _vm._v(
            _vm._s(_vm.order.quantity) + " - " + _vm._s(_vm.order.tierName)
          )
        ])
      ]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "ticketsline" },
        _vm._l(_vm.order.quantity, function(index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "ticketslineitem",
              class: { selected: _vm.quantity == index },
              on: {
                click: function($event) {
                  _vm.quantity = index
                }
              }
            },
            [_vm._v(_vm._s(index))]
          )
        }),
        0
      ),
      _c(
        "div",
        [
          _c("p", [_vm._v("Which date do you want to move to?")]),
          _vm.eventDates.length > 1 &&
          (!_vm.event.eventType || _vm.event.eventType != "videoOnDemand")
            ? _c("DateSlider", {
                attrs: {
                  dates: _vm.eventDates,
                  visible: true,
                  displayAllDates: true
                },
                on: { setDate: _vm.setDate }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.selectedDate && _vm.selectedDate.start.toDate() < Date.now()
        ? _c("div", [
            _c("p", { staticClass: "error" }, [
              _vm._v(
                "This date is in the past. Do you want to the tickets to this date?"
              )
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "column" }, [
          _c(
            "button",
            { staticClass: "button is-fullwidth", on: { click: _vm.cancel } },
            [_vm._v(" Cancel ")]
          )
        ]),
        _c("div", { staticClass: "column" }, [
          _c(
            "button",
            {
              staticClass: "button is-fullwidth is-primary",
              attrs: {
                disabled: _vm.selectedDate == null || _vm.quantity == 0
              },
              on: { click: _vm.move }
            },
            [_vm._v(" Move ")]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [_vm._v("How many tickets do you want to move?")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }