<template>
    <duet-date-picker
        identifier="earlyPurchaseEndDate"
        :localization.prop="localisation_us"
        :value="date"
        first-day-of-week="0"
        @duetChange="updateDate"
    >
    </duet-date-picker>
</template>

<script>
const localisation_us = {
  buttonLabel: "Choose date",
  placeholder: "MM/DD/YYYY",
  selectedDateMessage: "Selected date is",
  prevMonthLabel: "Previous month",
  nextMonthLabel: "Next month",
  monthSelectLabel: "Month",
  yearSelectLabel: "Year",
  closeLabel: "Close window",
  keyboardInstruction: "You can use arrow keys to navigate dates",
  calendarHeading: "Choose a date",
  dayNames: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  monthNamesShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ]
};

export default {
  props: {
    date: null,
  },
  data: function() {
    return {
      localisation_us: localisation_us
    };
  },
  methods: {
    updateDate(e) {
      // console.log("Yeaaaaah", e);
      this.$emit("changed", e.detail.value);
    }
  }
}

</script>