<template>
  <div>
    <div class="cal-body">
      <div v-if="date && (!end || (eventStartMonth == eventEndMonth && eventStartDay == eventEndDay))">
        <div class="month">{{ eventStartMonth }}</div>
        <div class="day">{{ eventStartDay }}</div>
      </div>
      <div v-if="end && eventStartMonth != eventEndMonth" class="datecolumns">
        <div class="datecolumn">
          <div class="month">{{ eventStartMonth }}</div>
          <div class="day">{{ eventStartDay }}</div>
        </div>
        <div class="day lowdash">-</div>
        <div class="datecolumn">
          <div class="month">{{ eventEndMonth }}</div>
          <div class="day">{{ eventEndDay }}</div>
        </div>
      </div>
      <div v-if="end && eventStartMonth == eventEndMonth && eventStartDay != eventEndDay">
        <div class="month">{{ eventStartMonth }}</div>
        <div class="datecolumns">
          <div class="datecolumn">
            <div class="day">{{ eventStartDay }}</div>
          </div>
          <div class="day">-</div>
          <div class="datecolumn">
            <div class="day">{{ eventEndDay }}</div>
          </div>
        </div>
      </div>
      <div v-if="!end && !date">
        <div class="month">NOTSET</div>
        <div class="day">?</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    date: Date,
    end: Date,
  },
  computed: {
    eventStartMonth() {
      return moment(this.date).format("MMM");
    },
    eventEndMonth() {
      return moment(this.end).format("MMM");
    },
    eventStartDay() {
      return moment(this.date).format("D");
    },
    eventEndDay() {
      return moment(this.end).format("D");
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";

.cal-body {
  border: 2px solid $gray5;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
  border-top: none;
  padding: 10px 20px;
  text-align: center;
}
.month {
  font-weight: bold;
  text-align: center;
}
.day {
  font-family: $family-secondary;
  font-size: 2.5em;
  line-height: 1em;
  text-align: center;
}
.datecolumns {
  display: flex;
  justify-content: center;
}
.datecolumn {
}
.lowdash {
  padding-top: 22px;
}
</style>