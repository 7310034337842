<template>
    <div class="body-wrapper">
      <div class="body-content gray">
        <div class="typecont">
           <h1>Choose Event Type</h1>
           <div>
            <div class="columns">
                <div class="column is-narrow">
                    <img src="/img/fill-house.png" alt="single event" width="140px" />
                </div>
                <div class="column">
                    <h3>Single Event</h3>
                    <p>The same artist or line up of artists for one or more performances at the same venue.</p>
                    <div class="buttons"><button class="button is-primary" @click="createEvent()">Create Event</button></div>
                </div>
            </div>
            <div class="columns" style="margin-top: 30px;">
                <div class="column is-narrow">
                    <img src="/img/series.png" alt="event series" />
                </div>
                <div class="column">
                    <h3>Event Series</h3>
                    <p>The same artist or line up of artists for one or more performances at the same venue.</p>
                    <div class="buttons"><button class="button is-primary" @click="$router.push({ name: 'createEventSeries' })">Create Event Series</button></div>
                </div>
            </div>
           </div>
        </div>
      </div>
    </div>
</template>

<script>
import store from "@/store";

export default {
  name: "gobo",
  methods: {
    createEvent() {
      store.dispatch("newEvent");
      this.$router.push({ name: "createEvent" });
    },
  },
}
</script>

<style>
    .typecont {
        background-color: white;
        padding: 50px;
        margin: 0 15%;
        border-radius: 6px;
    }
</style>