var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body-wrapper" }, [
    _c("div", { staticClass: "body-content" }, [
      _vm.festivalSeasons && _vm.festivalSeasons.length > 0
        ? _c("div", { staticClass: "container festyfest" }, [
            _c("h1", [_vm._v("My Festivals")]),
            _c(
              "table",
              { attrs: { id: "eventTable" } },
              [
                _vm._m(0),
                _vm._l(_vm.festivalSeasons, function(season) {
                  return _c("tr", { key: season.id }, [
                    _c(
                      "td",
                      { staticClass: "is-hidden-mobile" },
                      [
                        season.start
                          ? _c("EventDateBadge", {
                              attrs: { date: season.start.toDate() }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "div",
                        { staticClass: "synopsis" },
                        [
                          _c("div", { staticClass: "eventimagerapper" }, [
                            _c("div", { staticClass: "imageholder" }, [
                              _c("img", {
                                staticClass: "eventimage",
                                attrs: { src: season.image, alt: "" }
                              })
                            ])
                          ]),
                          _c("EventDateline", { attrs: { event: season } }),
                          _c(
                            "div",
                            { staticClass: "eventTitle" },
                            [
                              season.status &&
                              (season.status == "published" ||
                                season.status == "complete")
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "festivalSummary",
                                          params: {
                                            festivalId: season.festivalId,
                                            seasonId: season.id
                                          }
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(season.title))]
                                  )
                                : _vm._e(),
                              !season.status ||
                              (season.status != "published" &&
                                season.status != "complete")
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "editFestival",
                                          params: {
                                            festivalId: season.festivalId,
                                            id: season.id
                                          }
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(season.title))]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("div", { staticClass: "venue" }, [
                            _vm._v(_vm._s(season.venueName))
                          ]),
                          _c("div", { staticClass: "is-clearfix" }),
                          season.audienceReport
                            ? _c(
                                "div",
                                { staticClass: "stats is-hidden-tablet" },
                                [
                                  _c("div", { staticClass: "col" }, [
                                    _c("div", { staticClass: "field" }, [
                                      _c("label", { staticClass: "label" }, [
                                        _vm._v("Sold")
                                      ]),
                                      _c("div", { staticClass: "control" }, [
                                        season.audienceReport &&
                                        season.audienceReport.totalSold
                                          ? _c("span", [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    season.audienceReport
                                                      .totalSold
                                                  )
                                                )
                                              ]),
                                              _vm._v(
                                                " /" +
                                                  _vm._s(
                                                    season.audienceReport
                                                      .capacity
                                                  )
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                                  ]),
                                  _c("div", { staticClass: "col" }, [
                                    _c("div", { staticClass: "field" }, [
                                      _c("label", { staticClass: "label" }, [
                                        _vm._v("Gross")
                                      ]),
                                      _c("div", { staticClass: "control" }, [
                                        season.audienceReport
                                          ? _c("span", [
                                              _vm._v(
                                                "$" +
                                                  _vm._s(
                                                    season.audienceReport
                                                      .salesTotal
                                                  )
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "status is-hidden-tablet" },
                            [
                              _c("label", { staticClass: "label" }, [
                                _vm._v("Status")
                              ]),
                              _c("span", { staticClass: "statusstatus" }, [
                                _vm._v(_vm._s(season.status))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c("div", { staticClass: "menubar is-hidden-tablet" }, [
                        _c("div", { staticClass: "buttons mobilebar" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button is-info",
                              attrs: { disabled: season.status != "draft" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteDate(season)
                                }
                              }
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button is-info",
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "editEvent",
                                    params: { id: season.id }
                                  })
                                }
                              }
                            },
                            [_vm._v(" Edit ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button is-info",
                              on: {
                                click: function($event) {
                                  return _vm.duplicateEvent(season.id)
                                }
                              }
                            },
                            [_vm._v(" Duplicate ")]
                          )
                        ])
                      ])
                    ]),
                    _c("td", { staticClass: "results is-hidden-mobile" }, [
                      season.audienceReport && season.audienceReport.totalSold
                        ? _c("span", [
                            _c("strong", [
                              _vm._v(_vm._s(season.audienceReport.totalSold))
                            ]),
                            _vm._v(
                              " /" + _vm._s(season.audienceReport.capacity)
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("td", { staticClass: "results is-hidden-mobile" }, [
                      season.audienceReport
                        ? _c("span", [
                            _vm._v(
                              "$" + _vm._s(season.audienceReport.salesTotal)
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("td", { staticClass: "results is-hidden-mobile" }, [
                      _vm._v(_vm._s(season.status))
                    ]),
                    _c("td", { staticClass: "is-hidden-mobile" }, [
                      _c(
                        "div",
                        {
                          staticClass: "dropdown",
                          class: { "is-active": _vm.editing == season.id },
                          on: {
                            click: function($event) {
                              _vm.editing = season.id
                            }
                          }
                        },
                        [
                          _vm._m(1, true),
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-menu",
                              attrs: { id: "dropdown-menu2", role: "menu" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-content",
                                  on: {
                                    blur: function($event) {
                                      season.showMenu = false
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        to: {
                                          name: "eventSummary",
                                          params: { id: season.id }
                                        }
                                      }
                                    },
                                    [_vm._v("Sales Summary")]
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        to: {
                                          name: "editFestival",
                                          params: {
                                            festivalId: season.festivalId,
                                            id: season.id
                                          }
                                        }
                                      }
                                    },
                                    [_vm._v("Edit Festival")]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: function($event) {
                                          return _vm.duplicateEvent(season.id)
                                        }
                                      }
                                    },
                                    [_vm._v("Duplicate Event")]
                                  ),
                                  season.status == "draft" ||
                                  !season.audienceReport ||
                                  season.audienceReport.totalSold == 0
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteDate(season)
                                            }
                                          }
                                        },
                                        [_vm._v("Delete Event")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                })
              ],
              2
            )
          ])
        : _vm._e(),
      _vm.serieses && _vm.serieses.length > 0
        ? _c("div", { staticClass: "container festyfest" }, [
            _c("h1", [_vm._v("My Event Series")]),
            _c(
              "table",
              { attrs: { id: "eventTable" } },
              [
                _vm._m(2),
                _vm._l(_vm.serieses, function(series) {
                  return _c("tr", { key: series.id }, [
                    _c(
                      "td",
                      { staticClass: "is-hidden-mobile" },
                      [
                        series.start
                          ? _c("EventDateBadge", {
                              attrs: { date: series.start.toDate() }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "div",
                        { staticClass: "synopsis" },
                        [
                          _c("div", { staticClass: "eventimagerapper" }, [
                            _c("div", { staticClass: "imageholder" }, [
                              _c("img", {
                                staticClass: "eventimage",
                                attrs: { src: series.image, alt: "" }
                              })
                            ])
                          ]),
                          _c("EventDateline", { attrs: { event: series } }),
                          _c(
                            "div",
                            { staticClass: "eventTitle" },
                            [
                              series.status &&
                              (series.status == "published" ||
                                series.status == "complete")
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "seriesSummary",
                                          params: { id: series.id }
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(series.title))]
                                  )
                                : _vm._e(),
                              !series.status ||
                              (series.status != "published" &&
                                series.status != "complete")
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "editEventSeries",
                                          params: { id: series.id }
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(series.title))]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("div", { staticClass: "venue" }, [
                            _vm._v(_vm._s(series.venueName))
                          ]),
                          _c("div", { staticClass: "is-clearfix" }),
                          series.audienceReport
                            ? _c(
                                "div",
                                { staticClass: "stats is-hidden-tablet" },
                                [
                                  _c("div", { staticClass: "col" }, [
                                    _c("div", { staticClass: "field" }, [
                                      _c("label", { staticClass: "label" }, [
                                        _vm._v("Sold")
                                      ]),
                                      _c("div", { staticClass: "control" }, [
                                        series.audienceReport &&
                                        series.audienceReport.totalSold
                                          ? _c("span", [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    series.audienceReport
                                                      .totalSold
                                                  )
                                                )
                                              ]),
                                              _vm._v(
                                                " /" +
                                                  _vm._s(
                                                    series.audienceReport
                                                      .capacity
                                                  )
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                                  ]),
                                  _c("div", { staticClass: "col" }, [
                                    _c("div", { staticClass: "field" }, [
                                      _c("label", { staticClass: "label" }, [
                                        _vm._v("Gross")
                                      ]),
                                      _c("div", { staticClass: "control" }, [
                                        series.audienceReport
                                          ? _c("span", [
                                              _vm._v(
                                                "$" +
                                                  _vm._s(
                                                    series.audienceReport
                                                      .salesTotal
                                                  )
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "status is-hidden-tablet" },
                            [
                              _c("label", { staticClass: "label" }, [
                                _vm._v("Status")
                              ]),
                              _c("span", { staticClass: "statusstatus" }, [
                                _vm._v(_vm._s(series.status))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c("div", { staticClass: "menubar is-hidden-tablet" }, [
                        _c("div", { staticClass: "buttons mobilebar" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button is-info",
                              attrs: { disabled: series.status != "draft" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteDate(series)
                                }
                              }
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button is-info",
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "editEventSeries",
                                    params: { id: series.id }
                                  })
                                }
                              }
                            },
                            [_vm._v(" Edit ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button is-info",
                              on: {
                                click: function($event) {
                                  return _vm.duplicateEvent(series.id)
                                }
                              }
                            },
                            [_vm._v(" Duplicate ")]
                          )
                        ])
                      ])
                    ]),
                    _c("td", { staticClass: "results is-hidden-mobile" }, [
                      series.audienceReport && series.audienceReport.totalSold
                        ? _c("span", [
                            _c("strong", [
                              _vm._v(_vm._s(series.audienceReport.totalSold))
                            ]),
                            _vm._v(
                              " /" + _vm._s(series.audienceReport.capacity)
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("td", { staticClass: "results is-hidden-mobile" }, [
                      series.audienceReport
                        ? _c("span", [
                            _vm._v(
                              "$" + _vm._s(series.audienceReport.salesTotal)
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("td", { staticClass: "results is-hidden-mobile" }, [
                      _vm._v(_vm._s(series.status))
                    ]),
                    _c("td", { staticClass: "is-hidden-mobile" }, [
                      _c(
                        "div",
                        {
                          staticClass: "dropdown",
                          class: { "is-active": _vm.editing == series.id },
                          on: {
                            click: function($event) {
                              _vm.editing = series.id
                            }
                          }
                        },
                        [
                          _vm._m(3, true),
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-menu",
                              attrs: { id: "dropdown-menu2", role: "menu" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-content",
                                  on: {
                                    blur: function($event) {
                                      series.showMenu = false
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        to: {
                                          name: "seriesSummary",
                                          params: { id: series.id }
                                        }
                                      }
                                    },
                                    [_vm._v("Sales Summary")]
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        to: {
                                          name: "editEventSeries",
                                          params: { id: series.id }
                                        }
                                      }
                                    },
                                    [_vm._v("Edit Series")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                })
              ],
              2
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "container" }, [
        _c("h1", [_vm._v("My Events")]),
        _c(
          "table",
          { attrs: { id: "eventTable" } },
          [
            _vm._m(4),
            _vm._l(_vm.eventSummarys, function(summary) {
              return _c("tr", { key: summary.event.id }, [
                _c(
                  "td",
                  { staticClass: "is-hidden-mobile" },
                  [
                    summary.event.start
                      ? _c("EventDateBadge", {
                          attrs: { date: summary.event.start.toDate() }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "synopsis" },
                    [
                      _c("div", { staticClass: "eventimagerapper" }, [
                        _c("div", { staticClass: "imageholder" }, [
                          _c("img", {
                            staticClass: "eventimage",
                            attrs: { src: summary.event.image, alt: "" }
                          })
                        ])
                      ]),
                      _c("EventDateline", { attrs: { event: summary.event } }),
                      _c(
                        "div",
                        { staticClass: "eventTitle" },
                        [
                          summary.event.status &&
                          (summary.event.status == "published" ||
                            summary.event.status == "complete")
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "eventSummary",
                                      params: { id: summary.event.id }
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(summary.event.title))]
                              )
                            : _vm._e(),
                          !summary.event.status ||
                          (summary.event.status != "published" &&
                            summary.event.status != "complete")
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: summary.event.seasonId
                                        ? "editFestivalEvent"
                                        : "editEvent",
                                      params: { id: summary.event.id }
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(summary.event.title))]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", { staticClass: "venue" }, [
                        _vm._v(_vm._s(summary.event.venueName))
                      ]),
                      _c("div", { staticClass: "is-clearfix" }),
                      summary.audienceReport
                        ? _c("div", { staticClass: "stats is-hidden-tablet" }, [
                            _c("div", { staticClass: "col" }, [
                              _c("div", { staticClass: "field" }, [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v("Sold")
                                ]),
                                _c("div", { staticClass: "control" }, [
                                  summary.audienceReport &&
                                  summary.audienceReport.totalSold
                                    ? _c("span", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              summary.audienceReport.totalSold
                                            )
                                          )
                                        ]),
                                        _vm._v(
                                          " /" +
                                            _vm._s(
                                              summary.audienceReport.capacity
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ]),
                            _c("div", { staticClass: "col" }, [
                              _c("div", { staticClass: "field" }, [
                                _c("label", { staticClass: "label" }, [
                                  _vm._v("Gross")
                                ]),
                                _c("div", { staticClass: "control" }, [
                                  summary.audienceReport
                                    ? _c("span", [
                                        _vm._v(
                                          "$" +
                                            _vm._s(
                                              summary.audienceReport.salesTotal
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "status is-hidden-tablet" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Status")
                        ]),
                        _c("span", { staticClass: "statusstatus" }, [
                          _vm._v(_vm._s(summary.event.status))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "menubar is-hidden-tablet" }, [
                    _c("div", { staticClass: "buttons mobilebar" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-info",
                          attrs: { disabled: summary.event.status != "draft" },
                          on: {
                            click: function($event) {
                              return _vm.deleteDate(summary.event)
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "button is-info",
                          on: {
                            click: function($event) {
                              return _vm.$router.push({
                                name: summary.event.seasonId
                                  ? "editFestivalEvent"
                                  : "editEvent",
                                params: { id: summary.event.id }
                              })
                            }
                          }
                        },
                        [_vm._v(" Edit ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "button is-info",
                          on: {
                            click: function($event) {
                              return _vm.duplicateEvent(summary.event.id)
                            }
                          }
                        },
                        [_vm._v(" Duplicate ")]
                      )
                    ])
                  ])
                ]),
                _c("td", { staticClass: "results is-hidden-mobile" }, [
                  summary.audienceReport && summary.audienceReport.totalSold
                    ? _c("span", [
                        _c("strong", [
                          _vm._v(_vm._s(summary.audienceReport.totalSold))
                        ]),
                        _vm._v(" /" + _vm._s(summary.audienceReport.capacity))
                      ])
                    : _vm._e()
                ]),
                _c("td", { staticClass: "results is-hidden-mobile" }, [
                  summary.audienceReport
                    ? _c("span", [
                        _vm._v("$" + _vm._s(summary.audienceReport.salesTotal))
                      ])
                    : _vm._e()
                ]),
                _c("td", { staticClass: "results is-hidden-mobile" }, [
                  _vm._v(_vm._s(summary.event.status))
                ]),
                _c("td", { staticClass: "is-hidden-mobile" }, [
                  _c(
                    "div",
                    {
                      staticClass: "dropdown",
                      class: { "is-active": _vm.editing == summary.event.id }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-trigger",
                          on: {
                            click: function($event) {
                              _vm.editing = summary.event.id
                            }
                          }
                        },
                        [_vm._m(5, true)]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu",
                          attrs: { id: "dropdown-menu3", role: "menu" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "dropdown-content" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    to: {
                                      name: "eventSummary",
                                      params: { id: summary.event.id }
                                    }
                                  }
                                },
                                [_vm._v("Sales Summary")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    to: {
                                      name: summary.event.seasonId
                                        ? "editFestivalEvent"
                                        : "editEvent",
                                      params: { id: summary.event.id }
                                    }
                                  }
                                },
                                [_vm._v("Edit Event")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  on: {
                                    click: function($event) {
                                      return _vm.duplicateEvent(
                                        summary.event.id
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Duplicated Event")]
                              ),
                              summary.event.status == "draft" ||
                              !summary.audienceReport ||
                              summary.audienceReport.totalSold == 0
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteDate(summary.event)
                                        }
                                      }
                                    },
                                    [_vm._v("Delete Event")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ])
              ])
            })
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "header is-hidden-mobile" }, [
      _c("th", { attrs: { colspan: "2" } }, [_vm._v("Event Details")]),
      _c("th", [_vm._v("Sold")]),
      _c("th", [_vm._v("Gross")]),
      _c("th", [_vm._v("Status")]),
      _c("th")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropdown-trigger" }, [
      _c(
        "button",
        {
          staticClass: "button event-menu",
          attrs: { "aria-haspopup": "true", "aria-controls": "dropdown-menu3" }
        },
        [
          _c("span", { staticClass: "icon is-small" }, [
            _c("i", {
              staticClass: "fas fa-ellipsis-v",
              attrs: { "aria-hidden": "true" }
            })
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "header is-hidden-mobile" }, [
      _c("th", { attrs: { colspan: "2" } }, [_vm._v("Event Details")]),
      _c("th", [_vm._v("Sold")]),
      _c("th", [_vm._v("Gross")]),
      _c("th", [_vm._v("Status")]),
      _c("th")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropdown-trigger" }, [
      _c(
        "button",
        {
          staticClass: "button event-menu",
          attrs: { "aria-haspopup": "true", "aria-controls": "dropdown-menu3" }
        },
        [
          _c("span", { staticClass: "icon is-small" }, [
            _c("i", {
              staticClass: "fas fa-ellipsis-v",
              attrs: { "aria-hidden": "true" }
            })
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "header is-hidden-mobile" }, [
      _c("th", { attrs: { colspan: "2" } }, [_vm._v("Event Details")]),
      _c("th", [_vm._v("Sold")]),
      _c("th", [_vm._v("Gross")]),
      _c("th", [_vm._v("Status")]),
      _c("th")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "button event-menu",
        attrs: { "aria-haspopup": "true", "aria-controls": "dropdown-menu3" }
      },
      [
        _c("span", { staticClass: "icon is-small" }, [
          _c("i", {
            staticClass: "fas fa-ellipsis-v",
            attrs: { "aria-hidden": "true" }
          })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }