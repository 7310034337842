<template>
  <div v-if="series">
    <div class="preview-banner">
      <div class="columns is-mobile">
        <div class="column is-one-fifth is-hidden-mobile"></div>
        <div class="column">
          <h3>Event Series Preview</h3>
          <button class="button is-pulled-right" @click="edit()">
            <span class="icon">
              <i class="fas fa-pen"></i>
            </span>
            <span>Edit Series</span>
          </button>
        </div>
        <div class="column is-one-fifth is-hidden-mobile"></div>
      </div>
    </div>
    <div class="body-wrapper">
      <div class="columns">
        <div class="column is-one-fifth is-hidden-mobile"></div>
        <div class="column maincont">
          <div class="imagewrap" v-if="series.image">
            <img :src="series.image" class="event-image" />
          </div>
          <div class="calendar">
            <div class="cal-stripe"></div>
            <EventDateBadge
              :date="(series.start) ? series.start.toDate() : null"
              :end="(series.end) ? series.end.toDate() : null"
            />
          </div>
          <div class="description indent">
            <div><EventDateline :event="series" /></div>
            <h1>{{ series.title }}</h1>
            <div class="venue-map" v-if="venue" :class="{ hasMap: !series.mapDisplay || series.mapDisplay != 'hidden' }">
              <gmap-map
                v-if="!series.mapDisplay || series.mapDisplay != 'hidden'"
                :center="location"
                :zoom="series.venueIsAlternative ? 10 : 17"
                mapTypeControl="false"
                style="width: 300px; height: 200px"
                :options="options"
                zoomControl="false"
              >
                <gmap-marker
                  :position="location"
                  :clickable="true"
                  :draggable="true"
                  @click="center = location"
                ></gmap-marker>
              </gmap-map>
              <div class="map-legend">
                <h3>{{ venue.name }}</h3>
                <p v-if="!series.venueIsAlternative">{{ venue.fullAddress }}</p>
                <p v-if="series.venueIsAlternative">{{ venue.city }}</p>
              </div>
            </div>
            <div v-html="series.description"></div>
          </div>
          <div class="indent" style="margin-bottom: 20px;">
          <h3 style="clear: both; margin-top: 20px;">{{ series.title }} Passes</h3>
            <div
              v-for="pass in series.passes"
              :key="pass.id"
              class="passclass"
            >
              <h4>{{ pass.name }}</h4>
              <div class="columns">
                <div class="column">{{ pass.description }}</div>
                <div class="column is-one-quarter" v-if="!pass.status || pass.status != 'soldout'">
                  <strong>${{ pass.price }} per pass</strong>
                </div>
                <div class="column is-one-quarter" v-if="pass.status && pass.status == 'soldout'">
                  <strong class="soldout">${{ pass.price }} per pass</strong> SOLD OUT
                </div>
              </div>
            </div>
            <div class="buttons is-right">
              <button
                class="button is-primary is-pulled-right"
                @click="passes()"
              >
                purchase passes
              </button>
            </div>
          </div>
        </div>
        <div
              class="performances indent"
              v-if="!series.eventType || series.eventType != 'videoOnDemand'"
            >
              <FollowArtist v-if="false" :artist="series"></FollowArtist>
            </div>
        <div class="indent" v-if="events && events.length < 0">
        <h3>Shows</h3>
        </div>
        <div class="event indent" v-for="event in events" :key="event.id">
          <div class="columns">
            <div class="column">
              <div class="eventimagerapper">
                <div class="imageholder">
                  <img :src="event.image" alt="" class="eventimage" />
                </div>
              </div>
              <div class="eventTitle">
                <router-link
                  :to="{ name: 'event', params: { id: event.id } }"
                  >{{ event.title }}</router-link
                >
              </div>
              <div>{{ event.act }}</div>
              <div class="showblurb" v-html="event.description"></div>
            </div>
          </div>
        </div>
        <div class="column is-one-fifth is-hidden-mobile"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import moment from "moment";
import EventDateBadge from "@/components/event-date-badge";
import EventDateline from "@/components/event-dateline";

export default {
  props: ["id"],
  components: {
    EventDateBadge,
    EventDateline
  },
  data() {
    return {
      fields: [
        "place_id",
        "name",
        "formatted_phone_number",
        "website",
        "formatted_address",
        "address_components",
        "geometry"
      ],
      options: {
        disableDefaultUI: true,
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
        draggable: false,
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [
              {
                visibility: "off"
              }
            ]
          }
        ],
        mapId: "a7f1aa6c21b72aae"
      }
    };
  },
  computed: {
    ...mapGetters({
      events: "events",
      series: "series",
      venue: "venue",
    }),
    location: function() {
      if (this.series.venueIsAlternative) {
        let newloc = this.venue.location;
        newloc.lat = parseFloat(newloc.lat.toFixed(2));
        newloc.lng = parseFloat(newloc.lng.toFixed(2));
        console.log("boo", newloc);
        return newloc;
      }
      return this.venue.location;
    },
    salesStatus: function() {
      let status = "Purchase Tickets";
      let futureDates = 0;
      let soldoutDates = 0;
      for (let x = 0; x < this.eventDates.length; x++) {
        if (
          this.eventDates[x].status == "soldout" &&
          this.eventDates[x].start &&
          this.eventDates[x].start.toDate() > Date.now()
        )
          soldoutDates++;
        if (
          this.eventDates[x].start &&
          this.eventDates[x].start.toDate() > Date.now()
        )
          futureDates++;
      }
      console.log("future dates", futureDates, Date.now());
      if (soldoutDates >= futureDates) status = "Sold Out";
      if (futureDates == 0) status = "Sales Closed";
      return status;
    }
  },
  async created() {
    console.log("ID ", this.id);
    if (!this.series || this.series.id != this.id)
      await store.dispatch("fetchEventSeries", this.id);
    if (this.series.chargeSalesTax)
      store.dispatch("setTaxRate", this.series.salesTaxRate);
    if (this.series.venue) await store.dispatch("fetchVenue", this.series.venue);
  },
  methods: {
    edit() {
      this.$router.push({ name: "editEventSeries", id: this.series.id });
    },
    publish() {
      this.$router.push({ name: "eventPublished" });
    },
    passes() {
      console.log("keep selling those chicken passes");
      this.$emit("sellSeriesPasses");
    },
    formatDate(date) {
      return moment(date).format("dddd, MMMM Do YYYY");
    },
    formatShortDate(date) {
      return moment(date).format("dddd, MMM Do");
    },
    formatTime(time) {
      if (/.*[AaPp][Mm]/.test(time)) return time;
      else return moment(time, "HH:mm").format("h:mm A");
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
.datelist .cal-body {
  border: none;
}
.event {
  clear: both;
  margin-bottom: 10px;
}
.passclass {
  margin-bottom: 30px;
}
.showblurb {
  max-height: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.soldout {
  text-decoration: line-through;
}
.maincont {
  padding-bottom: 100px;
}

// APP SPECIFIC HERE
.preview-banner {
  background-color: $muted-red;
  margin: 20px 0;
}
.preview-banner h3 {
  font-size: 1.3em;
  line-height: 2em;
  margin: 0 0 0 25px;
  display: inline-block;
}

// mobile
@media screen and (max-width: 768px) {
  .body-wrapper {
    margin: 25px 0;
  }
  .event-image {
    border-radius: 0;
    margin: 0;
  }
  .calendar {
    margin-left: 15px;
  }
  .body-wrapper {
    margin: 0;
  }
  .indent {
    margin: 0 15px;
  }
  .venue-map {
    float: none;
    margin: 20px 30px;
  }
}
</style>
