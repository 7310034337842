<template>
  <div class="body-wrapper" v-if="series">
    <div class="body-content gray">
      <div class="container">
        <div class="columns is-gapless">
          <div class="column is-1 is-hidden-mobile"></div>
          <div class="column maincont" v-if="series">
            <h1>{{ series.title }}</h1>
            <div class="box">
              <div class="tabs">
                <ul>
                  <li
                    :class="{ 'is-active': tab == 'shows' }"
                    @click="tab = 'shows'"
                  >
                    <a>Shows</a>
                  </li>
                  <li
                    :class="{ 'is-active': tab == 'passes' }"
                    @click="tab = 'passes'"
                  >
                    <a>Pass Sales</a>
                  </li>
                  <li
                    :class="{ 'is-active': tab == 'orders' }"
                    @click="tab = 'orders'"
                  >
                    <a>Orders</a>
                  </li>
                </ul>
              </div>
              <div v-if="tab == 'shows'">
                <h1>Events</h1>
                <table id="eventTable">
                  <tr class="header is-hidden-mobile">
                    <th>Event Details</th>
                    <th>Sold</th>
                    <th>Gross</th>
                    <th>Status</th>
                  </tr>
                  <EventSummary :summary="event" v-for="event in events" :key="event.id" />
                </table>
              </div>
              <div v-if="tab == 'passes'">
                <h2>Pass Sales</h2>
                <div
                  class="passresults"
                  v-for="passType in series.passes"
                  :key="passType.id"
                >
                  <h3>{{ passType.name }}</h3>
                  <table class="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Qty</th>
                        <th>Paid</th>
                      </tr>
                    </thead>
                    <tr v-for="(pass, index) in getPasses(passType.id)" :key="index">
                      <td>{{ pass.firstName }} {{ pass.lastName }}</td>
                      <td>{{ pass.email }}</td>
                      <td>{{ pass.status }}</td>
                      <td>{{ pass.quantity}}</td>
                      <td>${{ pass.total }}</td>
                    </tr>
                    <tfoot>
                      <tr>
                        <th colspan="3">Total</th>
                        <th>{{ getPasses(passType.id).reduce((sum, pass) => sum + pass.quantity, 0) }}</th>
                        <th>
                          ${{ getPasses(passType.id).reduce((sum, pass) => sum + pass.total, 0) }}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div v-if="tab == 'orders'">
                <OrdersList :orders="orders" />
              </div>
            </div>
          </div>
          <div class="column is-1 is-hidden-mobile"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import EventSummary from "@/components/event-summary";
import OrdersList from "@/components/orders-list";

export default {
  name: "SeriesSummary",
  props: ["festivalId", "id"],
  components: { EventSummary, OrdersList },
  data() {
    return {
      tab: "shows"
    };
  },
  mounted() {},
  async created() {
    console.log(
      "series ID ",
      this.id,
      this.$route.params
    );
    console.log("Getting the series");
    await store.dispatch("fetchEventSeries", this.id);
    await store.dispatch("getSeriesPasses", this.id);
    await store.dispatch("getSeriesOrders", this.id);
    if (!this.events || this.events.length == 0) await store.dispatch("fetchSeriesEventSummarys", this.id);
    // console.log("events", this.events);
    // this.seriesDescription = this.series.description;
    // if (this.series.venue)
    //   await store.dispatch("fetchVenue", this.series.venue);
    // await store.dispatch("fetchFestivalEvents", this.series.id);
    this.step = 1;
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      series: "series",
      venue: "venue",
      passes: "seriesPasses",
      events: "seriesEventSummarys",
      orders: "seriesOrders",
    })
  },
  methods: {
    getPasses(passId) {
      return this.passes.filter(pass => pass.passId == passId);
    },
    getTotalUses(passId) {
      if (!this.passes || this.passes.length == 0) return 0;
      return this.passes
        .filter(pass => pass.passId == passId)
        .reduce((n, { usedCount }) => (n + usedCount ? usedCount : 0), 0);
    }
  }
};
</script>

<style>
.passresults {
  margin-bottom: 50px;
}
</style>
