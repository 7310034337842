var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Orders")]),
      _c("div", { staticStyle: { "margin-bottom": "20px", display: "flex" } }, [
        _c(
          "div",
          {
            staticClass: "control has-icons-left",
            staticStyle: { flex: "1", "margin-right": "20px" }
          },
          [
            _vm._m(0),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.orderSearch,
                  expression: "orderSearch"
                }
              ],
              staticClass: "input has-icons-left",
              attrs: { type: "text", placeholder: "Search by name or email" },
              domProps: { value: _vm.orderSearch },
              on: {
                keyup: _vm.searchOrders,
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.orderSearch = $event.target.value
                }
              }
            })
          ]
        ),
        _c("div", [
          _c(
            "button",
            { staticClass: "button", on: { click: _vm.exportCsv } },
            [_vm._v("Export CSV")]
          )
        ])
      ]),
      _c("table", { staticClass: "table is-fullwidth" }, [
        _vm._m(1),
        _c(
          "tbody",
          _vm._l(_vm.visibleOrders, function(order) {
            return _c("tr", { key: order.id }, [
              _c("td", [
                _vm._v(
                  _vm._s(
                    order.created
                      ? _vm.moment(order.created.toDate()).format("M/D/YY LT")
                      : ""
                  )
                )
              ]),
              _c("td", [
                _vm._v(_vm._s(order.firstName) + " " + _vm._s(order.lastName))
              ]),
              _c("td", [_vm._v(_vm._s(order.email))]),
              _c("td", [_vm._v(_vm._s(order.discountCode))]),
              _c("td", [_vm._v(_vm._s(order.quantity))]),
              _c("td", [_vm._v(_vm._s(order.donation))]),
              _c("td", [_vm._v("$" + _vm._s(order.total.toFixed(2)))])
            ])
          }),
          0
        )
      ]),
      _c("Paginator", {
        attrs: {
          pageSize: 10,
          itemCount:
            _vm.filteredOrders.length > 0
              ? _vm.filteredOrders.length
              : _vm.orders.length
        },
        on: { pageChanged: _vm.setOrdersPage }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-right" }, [
      _c("i", { staticClass: "fas fa-search" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Order Created")]),
        _c("th", [_vm._v("Name")]),
        _c("th", [_vm._v("Email")]),
        _c("th", [_vm._v("Discount Code")]),
        _c("th", [_vm._v("Qty")]),
        _c("th", [_vm._v("Donation")]),
        _c("th", [_vm._v("Total")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }