<template>
  <div class="bodywrapper">
    <div class="columns">
      <div class="column is-one-fifth"></div>
      <div class="column">
        <h1>Event Series Published!</h1>
        <p>
          Event series successfully published. You can find it at:
          <a :href="'https://tix.gobo.show/series/' + series.id">https://tix.gobo.show/series/{{ series.id }}</a>
        </p>
      </div>
      <div class="column is-one-fifth"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      series: "series"
    })
  }
};
</script>

<style>
.bodywrapper {
  margin: 100px 0;
}
</style>
